//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SelectLanguage from './SelectLanguage';
export default {
  components: {
    SelectLanguage: SelectLanguage
  },
  data: function data() {
    return {
      dialog: false,
      drawer: null,
      show_sidebar: true,
      url: '',
      find_aziende: false,
      aziende: [],
      items: [],
      current_active: null,
      login: true,
      logout: false,
      currentLog: false,
      oldLog: false,
      interval: {},
      lingue_ui: [],
      view_lang: false,
      loaded: false,
      logged: false
    };
  },
  props: {
    source: String,
    find: 0
  },
  created: function created() {
    this.get_opt();

    if (this.$root.logged === undefined) {
      this.$root.logged = false;
    } else {} //console.log(this.$root.logged);
    //console.log(this.$root);


    this.resendAll();
    this.isLogged();
    this.getNavLink();
    this.$convertIso();
    this.getLanguagesSystem(); //this.$moment.locale('it');
    //console.log(this);

    this.currentLog = this.$root.logged;
    this.current_active = 0;
  },
  computed: {
    getStyle: function getStyle(e) {
      console.log(e);
      return 'letter-spacing: 0.1em';
    },
    getItems: function getItems() {
      var cur = this.items;

      for (var i in cur) {
        cur[i]['style'] = 'letter-spacing: 0.1em';

        if (this.items[i]['active']) {
          cur[i]['style'] = 'letter-spacing: 0.1em;background: rgb(105,100,230);\n' + 'background: linear-gradient(0deg, rgba(105,100,230,0.3071603641456583) 16%, rgba(255,255,255,1) 100%);';
        }
      }

      return cur;
    }
  },
  methods: {
    checkEvent: function checkEvent(e) {
      console.log(e);

      if (e.reload !== undefined) {
        this.resendAll();
        this.isLogged();
        this.getNavLink();
      }
    },
    get_opt: function get_opt() {
      var _this = this;

      axios.get('/utils/pollme').then(function (res) {
        _this.$root.options = res.data.opt;
        _this.loaded = true;
      });
    },
    resendAll: function resendAll() {
      this.interval = setInterval(function () {
        if (this.$root.logged !== this.oldLog) {
          this.isLogged();
          this.getNavLink();
          this.oldLog = this.$root.logged;
          clearInterval(this.interval);
        }
      }.bind(this), 1000);
    },
    getLanguagesSystem: function getLanguagesSystem() {
      var self = this;
      axios.get('/sysLang').then(function (response) {
        self.lingue_ui = response.data.lang;
        self.view_lang = true;
      });
    },
    isLogged: function isLogged() {
      var self = this;
      axios.get('/auth/logged').then(function (response) {
        if (!response.data.obj.logged) {
          self.$root.logged = false;
          var currentUrl = window.location.pathname; //console.log(currentUrl);

          if (currentUrl === '/') self.$router.push('/badge/show');
        } else {
          self.$root.logged = true;
          self.$localStorage.set('token', response.data.obj.tk); // window.localStorage.set('token',response.data.obj.tk)

          var currentUrl = window.location.pathname;
          self.checkLangCookies(response.data.obj.l); //console.log(currentUrl);

          if (currentUrl === '/') self.$router.push('/badge/show');
        }
      });
    },
    checkLangCookies: function checkLangCookies(newLang) {
      if (this.$cookie.get('appLang') !== newLang) {
        this.$cookie.set('appLang', newLang);
        window.location.reload();
      }
    },
    getNavLink: function getNavLink() {
      var self = this;
      var currentUrl = window.location.pathname;
      self.url = currentUrl; //if(document.head.querySelector('meta[name="selected"]')!==null){

      axios.get('/mob/getMenu/').then(function (data) {
        self.items = data.data.obj; // self.show_sidebar=true;
        // self.find_aziende=false;
      });
      /*}else{
      		self.findMyAzienda();
      }*/
    },
    findMyAzienda: function findMyAzienda() {
      var self = this;
      axios.get('/admin/getItemsAziende').then(function (response) {
        self.aziende = response.data.obj;
        self.find_aziende = true;
      });
    },
    nv: function nv(url) {
      if (url === 'admin') {
        window.location = '/admin1';
      } else this.$router.push(url);
    },
    Naviga: function Naviga(url) {
      var self = this;

      for (var i = 0; i < this.items.length; i++) {
        this.items[i].active = false;

        if (this.items[i].url === url.url) {
          this.items[i].active = true;
        }
      }

      if (url.url === 'admin') {
        window.location = '/admin1';
      } else this.$router.push(url.url);
    },
    onClickChildAzienda: function onClickChildAzienda(data) {
      this.getNavLink();
      /*
      this.show_sidebar=true;
      this.find_aziende=false;*/
    }
  }
};