//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "vuefydatatable",
  props: ['url'],
  data: function data() {
    return {
      headers: [],
      dati: [],
      get_call: '',
      link_new: '',
      url_to_delete: '',
      pagination: {
        rowsPerPage: 25
      },
      isVideoDisplay: false,
      search: '',
      elevation: 'elevation-1',
      uu: '',
      dialog: false,
      send_delete_request: false,
      current_resource_delete: 0,
      dialog_delete: {
        title: '',
        message: '',
        "continue": '',
        cancel: ''
      },
      all: {},
      elsUrls: []
    };
  },
  created: function created() {
    var self = this;
    self.initial_request = this.$options.propsData.url;
    axios.get(this.initial_request).then(function (response) {
      self.all = response.data.obj;
      self.headers = response.data.obj.headers;
      self.dati = response.data.obj.dati;
      self.link_new = response.data.obj.link_new;
      self.dialog_delete = response.data.obj.msg_delete;

      if (response.data.obj.uu !== undefined) {
        self.uu = response.data.obj.uu;
      }

      self.isVideoDisplay = true;
    });
  },
  watch: {
    all: {
      handler: function handler() {
        console.log(this.all.pages);

        if (this.all.pages !== undefined && this.all.pages.next) {
          this.activeOtherData();
        }
      },
      deep: true
    }
  },
  methods: {
    getData: function getData(url) {
      var self = this;
      self.initial_request = this.$options.propsData.url;
      axios.get(url).then(function (response) {
        self.all = response.data.obj;
        self.headers = response.data.obj.headers;
        self.dati = response.data.obj.dati;
        self.link_new = response.data.obj.link_new;
        self.dialog_delete = response.data.obj.msg_delete;

        if (response.data.obj.uu !== undefined) {
          self.uu = response.data.obj.uu;
        }

        self.isVideoDisplay = true;
      });
    },
    activeOtherData: function activeOtherData() {
      var _this = this;

      if (this.all.pages.next) {
        if (!this.elsUrls.includes(this.all.pages.follow)) {
          this.elsUrls.push(this.all.pages.follow);
          axios.get(this.all.pages.follow).then(function (response) {
            for (var i = 0; i < response.data.obj.dati.length; i++) {
              _this.dati.push(response.data.obj.dati[i]);
            }

            setTimeout(function () {
              _this.all = response.data.obj;
            }, 500);
          })["catch"](function (error) {});
        }
      }
    },
    loadData: function loadData(url) {
      var self = this;
      axios.get(url).then(function (response) {
        self.dati = response.data.data;
      });
    },
    deleteResources: function deleteResources(id) {
      var self = this;
      this.current_resource_delete = id;
      this.dialog = true;
    },
    continueToDelete: function continueToDelete() {
      var self = this;
      axios.post(this.current_resource_delete).then(function (data) {
        //self.$router.push({ path: '/supersu/structure/view' });
        self.getData(self.initial_request);
      });
      self.dialog = false;
    },
    cancelDelete: function cancelDelete() {
      this.current_resource_delete = 0;
      this.dialog = false;
    },
    save: function save(item) {
      var a = item.edit.split('/').pop();
      var self = this;

      if (parseInt(item.custom_order) > 10000) {
        item.custom_order = 10000;
      }

      if (self.uu !== '') {
        axios({
          method: 'post',
          url: self.uu,
          data: {
            data: item.custom_order,
            uu: a
          }
        }).then(function (response) {});
      }
    }
  }
};