var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: _vm.margine,
      attrs: {
        dark: "",
        fab: "",
        absolute: _vm.objects.absolute,
        bottom: _vm.objects.bottom,
        right: _vm.objects.right,
        top: _vm.objects.top,
        left: _vm.objects.left,
        color: "blue",
        margin: ""
      },
      on: {
        click: function($event) {
          return _vm.navigatoToUrl(_vm.navLink)
        }
      }
    },
    [
      _c("v-icon", { attrs: { dark: "", color: "white" } }, [
        _vm._v(_vm._s(_vm.icona))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }