var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { "margin-top": "110px", height: "70%" },
      attrs: {
        "mini-variant": _vm.mini,
        "hide-overlay": "",
        permanent: "",
        fixed: "",
        clipped: "",
        floating: ""
      },
      on: {
        "update:miniVariant": function($event) {
          _vm.mini = $event
        },
        "update:mini-variant": function($event) {
          _vm.mini = $event
        }
      },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "v-toolbar",
        { staticClass: "transparent", attrs: { flat: "" } },
        [
          _c(
            "v-list",
            { staticClass: "pa-0" },
            [
              _c(
                "v-list-tile",
                { attrs: { avatar: "" } },
                [
                  _c(
                    "v-list-tile-avatar",
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "", fab: "" } },
                        [_c("v-icon", [_vm._v("menu")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.titolo !== "" && _vm.titolo !== undefined
                    ? _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v(
                              _vm._s(_vm.$createSubString(_vm.titolo, 0, 18)) +
                                "..."
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-tile-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.mini = !_vm.mini
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("chevron_left")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list",
        { staticClass: "pt-0", attrs: { dense: "" } },
        [
          _c("v-divider"),
          _vm._v(" "),
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "v-list-tile",
              {
                key: item.name,
                on: {
                  click: function($event) {
                    return _vm.gotoSection(item.section)
                  }
                }
              },
              [
                _c("v-list-tile-action", [
                  _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getNumber(index)) +
                        "\n                "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "v-list-tile-content",
                  [
                    _c("v-list-tile-title", { staticClass: "text-uppercase" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }