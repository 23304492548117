//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Dialog",
  props: ['dialog', 'data'],
  data: function data() {
    return {
      dialogo: false
    };
  },
  created: function created() {//this.dialogo=this.$options.propsData.dialog;
  },
  mounted: function mounted() {//console.log('sto montando il dialogo');
    //console.log(this.$options.propsData);
  },
  computed: {
    canShowDialog: function canShowDialog() {
      this.dialogo = this.$options.propsData.dialog;
      return this.dialogo;
    }
  },
  watch: {
    dialogo: {
      handler: function handler() {
        console.log(this.dialogo);
      }
    }
  },
  methods: {
    closeDialog: function closeDialog() {
      //this.$options.propsData.dialog=false;
      //this.dialogo=false;
      //console.log(this.$options.propsData.dialog);
      this.$emit('update:dialog', false);
      this.$emit('update:openDialog', false);
    }
  }
};