var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.biografy
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("userSidebar", { attrs: { ready: _vm.biografy } }),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { md9: "", sm12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0 ma-4" },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color: "transparent",
                                    flat: "",
                                    dense: "",
                                    card: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticClass: "title ft-200" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t_("FE_UTENTE_URL_PERSONALE")
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c("b-nav-text", [
                                    _vm._v(
                                      "\n\n                                https://app.myopenbadge.com/profile/" +
                                        _vm._s(_vm.user.my_custom_url) +
                                        "\n                            "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t_(
                                        "FE_UTENTE_INFORMAZIONI_URL"
                                      ),
                                      disabled: _vm.disable
                                    },
                                    model: {
                                      value: _vm.url_personalizzato,
                                      callback: function($$v) {
                                        _vm.url_personalizzato = $$v
                                      },
                                      expression: "url_personalizzato"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0 ma-4" },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color: "transparent",
                                    flat: "",
                                    dense: "",
                                    card: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticClass: "title ft-200" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t_("FE_UTENTE_BIOGRAFIA"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c("vue-editor", {
                                    model: {
                                      value: _vm.user.config.biography,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.user.config,
                                          "biography",
                                          $$v
                                        )
                                      },
                                      expression: "user.config.biography"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0 ma-4" },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color: "transparent",
                                    flat: "",
                                    dense: "",
                                    card: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticClass: "title ft-200" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t_("FE_UTENTE_ADD_NOTES"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c("vue-editor", {
                                    model: {
                                      value: _vm.user.config.add_notes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.user.config,
                                          "add_notes",
                                          $$v
                                        )
                                      },
                                      expression: "user.config.add_notes"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { md3: "", sm12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0 ma-4" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-card-title", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_FORM_DATI_AGGIUNTIVI_UTENTI_PRINCIPALI"
                                          )
                                        ) +
                                        "\n                            "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { column: "" },
                                      model: {
                                        value: _vm.permissionView,
                                        callback: function($$v) {
                                          _vm.permissionView = $$v
                                        },
                                        expression: "permissionView"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: _vm.$t_(
                                            "FE_FORM_DATI_ADD_COMPLETAMENTE_PUB_VISUALIZZA_PROFILO"
                                          ),
                                          value: "1"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-radio", {
                                        attrs: {
                                          label: _vm.$t_(
                                            "FE_FORM_DATI_ADD_SOLO_AUTH_VISUALIZZA_PROFILO"
                                          ),
                                          value: "2"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-radio", {
                                        attrs: {
                                          label: _vm.$t_(
                                            "FE_FORM_DATI_ADD_NESSUNO_VISUALIZZA_PROFILO"
                                          ),
                                          value: "0"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            { staticClass: "elevation-0 transparent pt-4" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "blue" },
                                      on: {
                                        click: function($event) {
                                          return _vm.SendData()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t_(
                                              "FE_FORM_DATI_BTN_UPDATE_PROFILO"
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("ProgressBar", { attrs: { dialog: _vm.showLoader } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }