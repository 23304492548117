var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        { attrs: { id: "scroll-target" } },
        [
          _vm.showSubBar
            ? _c("SubBar", { attrs: { items: _vm.getItemSubBar } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-layout",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: _vm.onScroll,
                  expression: "onScroll"
                }
              ],
              attrs: { row: "", wrap: "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-4 elevation-0 badge" },
                    [
                      _c(
                        "v-card-text",
                        {},
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md3: "", sm5: "", xs12: "" } },
                                [
                                  _c("v-img", {
                                    attrs: { src: _vm.badge.image, contain: "" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md9: "", sm7: "", xs12: "" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "title" },
                                    [
                                      _c("vue-simple-markdown", {
                                        staticClass: "display-1",
                                        attrs: {
                                          source:
                                            _vm.badge.compose.content[0].name
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md4: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "transparent" },
                                            [
                                              _c("v-list-tile", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "blue--text pr-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_DATA_DISPONIBILE"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.badge.disp_from
                                                    ) +
                                                    "\n                                            "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("v-list-tile", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "blue--text pr-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "LABEL_EMESSO_DA_BADGE"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.badge.show.desc
                                                    ) +
                                                    "\n                                            "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-tile",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "blue--text pr-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t_("Owners")
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "success",
                                                      attrs: {
                                                        flat: "",
                                                        icon: "",
                                                        success: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.badge.emissioni
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.haveEndorser()
                                                ? _c(
                                                    "v-list-tile",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "blue--text pr-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t_(
                                                                "FE_LABEL_ENDORSEMENT"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "orange",
                                                          attrs: {
                                                            flat: "",
                                                            icon: "",
                                                            success: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.badge
                                                                .endorsement
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pt-3",
                                          attrs: { md8: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "blue--text pr-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_TIPOLOGIA_DI_BADGE"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            _vm._s(_vm.badge.typeBadge) +
                                              "\n                                          "
                                          ),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "blue--text pr-3" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t_("FE_TAGS_FE"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.badge.compose.content[0].tags,
                                            function(item) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "grey lighten-4",
                                                    attrs: {
                                                      round: "",
                                                      small: "",
                                                      dark: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.PushThis(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item))]
                                                )
                                              ]
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm.badge.compose.attributes.length
                                            ? _vm._l(
                                                _vm.badge.compose.attributes,
                                                function(ecm, indecm) {
                                                  return ecm.key ===
                                                    "Crediti ECM"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "blue--text pr-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(ecm.key)
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "primary text-white white-text",
                                                              attrs: {
                                                                flat: "",
                                                                icon: "",
                                                                success: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  ecm.value
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _c("br")
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                }
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "wrap-madi-divider" }),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "subheading",
                          domProps: { innerHTML: _vm._s(_vm.badge.description) }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.badge.description) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.badge.attachment.length > 0
                        ? _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-list",
                                _vm._l(_vm.badge.attachment, function(
                                  attachment,
                                  indexAtt
                                ) {
                                  return _c(
                                    "v-list-tile",
                                    {
                                      key: indexAtt,
                                      attrs: { avatar: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.forceDownload(
                                            attachment.guid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-tile-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "blue lighten-1 white--text"
                                            },
                                            [_vm._v("attach_file")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(_vm._s(attachment.file_name))
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "elevation-0 pa-4 mt-5 grey lighten-5 criteri"
                    },
                    [
                      _c("v-card-title", { staticClass: "display-1" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t_("FE_CRITERI_LABEL")) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "wrap-madi-divider" }),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { domProps: { innerHTML: _vm._s(_vm.badge.criteria) } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.badge.criteria) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 pa-4 mt-5 issuer" },
                    [
                      _c("v-card-title", { staticClass: "display-1" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t_("LABEL_EMESSO_DA_BADGE")) +
                            " " +
                            _vm._s(_vm.badge.azienda[0].name) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "wrap-madi-divider" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("AziendaIssuer", {
                            attrs: { azienda: _vm.badge.azienda[0] }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.badge.endorseObj.length > 0
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "elevation-0 transparent mt-5 endorsement"
                        },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t_("FE_LABEL_ENDORSEMENT_CARDS")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c("GlobalCards", {
                            attrs: {
                              callRemote: false,
                              remoteUrlCall: "",
                              subheaderShow: false,
                              subheaderObjectData: false,
                              dimensions: "md4 sm6 xs12",
                              baseLink: "/mob",
                              loopItem: _vm.badge.endorseObj,
                              tipologia: "orgs"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.Calcprogetti.length > 0
                    ? _c(
                        "v-card",
                        {
                          staticClass: "elevation-0 transparent mt-5 progetti"
                        },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t_("SUB_HEADER_PROGETTI")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c("GlobalCards", {
                            attrs: {
                              callRemote: false,
                              remoteUrlCall: "",
                              subheaderShow: false,
                              subheaderObjectData: false,
                              dimensions: "md4 sm6 xs12",
                              baseLink: "/mob",
                              loopItem: _vm.Calcprogetti,
                              tipologia: "project"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.badge.child !== undefined &&
                  _vm.badge.child.card !== undefined &&
                  _vm.badge.child.card.length > 0
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "elevation-0 transparent mt-5 childMilestone"
                        },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v("\n\t\t\t\t\t\tMilestone\n\t\t\t\t\t")
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c("GlobalCards", {
                            attrs: {
                              callRemote: false,
                              remoteUrlCall: "",
                              subheaderShow: false,
                              subheaderObjectData: false,
                              dimensions: "md4 sm6 xs12",
                              baseLink: "/mob",
                              loopItem: _vm.badge.child.card,
                              tipologia: "badge"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }