var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "post-card" },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "transparent", flat: "", dense: "", card: "" } },
        [
          _c("v-toolbar-title", { staticClass: "subheading ft-200" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { icon: "" } },
            [
              _c("v-icon", { staticClass: "text--secondary" }, [
                _vm._v("more_vert")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _vm.enableFilter
        ? _c(
            "v-card-title",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Filter" },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-card-text", { staticClass: "pa-0" }, [
        _c(
          "ul",
          { staticClass: "post--list flex-list vertical" },
          _vm._l(_vm.filtered, function(item, key) {
            return _vm.ready
              ? _c("li", { key: key, staticClass: "post--item" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        " post--link pa-4 layout row ma-0 text--primary",
                      on: {
                        click: function($event) {
                          return _vm.navigateToUrl(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "post--media" }, [
                        _c("img", {
                          staticClass: "image-scale",
                          attrs: { src: item.image, alt: "", height: "100" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "post--content ml-3" }, [
                        _c("h3", { staticClass: "title post--title" }, [
                          item.lang !== undefined
                            ? _c("img", {
                                staticStyle: { "max-height": "25px" },
                                attrs: {
                                  alt: item.lang,
                                  src: _vm.$immaginiNazione(item.lang)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" " + _vm._s(item.title) + "\n            ")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "post--desc py-2 text--secondary",
                            domProps: {
                              innerHTML: _vm._s(item.desc.substr(0, 150))
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.desc.substr(0, 150) + "...") +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "post--meta o-flex justify-space-between"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "post--author caption grey--text text--darken-1"
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.author))]),
                                _vm._v(" "),
                                _c("time", { staticClass: "px-2" }, [
                                  _vm._v(_vm._s(item.createdAt))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "social" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "grey--text text--darken-1",
                                  on: { click: _vm.handleThumb }
                                },
                                [
                                  item.icon !== ""
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("thumb_up")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("small", [_vm._v(_vm._s(item.uno))])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "grey--text text--darken-1 mx-3",
                                  on: { click: _vm.handleComment }
                                },
                                [
                                  item.icon !== ""
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mode_comment")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("small", [_vm._v(_vm._s(item.due))])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "grey--text text--darken-1",
                                  on: { click: _vm.handleFavorite }
                                },
                                [
                                  item.icon !== ""
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("favorite")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("small", [_vm._v(_vm._s(item.tre))])
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e()
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm.paginate.length > 1
        ? _c(
            "v-card-actions",
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "text-xs-center" },
                  [
                    _c("v-pagination", {
                      attrs: { length: _vm.paginate.length, circle: "" },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }