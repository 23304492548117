var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 transparent" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-img", {
                                    staticStyle: {
                                      margin: "auto",
                                      "max-width": "150px"
                                    },
                                    attrs: {
                                      src: _vm.all.user.meta.profile_image,
                                      contain: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { xs8: "" } }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.all.user.first_name) +
                                      " " +
                                      _vm._s(_vm.all.user.last_name) +
                                      "\n                            "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "elevation-0 pa-4 mt-5  criteri"
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.all.user.meta.biography
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.all.user.meta.biography
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "elevation-0 pa-4 mt-5 grey lighten-5 criteri"
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.all.user.meta.add_notes
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.all.user.meta.add_notes
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isVideoMode
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _vm._l(_vm.badgeCard, function(item, index) {
                    return _vm.showAllBadges
                      ? [
                          !item.revoked && parseInt(item.show_public) === 1
                            ? _c(
                                "v-flex",
                                { class: _vm.dim },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "ma-4 pa-2 elevation-0" },
                                    [
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            staticStyle: {
                                              "margin-top": "-25px",
                                              "margin-left": "-25px"
                                            },
                                            attrs: { row: "", wrap: "" }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "blue"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              !item.revoked &&
                                                              !item.expired
                                                                ? _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          fab:
                                                                            "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "blue accent-2",
                                                                          top:
                                                                            "",
                                                                          left:
                                                                            "",
                                                                          outline:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {}
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.permissions
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.tooltip)
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "red"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              item.expired &&
                                                              !item.revoked
                                                                ? _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "red accent-2",
                                                                          top:
                                                                            "",
                                                                          left:
                                                                            "",
                                                                          fab:
                                                                            "",
                                                                          outline:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {}
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "timer_off"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "FE_TOOLTIP_UTENTE_BADGE_ASSERTION_TIMESTAMP"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "red"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              item.revoked
                                                                ? _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "red accent-2",
                                                                          top:
                                                                            "",
                                                                          left:
                                                                            "",
                                                                          outline:
                                                                            "",
                                                                          fab:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {}
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "error_outline"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "_TABLE_INTEST_revoked"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      _vm._v(" "),
                                      _c(
                                        "v-card-title",
                                        [
                                          _c("vuetify-lazy-image", {
                                            staticClass: "badge",
                                            style:
                                              "max-height: 200px;min-height: 200px ;margin: -8px;background-size: 60% auto;" +
                                              _vm.GreyColorBadge(item),
                                            attrs: {
                                              src: _vm.getUrlFile(item.image),
                                              contain: true
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-toolbar",
                                        {
                                          staticClass: "elevation-0 white",
                                          staticStyle: {
                                            height: "33px",
                                            background: "transparent !important"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-toolbar-items",
                                            {
                                              staticClass: "indigo--text",
                                              staticStyle: {
                                                position: "absolute",
                                                "padding-top": "15px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(item.header) +
                                                  "\n                        "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-toolbar-items",
                                            {
                                              staticStyle: {
                                                float: "right",
                                                "padding-left": "80%"
                                              }
                                            },
                                            [
                                              _c("m-hex-btn", {
                                                staticStyle: { float: "right" },
                                                attrs: {
                                                  margin: "",
                                                  icon: "arrow_forward",
                                                  link:
                                                    "/receive/" +
                                                    _vm.all.user.meta
                                                      .my_custom_url +
                                                    "/" +
                                                    item.guid +
                                                    "/public",
                                                  object: _vm.object
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "grey--text pt-4 pb-4 body-2 madi-style-ccard",
                                          staticStyle: {
                                            width: "92%",
                                            height: "65px !important"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.$t_("LABEL_EMESSO_DA_BADGE")
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "blue--text" },
                                            [_vm._v(_vm._s(item.issuer))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "title  pt-4 pb-5   font-weight-medium madi-style-ccard madi-ellips"
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(item.name) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "grey--text madi-ellips pb-5"
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Tags: "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "blue-grey--text" },
                                            [_vm._v(_vm._s(item.tags))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticStyle: {
                                          border: "dashed rgba(0,0,0,.12)",
                                          "border-width": "thin 0 0"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      : void 0
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }