var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-label",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        { staticClass: "pa-3", attrs: { col12: "" } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_c("h3", [_vm._v("OpenApi 3")])]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("json-viewer", {
                    attrs: { value: _vm.def, "expand-depth": 10 }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }