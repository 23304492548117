//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GlobalCards from '../../components/GlobalCards';
export default {
  name: "SearchIndex",
  props: ["my_url", "request"],
  components: {
    "GlobalCards": GlobalCards
  },
  data: function data() {
    return {
      lists: [],
      isError: false,
      isVideoMode: false,
      showProgressBar: true
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var self = this;
      axios({
        method: 'post',
        url: '/mob/search',
        data: {
          data: this.$options.propsData.request
        }
      }).then(function (response) {
        if (response.data.code === 200) {
          self.lists = response.data.obj;
          self.isVideoMode = true;
          self.isError = false;
          self.showProgressBar = false;
        } else {//errore non trovato
        }
      });
    }
  }
};