//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tables from '../../../app/components/table';
import VFormBase from '../../../../node_modules/vuetify-form-base';
import Dialog from '../../../app/components/utils/Dialog';
export default {
  name: "EmailUtente",
  components: {
    tables: tables,
    VFormBase: VFormBase,
    Dialog: Dialog
  },
  data: function data() {
    return {
      isVideoMode: false,
      emailUtente: {},
      dialog: false,
      dialog_delete: {},
      addEmail: false,
      remaing: {},
      valid: false,
      inputs: {},
      is_disableBTN: true,
      openDialog: false,
      dataDialog: {
        title: '',
        message: '',
        btn_ok: this.$t_('BTN_CLOSE')
      },
      changeEmail: false,
      selected: '',
      oldPrimary: '',
      radioBtn: [],
      showBtnChange: false,
      ricongiungiEmail: false,
      showReuinionModal: false,
      inputReunionMail: '',
      dataModal: {
        title: '',
        body: '',
        inputReunionMail: ''
      }
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  watch: {
    selected: function selected() {
      if (this.selected !== this.oldPrimary) this.showBtnChange = true;else this.showBtnChange = false;
    }
  },
  methods: {
    getEmailSelecheSwitch: function getEmailSelecheSwitch() {
      var self = this;

      for (var i in self.emailUtente.dati) {
        if (self.emailUtente.dati[i].is_primary) {
          this.selected = self.emailUtente.dati[i].new_email;
          this.oldPrimary = self.emailUtente.dati[i].new_email;
        }
      }
    },
    getData: function getData() {
      var self = this;
      self.isVideoMode = true;
      axios.get('/user/getVuEmail').then(function (data) {
        self.emailUtente = data.data.obj;
        self.remaing = data.data.obj.user_pack;
        self.dialog_delete = data.data.obj.msg_delete;
        self.inputs = data.data.obj.new_model;
        self.inputs.schema = self.$getRulesInput(self.inputs.schema);
        self.getEmailSelecheSwitch();
        self.isVideoMode = true;
      });
    },
    deleteResources: function deleteResources(id) {
      var self = this;
      this.current_resource_delete = id;
      this.dialog = true;
    },
    continueToDelete: function continueToDelete() {
      var self = this;
      axios.post(this.current_resource_delete).then(function (data) {
        //self.$router.push({ path: '/supersu/structure/view' });
        self.getData();
      });
      self.dialog = false;
    },
    cancelDelete: function cancelDelete() {
      this.current_resource_delete = 0;
      this.dialog = false;
    },
    getStatus: function getStatus(stato) {
      switch (stato) {
        case '1':
          return this.$t_('FE_STATO_EMAIL_UTENTE_ATTIVO');
          break;

        default:
          return this.$t_('FE_STATO_EMAIL_UTENTE_NON_ATTIVO');
      }
    },
    canAddEmail: function canAddEmail() {
      if (parseInt(this.remaing.max_email) > 0) return true;else return false;
    },
    update: function update(_ref) {
      var on = _ref.on,
          id = _ref.id,
          key = _ref.key,
          value = _ref.value,
          obj = _ref.obj,
          event = _ref.event,
          params = _ref.params,
          size = _ref.size,
          data = _ref.data,
          schema = _ref.schema;
      var self = this; //console.log(self.all_images);
    },
    isValidForm: function isValidForm() {
      return this.$refs.form.validate();
    },
    haveSameEmail: function haveSameEmail() {
      for (var i in this.emailUtente.dati) {
        if (this.emailUtente.dati[i].new_email === this.inputs.values.email_f) return false;
      }

      return true;
    },
    sendChecked: function sendChecked() {
      var _this = this;

      axios({
        method: 'post',
        url: this.dataModal.checkUrl,
        data: {
          verify: this.inputReunionMail
        }
      }).then(function (response) {
        _this.showReuinionModal = false;
        _this.dataDialog = response.data.obj.message;
        _this.dataDialog.btn_ok = _this.$t_('BTN_CLOSE');
        _this.openDialog = true;
        setTimeout(function () {
          _this.addEmail = false;

          _this.getData();
        }, 3500);
      })["catch"](function (error) {});
    },
    SendData: function SendData() {
      var self = this;

      if (this.isValidForm()) {
        this.valid = true;

        if (this.inputs.values.email_f === this.inputs.values.email_s) {
          if (this.haveSameEmail()) {
            axios({
              method: 'post',
              url: '/user/add_new_Vuemail',
              data: this.inputs.values
            }).then(function (response) {
              self.dataDialog = response.data.obj.message;
              self.dataModal = response.data.obj.message;

              if (response.data.obj.message.showBoxVerify !== undefined) {
                self.showReuinionModal = true;
              } else {
                self.openDialog = true;
                setTimeout(function () {
                  self.addEmail = false;
                  self.getData();
                }, 3500);
              }
            });
          } else {
            this.dataDialog.message = this.$t_('FE_MESSAGE_UTENTE_DIALOG_MESSAGE_EMAIL_PRESENTE');
            this.dataDialog.title = this.$t_('FE_TITLE_UTENTE_BNT_ERROR_TITLE_EMAIL_DIVERSE');
            this.openDialog = true;
          }
        } else {
          this.dataDialog.title = this.$t_('FE_TITLE_UTENTE_BNT_ERROR_TITLE_EMAIL_DIVERSE');
          this.dataDialog.message = this.$t_('FE_MESSAGE_UTENTE_DIALOG_MESSAGE_EMAIL_DIVERSE');
          this.openDialog = true;
        }
      }
    },
    checkVerifyCodeMail: function checkVerifyCodeMail(data) {},
    ChangeEmailUser: function ChangeEmailUser() {
      var self = this; //selected:'',
      // oldPrimary:'',

      axios({
        method: 'post',
        url: '/user/changePrimaryEmailUser',
        data: {
          find: this.oldPrimary,
          replace: this.selected
        }
      }).then(function (response) {
        self.dataDialog = response.data.obj;
        self.openDialog = true;
        setTimeout(function () {
          self.ExitAndLogout();
        }, 4000);
      });
    },
    ExitAndLogout: function ExitAndLogout() {
      window.location = '/auth/logout';
    }
  }
};