//
//
//
//
//
//
//
//
//
//
//
import tables from '../../../app/components/table';
export default {
  name: "ClusterTags",
  components: {
    tables: tables
  },
  data: function data() {
    return {
      isVideoMode: false
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var self = this;
      axios.get('/user/getMyCluster').then(function (response) {
        self.isVideoMode = true;
      });
    }
  }
};