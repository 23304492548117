//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//$2y$08$riIYw9JuUjNBNKzm5yXepeTjLvNM82p/KJP/l0ws0eyeoJsSKgNNm
import Loader from "../../utils/widget/loader";
import Notarization from "./notarization";
export default {
  name: "UserBadgeDetails",
  props: ["guid_badge"],
  components: {
    Notarization: Notarization,
    Loader: Loader
  },
  data: function data() {
    return {
      isVideoMode: false,
      showLoader: false,
      currentBadge: {
        badge: {},
        validator: {}
      },
      networks: [{
        network: 'email',
        name: 'Email',
        icon: 'far fah fa-lg fa-envelope',
        color: '#333333'
      }, {
        network: 'facebook',
        name: 'Facebook',
        icon: 'fab fah fa-lg fa-facebook-f',
        color: '#1877f2'
      }, {
        network: 'linkedin',
        name: 'LinkedIn',
        icon: 'fab fah fa-lg fa-linkedin',
        color: '#007bb5'
      }, {
        network: 'pinterest',
        name: 'Pinterest',
        icon: 'fab fah fa-lg fa-pinterest',
        color: '#bd081c'
      }, {
        network: 'telegram',
        name: 'Telegram',
        icon: 'fab fah fa-lg fa-telegram-plane',
        color: '#0088cc'
      }, {
        network: 'twitter',
        name: 'Twitter',
        icon: 'fab fah fa-lg fa-twitter',
        color: '#1da1f2'
      }, {
        network: 'viber',
        name: 'Viber',
        icon: 'fab fah fa-lg fa-viber',
        color: '#59267c'
      }, {
        network: 'whatsapp',
        name: 'Whatsapp',
        icon: 'fab fah fa-lg fa-whatsapp',
        color: '#25d366'
      }],
      network_1: [],
      deleteDialog: false,
      deleteDialogCard: {
        title: this.$t_('FE_UTENTE_BADGE_TITOLO_CANCELLA_BADGE'),
        message: this.$t_('FE_UTENTE_BADGE_MESSAGE_CANCELLA_BADGE'),
        cancel: false,
        "continue": false
      },
      badgeForm: {},
      iteration: [{
        name: this.$t_('FE_UTENTE_BADGES_EDIT_FORM_OPTIONS'),
        description: this.$t_('FE_UTENTE_BADGES_EDIT_DESCRIPTION_FORM_OPTIONS'),
        color: 'cyan',
        active: 'form'
      }, {
        name: this.$t_('FE_UTENTE_BADGES_EDIT_SHARE'),
        description: this.$t_('FE_UTENTE_BADGES_EDIT_DESCRIPTION_SHARE'),
        color: 'orange',
        active: 'share'
      }, {
        name: this.$t_('FE_UTENTE_BADGES_EDIT_ATTESTATO'),
        description: this.$t_('FE_UTENTE_BADGES_EDIT_DESCRIPTION_ATTESTATO'),
        color: 'grey',
        active: 'attestato'
      }, {
        name: this.$t_('FE_UTENTE_BADGES_EDIT_VALIDATOR'),
        description: this.$t_('FE_UTENTE_BADGES_EDIT_DESCRIPTION_VALIDATOR'),
        color: 'green',
        active: 'validator'
      }],
      backToMenu: false,
      isMenu: true,
      isValidator: false,
      isShareOptions: false,
      isFormControl: false,
      haveCustomData: false,
      activeCustomData: false,
      embedded: '',
      permissionView: null,
      googleView: '0',
      infoNotarization: null,
      showNotarizationModal: false,
      finder_current: {}
    };
  },
  computed: {
    iterCards: {
      get: function get() {
        var my = [];

        for (var i in this.iteration) {
          if (this.iteration[i].active === 'attestato') {
            if (this.currentBadge.badge.is_mob_object === '1') my.push(this.iteration[i]);
          } else my.push(this.iteration[i]);
        }

        if (this.haveCustomData) {
          console.log(this.currentBadge);
          var t = {
            name: this.$t_('FE_UTENTE_BADGE_CUSTOM_DATA_UTENTE_VIEW'),
            description: this.$t_('FE_UTENTE_BADGE_DESCRIPTION_CUSTOM_DATA_UTENTE_VIEW'),
            color: 'purple',
            active: 'customData'
          };
          my.push(t);
        }

        return my;
      },
      set: function set() {}
    }
  },
  watch: {
    permissionView: {
      handler: function handler() {
        switch (this.permissionView) {
          case '0':
            this.currentBadge.badge.only_auth = 0;
            this.currentBadge.badge.show_public = 0;
            break;

          case '1':
            this.currentBadge.badge.only_auth = 0;
            this.currentBadge.badge.show_public = 1;
            break;

          case '2':
            this.currentBadge.badge.only_auth = 1;
            this.currentBadge.badge.show_public = 0;
            break;
        }
      }
    },
    googleView: {
      handler: function handler() {
        switch (this.googleView) {
          case '0':
            this.currentBadge.badge.for_goolge = "0";
            break;

          default:
            this.currentBadge.badge.for_goolge = '1';
        }
      }
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    actionsUser: function actionsUser(network) {
      console.log({
        net: network,
        badge: this.currentBadge.badge
      });
      axios({
        method: 'post',
        url: '/huge/statsBackpack',
        data: {
          net: network.network,
          badge: this.currentBadge.badge.guid_badge
        }
      }).then(function (response) {})["catch"](function (error) {});
    },
    updateBadgeShare: function updateBadgeShare() {
      var self = this;
      self.showLoader = true;
      axios({
        method: 'post',
        url: '/user/updateBadge',
        data: {
          badge: this.currentBadge.badge
        }
      }).then(function (data) {
        axios.get('/user/VueBackpack').then(function (response) {
          self.$root.backpack = response.data.obj;
          self.getCurrentBadge();
          self.showLoader = false;
        });
      });
    },
    CancellaBadge: function CancellaBadge() {
      console.log(this.currentBadge.badge.id);
      var self = this;
      axios({
        method: 'post',
        url: '/user/deleteBadge',
        data: {
          guid: this.currentBadge.badge.guid_badge
        }
      }).then(function (response) {
        axios.get('/user/VueBackpack').then(function (response) {
          self.backpack = response.data.obj;
          self.$root.backpack = self.backpack;
          self.$router.push('/backpack/badges');
        });
      });
    },
    getData: function getData() {
      var self = this;

      if (this.$root.backpack !== undefined) {
        this.getCurrentBadge();
      } else {
        axios.get('/user/VueBackpack').then(function (response) {
          self.$root.backpack = response.data.obj;
          self.getCurrentBadge();
        });
      }
    },
    findPermission: function findPermission() {
      if (this.currentBadge.badge.show_public === "0" && this.currentBadge.badge.only_auth === "0") this.permissionView = "0";else if (this.currentBadge.badge.show_public === "1" && this.currentBadge.badge.only_auth === "0") this.permissionView = "1";else this.permissionView = "2";
    },
    getCurrentBadge: function getCurrentBadge() {
      for (var i in this.$root.backpack.badges) {
        if (this.$root.backpack.badges[i].guid_badge === this.$options.propsData.guid_badge) {
          this.currentBadge.badge = this.$root.backpack.badges[i];
          this.currentBadge.validator = this.$root.backpack.validator[i].validation;
          this.$root.newData = this.$root.backpack.validator[i].validation;
          this.trovaCustomData();
          this.getEmbeddedBadge();
          this.findPermission();
          this.googleView = this.currentBadge.badge.for_goolge;
          this.isVideoMode = true;
          console.log(this.currentBadge);

          if (this.currentBadge.badge.notarize !== undefined && this.currentBadge.badge.notarize) {
            this.getNotarizationData();
          }
        }
      } // this.currentBadge.badge.my_tags=['uno','due','tre','quattro'];

    },
    getRandomInt: function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    getNotarizationData: function getNotarizationData() {
      var _this = this;

      axios.get("/user/notarization/".concat(this.currentBadge.badge.guid_badge)).then(function (response) {
        _this.infoNotarization = response.data.obj;
      });
    },
    trovaCustomData: function trovaCustomData() {
      if (this.currentBadge.badge.is_mob_object === '1') {
        if (this.currentBadge.badge.compose !== undefined && this.currentBadge.badge.compose.attributes !== undefined) {
          if (this.currentBadge.badge.compose.attributes[0] !== undefined) {
            if (this.currentBadge.badge.compose.attributes[0].key !== undefined && this.currentBadge.badge.compose.attributes[0].key !== '') {
              this.haveCustomData = true;
            } else this.haveCustomData = false;
          } else this.haveCustomData = false;
        } else this.haveCustomData = false;
      } else this.haveCustomData = false;
    },
    resetAllMenu: function resetAllMenu() {
      this.isMenu = true;
      this.isValidator = false;
      this.backToMenu = false;
      this.isShareOptions = false;
      this.isFormControl = false;
      this.activeCustomData = false;
    },
    activeShowThis: function activeShowThis(item) {
      switch (item.active) {
        case 'validator':
          this.actionsUser({
            network: 'validator'
          });
          this.isMenu = false;
          this.isValidator = true;
          this.backToMenu = true;
          break;

        case 'attestato':
          var url = '/certificate/' + this.currentBadge.badge.guid_badge;
          this.actionsUser({
            network: 'printer_pdf'
          });
          window.open(url, '_blank');
          break;

        case 'share':
          this.isMenu = false;
          this.isShareOptions = true;
          this.backToMenu = true;
          break;

        case 'form':
          this.isFormControl = true;
          this.backToMenu = true;
          break;

        case 'customData':
          this.activeCustomData = true;
          this.backToMenu = true;
          break;
      }
    },
    getNambeBadge: function getNambeBadge() {
      for (var i in this.currentBadge.validator.graph) {
        if (this.currentBadge.validator.graph[i].type === 'BadgeClass') {
          if (this.currentBadge.validator.graph[i].name !== undefined) return this.currentBadge.validator.graph[i].name;
        }
      }
    },
    getIssuerName: function getIssuerName() {
      for (var i in this.currentBadge.validator.graph) {
        if (this.currentBadge.validator.graph[i].type === 'Issuer') {
          if (this.currentBadge.validator.graph[i].name !== undefined) return this.currentBadge.validator.graph[i].name;
        }
      }
    },
    getUrlFile: function getUrlFile(item) {
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

      if (!regex.test(item)) {
        return '/' + item;
      } else {
        return item;
      }
    },
    getEmbeddedBadge: function getEmbeddedBadge() {
      var self = this;

      if (this.currentBadge.badge.is_mob_object) {
        axios.get('/user/getEmbedded/' + this.currentBadge.badge.master_guid_badge).then(function (response) {
          self.embedded = response.data.obj.html;
        });
      }
    },
    downloadBadge: function downloadBadge(url) {
      window.open(url, '_blank');
    },
    apriLinkedin: function apriLinkedin() {
      /*Luigi, 17:41
      https://www.linkedin.com/in/luigi-susanna-9b448520/edit/certification/new/?certId=27921159
      certUrl=https%3A%2F%2Fwww.credential.net%2F6add8c32-3c7b-4df7-87db-98711837703f
      isFromA2p=true
      issueMonth=1
      issueYear=2021
      name=Networking
      organizationName=SSNLGU59D03E716N*/
      var dataIssuing = new Date(this.currentBadge.badge.iTXt.issuedOn);
      var el = 'https://www.linkedin.com/profile/add?';
      var opt = {
        certId: this.currentBadge.badge.guid_badge,
        certUrl: 'https://app.myopenbadge.com/' + this.$root.profile.config.my_custom_url + '/' + this.currentBadge.badge.guid_badge + '/public',
        name: this.getNambeBadge(),
        organizationName: this.getIssuerName(),
        isFromA2p: true,
        issueYear: dataIssuing.getFullYear(),
        issueMonth: dataIssuing.getMonth() + 1
      };
      /*	let certId=this.currentBadge.badge.guid_badge;
      	let certUrl='https://app.myopenbadge.com/'+this.$root.profile.config.my_custom_url+'/'+this.currentBadge.badge.guid_badge+'/public';
      	let name=this.getNambeBadge();
      	let organizationName=this.getIssuerName();*/

      if (this.currentBadge.badge.iTXt.expires !== undefined && this.currentBadge.badge.iTXt.expires !== '') {
        var dataExpired = new Date(this.currentBadge.badge.iTXt.expires);
        opt.expirationYear = dataExpired.getFullYear();
        opt.expirationMonth = dataExpired.getMonth() + 1;
      }

      console.log([Object.keys(opt), opt]);

      for (var i = 0; i < Object.keys(opt); i++) {
        el = el + opt[i] + '=' + opt[opt[i]] + '&';
      }

      var params = new URLSearchParams(opt).toString();
      console.log(new URLSearchParams(opt).toString());
      console.log(el);
      window.open(el + params, '_blank');
    }
  }
};