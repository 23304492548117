var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.badge !== null
    ? _c(
        "div",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            _vm._l(_vm.badge, function(item) {
              return _c(
                "v-flex",
                { key: item.type, attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { elevation: "0" } },
                    [
                      _c("v-card-title", [
                        _c("h3", [
                          _vm._v(
                            "\n\t\t\t\t\t\tNotary type: " +
                              _vm._s(_vm._f("matchssa")(item.type)) +
                              "\n\t\t\t\t\t"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-card-title", [
                        _c("h4", [
                          _vm._v(
                            "\n\t\t\t\t\t\tLast Update: " +
                              _vm._s(item.last_update) +
                              "\n\t\t\t\t\t"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-list",
                            { attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("Notary Hash")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-tile-sub-title", [
                                        _vm._v(
                                          _vm._s(item.receipt.notary.blockHash)
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-tile-action",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", ripple: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.navigateToUrl(
                                                item.receipt.notary.blockHash
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "grey lighten-1" }
                                            },
                                            [_vm._v("verified_user")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel",
                            [
                              _c(
                                "v-expansion-panel-content",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function() {
                                          return [
                                            _c("div", [_vm._v("Details")])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("json-viewer", {
                                            attrs: {
                                              value: item.receipt.notary,
                                              "expand-depth": 5
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }