var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("userSidebar", { attrs: { ready: true } }),
      _vm._v(" "),
      _vm.isVideoMode
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [_c("tables", { attrs: { myurl: "/user/getMyCluster" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }