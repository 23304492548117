//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VueEditor } from "vue2-editor";
export default {
  name: "PersonalBio",
  components: {
    VueEditor: VueEditor
  },
  data: function data() {
    return {
      isVideoMode: true,
      biografy: false,
      user: {},
      permissionView: null,
      url_personalizzato: '',
      showLoader: false,
      currentCall: 0,
      disable: false,
      is_busy: false
    };
  },
  watch: {
    biografy: {
      handler: function handler() {
        console.log(this);
      }
    },
    permissionView: {
      handler: function handler() {
        switch (this.permissionView) {
          case '0':
            this.user.config.profile_public = 0;
            this.user.config.show_only_auth_user = 0;
            this.user.show_only_auth_user = 0;
            this.user.show_only_auth_user = 0;
            break;

          case '1':
            this.user.config.profile_public = 1;
            this.user.config.show_only_auth_user = 1;
            this.user.profile_public = 1;
            this.user.show_only_auth_user = 1;
            break;

          case '2':
            this.user.config.profile_public = 0;
            this.user.config.show_only_auth_user = 1;
            this.user.profile_public = 0;
            this.user.show_only_auth_user = 1;
            break;
        }
      }
    },
    url_personalizzato: {
      handler: function handler() {
        var self = this;

        if (self.url_personalizzato !== self.user.config.my_custom_url) {
          if (!this.is_busy) {
            if (this.url_personalizzato.length > 5) {
              this.is_busy = true;
              axios({
                method: 'post',
                url: '/user/checkUrlPersonalizzato',
                data: {
                  url: this.url_personalizzato
                }
              }).then(function (response) {
                self.url_personalizzato = response.data.obj.url;
                self.user.config.my_custom_url = response.data.obj.url;
                self.user.my_custom_url = response.data.obj.url;
                self.is_busy = false;
              });
            }
          }
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.$root.profile === undefined) {
      this.$router.push('/backpack');
    } else {
      this.user = this.$root.profile;
      this.url_personalizzato = this.user.config.my_custom_url;
      this.findPermission();
      this.biografy = true;
    }
  },
  methods: {
    findPermission: function findPermission() {
      if (parseInt(this.user.config.profile_public) === 0 && parseInt(this.user.config.show_only_auth_user) === 0) this.permissionView = "0";else if (parseInt(this.user.config.profile_public) === 1 && parseInt(this.user.config.show_only_auth_user) === 1) this.permissionView = "1";else this.permissionView = "2";
    },
    SendData: function SendData() {
      var self = this;
      this.showLoader = true;
      this.user.config.my_custom_url = self.url_personalizzato;
      this.user.my_custom_url = self.url_personalizzato;
      axios({
        method: 'post',
        url: '/user/aggiornaDatiUtente',
        data: {
          all: this.user,
          image: [],
          data: this.user
        }
      }).then(function (response) {
        axios.get('/user/get_Vueprofile').then(function (response) {
          self.user = response.data.obj.all;
          self.$root.userInfo = self.user;
          self.$root.profile = self.user;
          self.url_personalizzato = self.user.config.my_custom_url; //self.schema=response.data.obj.new_model.schema;

          self.findPermission();
          self.showLoader = false;
        });
      });
    }
  }
};