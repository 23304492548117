var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("userSidebar", { attrs: { ready: _vm.isVideoMode } }),
          _vm._v(" "),
          _c("ProgressBar", { attrs: { dialog: _vm.loader } }),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { md8: "", sm7: "", xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 ml-4 mr-4" },
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            color: "transparent",
                            flat: "",
                            dense: "",
                            card: ""
                          }
                        },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "title ft-200" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t_(
                                      "FE_UTENTE_PAGINA_CARD_TITOLO_PAGINA_DATI_PRINCIPALE"
                                    )
                                  ) +
                                  "\n\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-title",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openNewNavTab()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        Page url:\n                        "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.openNewNavTab()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            https://app.myopenbadge.com/page/" +
                                  _vm._s(_vm.all.page.guid) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("v-form-base", {
                                attrs: {
                                  value: _vm.modello.values,
                                  schema: _vm.modello.schema
                                },
                                on: { update: _vm.update }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("v-card-title", [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t_("FE_UTENTE_SFONDO_SSFONDO")) +
                                  "\n                            "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.sfondi, function(item) {
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "min-height": "55px" },
                                  attrs: { color: _vm.getColor(item) },
                                  on: {
                                    click: function($event) {
                                      return _vm.setSfondo(item)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticStyle: { "max-height": "40px" },
                                    attrs: { src: item },
                                    on: {
                                      click: function($event) {
                                        return _vm.setSfondo(item)
                                      }
                                    }
                                  })
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.showSeparatore
                        ? _c(
                            "v-card-text",
                            [
                              _c("v-card-title", [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(
                                    "\n                                Separatore\n                            "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.separatori, function(item) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "min-height": "55px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setSeparatore(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { "max-height": "40px" },
                                        attrs: { src: item },
                                        on: {
                                          click: function($event) {
                                            return _vm.setSeparatore(item)
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { md4: "", sm5: "", xs12: "" } },
                [
                  _c("images", { attrs: { immagine: _vm.immagine.image } }),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-0 mt-5 pt-3",
                      staticStyle: {
                        "background-color": "transparent !important"
                      }
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pb-5" },
                        [_c("v-divider")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { block: "", dark: "", color: "blue" },
                              on: {
                                click: function($event) {
                                  _vm.dialog = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t_(
                                      "FE_UTENTE_PAGINA_APRI_MODALE_FULL_SCREEN"
                                    )
                                  ) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { block: "", dark: "", color: "red" },
                              on: {
                                click: function($event) {
                                  _vm.deleteDialog = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t_(
                                      "MSG_DELETE_PAGINA_UTENTE_PERCHE_NON_E_UNA_RISORSA"
                                    )
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { absolute: "", bottom: "", right: "", color: "blue" },
                  on: { click: _vm.sendData }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t_("FE_UTENTE_PAGINA_SALVA_LA_PAGINA_")) +
                      "\n            "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                "hide-overlay": "",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { id: "create" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", color: "primary" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", flat: "" },
                              on: { click: _vm.salvaContenuto }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.$t_("BADGE_BTN_TERMINA_CHIUDI_BADGE")
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs9: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          attrs: { expand: "" },
                                          model: {
                                            value: _vm.collapse,
                                            callback: function($$v) {
                                              _vm.collapse = $$v
                                            },
                                            expression: "collapse"
                                          }
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              staticClass: "xs-12",
                                              staticStyle: { width: "100%" },
                                              model: {
                                                value: _vm.object,
                                                callback: function($$v) {
                                                  _vm.object = $$v
                                                },
                                                expression: "object"
                                              }
                                            },
                                            _vm._l(_vm.object, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "v-expansion-panel-content",
                                                {
                                                  key: index,
                                                  staticClass: "xs-12",
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "header",
                                                        fn: function() {
                                                          return [
                                                            _c("div", [
                                                              _vm._v(
                                                                "\n                                                                    " +
                                                                  _vm._s(
                                                                    _vm.getItemTitle(
                                                                      item.type
                                                                    )
                                                                  ) +
                                                                  "\n                                                                "
                                                              )
                                                            ])
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  item.type === "text"
                                                    ? _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0 ma-4",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.currentIndex = index
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                flat: "",
                                                                dense: "",
                                                                card: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "title ft-200"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t_(
                                                                        "FE_UTENTE_PAGINA_TITOLO_CARD_EDITOR"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "text--secondary",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeElement(
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c("vue-editor", {
                                                                model: {
                                                                  value:
                                                                    _vm.object[
                                                                      index
                                                                    ].text,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .object[
                                                                        index
                                                                      ],
                                                                      "text",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "object[index].text"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.type === "badge"
                                                    ? _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0 ma-4",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.currentIndex = index
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                flat: "",
                                                                dense: "",
                                                                card: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "title ft-200"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t_(
                                                                        "FE_UTENTE_PAGINA_TITOLO_CARD_BADGE"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "text--secondary",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeElement(
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        sm4: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-img",
                                                                        {
                                                                          staticStyle: {
                                                                            "max-height":
                                                                              "150px"
                                                                          },
                                                                          attrs: {
                                                                            src: _vm.getImageExsists(
                                                                              index
                                                                            ),
                                                                            contain:
                                                                              ""
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          model: {
                                                                            value:
                                                                              item.view,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "view",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.view"
                                                                          }
                                                                        },
                                                                        _vm._l(
                                                                          _vm.viewBadge,
                                                                          function(
                                                                            select
                                                                          ) {
                                                                            return _c(
                                                                              "v-radio",
                                                                              {
                                                                                key: select,
                                                                                attrs: {
                                                                                  label: _vm.$t_(
                                                                                    "FE_UTENTE_LABEL_VIEW_TYPE_PERSONAL_PAGE_" +
                                                                                      select.toUpperCase()
                                                                                  ),
                                                                                  value: select
                                                                                }
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        sm8: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-data-table",
                                                                        {
                                                                          staticClass:
                                                                            "elevation-1",
                                                                          attrs: {
                                                                            items:
                                                                              item.cBadges
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "headers",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                                        Badge\n                                                                                    "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                                        Name\n                                                                                    "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "th"
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              },
                                                                              {
                                                                                key:
                                                                                  "items",
                                                                                fn: function(
                                                                                  props
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _c(
                                                                                          "v-img",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "max-height":
                                                                                                "25px"
                                                                                            },
                                                                                            attrs: {
                                                                                              src: _vm.getUrlFile(
                                                                                                props
                                                                                                  .item
                                                                                                  .image
                                                                                              ),
                                                                                              contain:
                                                                                                ""
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                                        " +
                                                                                            _vm._s(
                                                                                              props
                                                                                                .item
                                                                                                .info
                                                                                                .badge
                                                                                                .title
                                                                                            ) +
                                                                                            "\n                                                                                    "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              fab:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              orange:
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.setThis(
                                                                                                  props
                                                                                                    .item
                                                                                                    .id,
                                                                                                  index
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "add"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.type === "biografy"
                                                    ? _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0 ma-4",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.currentIndex = index
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                flat: "",
                                                                dense: "",
                                                                card: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "title ft-200"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t_(
                                                                        "FE_UTENTE_PAGINA_TITOLO_CARD_BIOGRAFY"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "text--secondary",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeElement(
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  item.text
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                    " +
                                                                  _vm._s(
                                                                    item.text
                                                                  ) +
                                                                  "\n                                                                "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.type === "note"
                                                    ? _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0 ma-4",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.currentIndex = index
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                flat: "",
                                                                dense: "",
                                                                card: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "title ft-200"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t_(
                                                                        "FE_UTENTE_PAGINA_TITOLO_CARD_NOTES"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "text--secondary",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeElement(
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  item.text
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                    " +
                                                                  _vm._s(
                                                                    item.text
                                                                  ) +
                                                                  "\n                                                                "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.type === "attachment"
                                                    ? _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0 ma-4",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.currentIndex = index
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                flat: "",
                                                                dense: "",
                                                                card: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "title ft-200"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t_(
                                                                        "FE_UTENTE_PAGINA_TITOLO_CARD_ATTACHMENT"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "text--secondary",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeElement(
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "vue-dropzone",
                                                                    {
                                                                      ref:
                                                                        "myVueDropzone",
                                                                      refInFor: true,
                                                                      attrs: {
                                                                        id:
                                                                          "dropzone",
                                                                        options:
                                                                          _vm.dropzoneOptions
                                                                      },
                                                                      on: {
                                                                        "vdropzone-sending":
                                                                          _vm.sendingEvent,
                                                                        "vdropzone-success":
                                                                          _vm.getResponse
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.typeAction ===
                                                              "up"
                                                                ? _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        row: "",
                                                                        wrap: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list",
                                                                        {
                                                                          staticClass:
                                                                            "pa-0",
                                                                          attrs: {
                                                                            "two-line":
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._l(
                                                                            item.items,
                                                                            function(
                                                                              data,
                                                                              indice
                                                                            ) {
                                                                              return [
                                                                                _c(
                                                                                  "v-list-tile",
                                                                                  {
                                                                                    key:
                                                                                      data.id,
                                                                                    attrs: {
                                                                                      avatar:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {}
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-tile-avatar",
                                                                                      [
                                                                                        data.type.includes(
                                                                                          "image"
                                                                                        )
                                                                                          ? _c(
                                                                                              "img",
                                                                                              {
                                                                                                attrs: {
                                                                                                  src:
                                                                                                    data.path_save
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-tile-content",
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-tile-title",
                                                                                          {
                                                                                            domProps: {
                                                                                              innerHTML: _vm._s(
                                                                                                data.file_name
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-list-tile-sub-title",
                                                                                          {
                                                                                            domProps: {
                                                                                              innerHTML: _vm._s(
                                                                                                data.type
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-tile-action",
                                                                                      [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              fab:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              icon:
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.removeItemsAttachment(
                                                                                                  index,
                                                                                                  indice
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "delete"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          )
                                                                        ],
                                                                        2
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.type === "image"
                                                    ? _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0 ma-4",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.currentIndex = index
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                flat: "",
                                                                dense: "",
                                                                card: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "title ft-200"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t_(
                                                                        "FE_UTENTE_PAGINA_TITOLO_CARD_IMMAGINE"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "text--secondary",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeElement(
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "vue-dropzone",
                                                                    {
                                                                      ref:
                                                                        "myVueDropzone",
                                                                      refInFor: true,
                                                                      attrs: {
                                                                        id:
                                                                          "dropzone",
                                                                        options:
                                                                          _vm.dropzoneOptionsOneFile
                                                                      },
                                                                      on: {
                                                                        "vdropzone-sending":
                                                                          _vm.sendingEvent,
                                                                        "vdropzone-success":
                                                                          _vm.getResponse
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.typeAction ===
                                                              "up"
                                                                ? _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        row: "",
                                                                        wrap: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list",
                                                                        {
                                                                          staticClass:
                                                                            "pa-0",
                                                                          attrs: {
                                                                            "two-line":
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._l(
                                                                            item.items,
                                                                            function(
                                                                              data,
                                                                              indice
                                                                            ) {
                                                                              return [
                                                                                _c(
                                                                                  "v-list-tile",
                                                                                  {
                                                                                    key:
                                                                                      data.id,
                                                                                    attrs: {
                                                                                      avatar:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {}
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-tile-avatar",
                                                                                      [
                                                                                        data.type.includes(
                                                                                          "image"
                                                                                        )
                                                                                          ? _c(
                                                                                              "img",
                                                                                              {
                                                                                                attrs: {
                                                                                                  src:
                                                                                                    data.path_save
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-tile-content",
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-tile-title",
                                                                                          {
                                                                                            domProps: {
                                                                                              innerHTML: _vm._s(
                                                                                                data.file_name
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-list-tile-sub-title",
                                                                                          {
                                                                                            domProps: {
                                                                                              innerHTML: _vm._s(
                                                                                                data.type
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-tile-action",
                                                                                      [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              fab:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              icon:
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.removeItemsAttachment(
                                                                                                  index,
                                                                                                  indice
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "delete"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          )
                                                                        ],
                                                                        2
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.type === "badgeGroup"
                                                    ? _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0 ma-4",
                                                          on: {
                                                            clikc: function(
                                                              $event
                                                            ) {
                                                              _vm.currentIndex = index
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                flat: "",
                                                                dense: "",
                                                                card: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "title ft-200"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t_(
                                                                        "FE_UTENTE_PAGINA_TITOLO_CARD_GROUP_BADGE"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "text--secondary",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeElement(
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            items:
                                                                              _vm
                                                                                .all
                                                                                .tags,
                                                                            label:
                                                                              "Select Tag"
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.FiltraBadge(
                                                                                item,
                                                                                index
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              item.tag,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "tag",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.tag"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          model: {
                                                                            value:
                                                                              item.view,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "view",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.view"
                                                                          }
                                                                        },
                                                                        _vm._l(
                                                                          _vm.viewBadgeGroup,
                                                                          function(
                                                                            select
                                                                          ) {
                                                                            return _c(
                                                                              "v-radio",
                                                                              {
                                                                                key: select,
                                                                                attrs: {
                                                                                  label: _vm.$t_(
                                                                                    "FE_UTENTE_LABEL_VIEW_TYPE_PERSONAL_PAGE_" +
                                                                                      select.toUpperCase()
                                                                                  ),
                                                                                  value: select
                                                                                }
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-data-table",
                                                                        {
                                                                          staticClass:
                                                                            "elevation-1",
                                                                          attrs: {
                                                                            items:
                                                                              item.items
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "headers",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                                        Badge\n                                                                                    "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Name"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              },
                                                                              {
                                                                                key:
                                                                                  "items",
                                                                                fn: function(
                                                                                  props
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _c(
                                                                                          "v-img",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              "max-height":
                                                                                                "25px"
                                                                                            },
                                                                                            attrs: {
                                                                                              src: _vm.getUrlFile(
                                                                                                props
                                                                                                  .item
                                                                                                  .image
                                                                                              ),
                                                                                              contain:
                                                                                                ""
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                                        " +
                                                                                            _vm._s(
                                                                                              props
                                                                                                .item
                                                                                                .info
                                                                                                .badge
                                                                                                .title
                                                                                            ) +
                                                                                            "\n                                                                                    "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.type === "cluster_tags"
                                                    ? _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0 ma-4",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.currentIndex = index
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                flat: "",
                                                                dense: "",
                                                                card: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "title ft-200"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t_(
                                                                        "FE_UTENTE_AGGIUNGI_CLUSTER_IN_PAGINA"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "text--secondary",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeElement(
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "v-list",
                                                                {
                                                                  attrs: {
                                                                    subheader:
                                                                      "",
                                                                    "two-line":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-subheader",
                                                                    [
                                                                      _vm._v(
                                                                        "Clusters"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    item.cluster,
                                                                    function(
                                                                      clusterItem,
                                                                      indexCluster
                                                                    ) {
                                                                      return _c(
                                                                        "v-list-tile",
                                                                        {
                                                                          key: indexCluster,
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {}
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-tile-action",
                                                                            [
                                                                              _c(
                                                                                "v-list-tile-action",
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      item.id = indexCluster
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-checkbox",
                                                                                    {
                                                                                      attrs: {
                                                                                        value:
                                                                                          indexCluster ===
                                                                                          parseInt(
                                                                                            item.id
                                                                                          )
                                                                                            ? true
                                                                                            : false
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-list-tile-content",
                                                                            {
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  item.id = indexCluster
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-tile-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      clusterItem.clustern_name
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-list-tile-sub-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      clusterItem
                                                                                        .cluster_tags
                                                                                        .wordcloud
                                                                                        .length
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          item.id >= 0
                                                            ? _c(
                                                                "v-card-text",
                                                                _vm._l(
                                                                  item.cluster[
                                                                    item.id
                                                                  ].cluster_tags
                                                                    .wordcloud,
                                                                  function(
                                                                    chips,
                                                                    tin
                                                                  ) {
                                                                    return _c(
                                                                      "v-chip",
                                                                      {
                                                                        key: tin
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-avatar",
                                                                          {
                                                                            staticClass:
                                                                              "teal"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                chips[1]
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              chips[0]
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs3: "" } },
                            [
                              _vm.addEditorCheck
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "white--text ma-2",
                                      attrs: { color: "blue" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { attrs: { "primary-title": "" } },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "headline" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_TITOLO_CARD_EDITOR"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_EDITOR"
                                                    )
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_EDITOR"
                                                      )
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", dark: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addEditor()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_UTENTE_PAGINA_BTN_CARD_EDITOR"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.addEditorCheck
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "white--text ma-2",
                                      attrs: { color: "cyan" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { attrs: { "primary-title": "" } },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "headline" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_TITOLO_CARD_IMMAGINE"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_IMMAGINE"
                                                    )
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_IMMAGINE"
                                                      )
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", dark: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addImmagine()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_UTENTE_PAGINA_BTN_CARD_IMMAGINE"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.addBadge
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "white--text  ma-2",
                                      attrs: { color: "blue" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { attrs: { "primary-title": "" } },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "headline" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_TITOLO_CARD_BADGE"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_BADGE"
                                                    )
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_BADGE"
                                                      )
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", dark: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.AddBadge()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_UTENTE_PAGINA_BTN_CARD_BADGE"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                {
                                  staticClass: "white--text  ma-2",
                                  attrs: {
                                    color: _vm.getClassCard(_vm.addGroupBadge)
                                  }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { attrs: { "primary-title": "" } },
                                    [
                                      _c("div", [
                                        _c("div", { staticClass: "headline" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t_(
                                                "FE_UTENTE_PAGINA_TITOLO_CARD_GROUP_BADGE"
                                              )
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm.addGroupBadge
                                          ? _c(
                                              "div",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_GROUP_BADGE"
                                                    )
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_GROUP_BADGE"
                                                      )
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_TITOLO_CARD_ERROE_GROUP_BADGE"
                                                    )
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_PAGINA_TITOLO_CARD_ERROE_GROUP_BADGE"
                                                      )
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ]
                                            )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _vm.addGroupBadge
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", dark: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.AddBadgeGroups()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_UTENTE_PAGINA_BTN_CARD_GROUP_BADGE"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                {
                                  staticClass: "white--text ma-2",
                                  attrs: {
                                    color:
                                      _vm.clusters.length > 0 ? "blue" : "red"
                                  }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { attrs: { "primary-tile": "" } },
                                    [
                                      _c("div", [
                                        _c("div", { staticClass: "headline" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.$t_(
                                                  "FE_UTENTE_AGGIUNGI_CLUSTER_IN_PAGINA"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t_(
                                                  "FE_UTENTE_AGGIUNGI_DESCRIZIONE_CLUSTER_IN_PAGINA"
                                                )
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_UTENTE_AGGIUNGI_DESCRIZIONE_CLUSTER_IN_PAGINA"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.clusters.length > 0
                                    ? _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", dark: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.AddCluster()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_UTENTE_PAGINA_BTN_CARD_GROUP_BADGE"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.addAtch
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "white--text  ma-2",
                                      attrs: { color: "blue" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { attrs: { "primary-title": "" } },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "headline" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_TITOLO_CARD_ATTACHMENT"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_ATTACHMENT"
                                                    )
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_ATTACHMENT"
                                                      )
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", dark: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.Attachment()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_UTENTE_PAGINA_BTN_CARD_ATTACHMENT"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _vm.addBiografy
                                        ? _c(
                                            "v-card",
                                            {
                                              staticClass: "white--text  ma-2",
                                              attrs: {
                                                color: "green lighten-3"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "headline"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_UTENTE_PAGINA_TITOLO_CARD_BIOGRAFY"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.$t_(
                                                              "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_BIOGRAFY"
                                                            )
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(
                                                              _vm.$t_(
                                                                "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_BIOGRAFY"
                                                              )
                                                            ) +
                                                            "\n                                                            "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        dark: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.AddBio()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "FE_UTENTE_PAGINA_BTN_CARD_BIOGRAFY"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _vm.addNote
                                        ? _c(
                                            "v-card",
                                            {
                                              staticClass: "white--text  ma-2",
                                              attrs: {
                                                color: "green lighten-3"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "headline"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_UTENTE_PAGINA_TITOLO_CARD_NOTES"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.$t_(
                                                              "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_NOTES"
                                                            )
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(
                                                              _vm.$t_(
                                                                "FE_UTENTE_PAGINA_DESCRIZIONE_CARD_NOTES"
                                                              )
                                                            ) +
                                                            "\n                                                            "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        dark: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.AddNotes()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "FE_UTENTE_PAGINA_BTN_CARD_NOTES"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "650" },
                  model: {
                    value: _vm.deleteDialog,
                    callback: function($$v) {
                      _vm.deleteDialog = $$v
                    },
                    expression: "deleteDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "red lighten-3",
                          attrs: {
                            color: "transparent",
                            flat: "",
                            dense: "",
                            card: ""
                          }
                        },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "subheading ft-200" },
                                [_vm._v(_vm._s(_vm.deleteDialogCard.title))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.deleteDialogCard.message) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "indifo darken-1", flat: "" },
                              on: {
                                click: function($event) {
                                  _vm.deleteDialog = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t_("BTN_ANNULLA_OPERAZIONE")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red darken-1", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.CancellaPagina()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t_("BTN_CONTINUA_CANCELLAZIONE"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }