import Router from 'vue-router';
import CardsGeneral from '../components/CardsGeneral';
import BadgeDetails from '../components/BadgeDetails';
import CardsOrganizzazioni from '../components/CardsOrganizzazioni';
import GlobalCards from '../components/GlobalCards';
import login from '../components/login';
import SearchIndex from '../components/search/SearchIndex';
import Progetto from '../components/Progetto';
import Organizzation from '../components/Organizzation';
import Validator from '../components/Validator';
import ValidatorResponse from '../components/ValidatorResponse';
import Backpack from '../components/backpack/Backpack';
import EmailUtente from '../components/backpack/EmailUtente';
import BackPackBadges from '../components/backpack/BackPackBadges';
import UserBadgeDetails from '../components/backpack/UserBadgeDetails';
import AddNewBadge from '../components/backpack/AddNewBadge';
import PersonalBio from '../components/backpack/PersonalBio';
import PagesList from '../components/backpack/pages/PagesList';
import ProfiloUtente from '../components/backpack/ProfiloUtente';
import PagineEditNew from '../components/backpack/pages/PagineEditNew';
import ReceiveBadge from '../components/backpack/ReceiveBadge';
import PagineUtente from '../components/PagineUtente';
import TakeBadge from '../components/TakeBadge';
import takeNewBadge from '../components/takeNewBadge';
import zMessage from '../components/zMessage';
import LogsUtente from '../components/backpack/LogsUtente';
import ClusterTags from '../components/backpack/ClusterTags';
import ClusterTagsEditCreate from '../components/backpack/ClusterTagsEditCreate';
import OpenApi from "../components/oApi/OpenApi";
import TakeNewBadgeOrgs from "../components/TakeNewBadgeOrgs";
export default new Router({
  linkActiveClass: 'active',
  mode: 'history',
  canReuse: true,
  routes: [{
    path: '/',
    name: 'demo'
    /* component: FindAziende,
     props: route => ({
         myurl: '/admin/get_utenti_azienda/'
     }),*/

  }, {
    path: '/auth/activate/:user/:code',
    name: 'FreeuserMessage',
    component: zMessage,
    props: function props(route) {
      return {
        type: 'activation',
        utn: route.params.user,
        code: route.params.code
      };
    }
  }, {
    path: '/sys/:action',
    name: 'FreeuserMessageS',
    component: zMessage,
    props: function props(route) {
      return {
        type: route.params.action,
        utn: '',
        code: ''
      };
    }
  }, {
    path: '/login',
    name: 'login',
    component: login,
    props: function props(route) {
      return {
        type: 'login'
      };
    }
  }, {
    path: '/resetpassword',
    name: 'Reset',
    component: login,
    props: function props(route) {
      return {
        type: 'resetpassword'
      };
    }
  }, {
    path: '/auth/login',
    name: 'Alogin',
    component: login,
    props: function props(route) {
      return {
        type: 'login'
      };
    }
  }, {
    path: '/register',
    name: 'registrati',
    component: login,
    props: function props(route) {
      return {
        type: 'register'
      };
    }
  }, {
    path: '/logout',
    name: 'logout',
    component: login,
    props: function props(route) {
      return {
        type: 'logout'
      };
    }
  }, {
    path: '/take/:findBadge',
    name: 'takeBadge',
    component: TakeBadge,
    props: function props(route) {
      return {
        guid_badge: route.params.findBadge
      };
    }
  }, {
    path: '/mineBadge/:findBadge',
    name: 'takeBadgeNew',
    //component:TakeBadge,
    component: takeNewBadge,
    props: function props(route) {
      return {
        guid_badge: route.params.findBadge
      };
    },
    redirect: function redirect(to) {
      return {
        path: '/obadge-issuer-check/' + to.params.findBadge
      };
    }
  }, {
    path: '/obadge-issuer-check/:findBadge',
    name: 'takeBadgeNewOrgView',
    //component:TakeBadge,
    component: TakeNewBadgeOrgs,
    props: function props(route) {
      return {
        guid_badge: route.params.findBadge
      };
    }
  }, {
    path: '/mineNewBadge/:badge',
    name: 'takeNewBadgeNew',
    component: takeNewBadge,
    props: function props(route) {
      return {
        guid_badge: route.params.badge
      };
    }
  }, {
    path: '/auth/reset_password/:code',
    name: 'resetPassword',
    component: login,
    props: function props(route) {
      return {
        type: 'resetCode',
        codice: route.params.code
      };
    }
  }, {
    path: '/search/:request',
    name: 'showBadgeVisualizza',
    component: SearchIndex,
    props: function props(route) {
      return {
        my_url1: '/mob/getBadges/' + route.params.request,
        my_url: '/getBadges/' + route.params.request,
        request: route.params.request
      };
    }
  }, //visualizzazione dei badge nel frontend
  {
    path: '/badge/show',
    name: 'showBadge',
    component: GlobalCards,
    props: function props(route) {
      return {
        myurl: '/mob/getBadges/',
        videoMode: false,
        remoteUrlCall: '/mob/getBadges/',
        callRemote: true,
        subheaderShow: true,
        subheaderObjectData: {
          titolo: 'FE_BADGE_TITOLO_HEADER',
          desc: 'FE_BADGE_DESCRIZIONE_HEADER'
        },
        dimensions: 'lg3 md4 sm6 xs12',
        baseLink1: '/mob',
        baseLink: '',
        loopItem: [],
        filterIncomponent: false
      };
    }
  }, {
    path: '/badge/show/:page',
    name: 'showBadgePage',
    component: GlobalCards,
    props: function props(route) {
      return {
        myurl: '/mob/getBadges/' + (route.params.page - 1),
        videoMode: true,
        remoteUrlCall: '/mob/getBadges/' + (route.params.page - 1),
        callRemote: true,
        subheaderShow: true,
        subheaderObjectData: {
          titolo: 'FE_BADGE_TITOLO_HEADER',
          desc: 'FE_BADGE_DESCRIZIONE_HEADER'
        },
        dimensions: 'lg3 md4 sm6 xs12',
        baseLink1: '/mob',
        baseLink: '',
        loopItem: []
      };
    }
  }, //visualizza un badge specifico
  {
    path: '/badge/detail/:badge',
    name: 'showDetailsBadge',
    component: BadgeDetails,
    props: function props(route) {
      return {
        myurl: '/mob/getBadgeDetails/' + route.params.badge
      };
    }
  }, {
    path: '/progetti/show',
    name: 'showProjectAzienda',
    component: GlobalCards,
    props: function props(route) {
      return {
        myurl: '/mob/getProject/',
        remoteUrlCall: '/mob/getProject/',
        callRemote: true,
        subheaderShow: true,
        subheaderObjectData: {
          titolo: 'FE_PROJECT_TITOLO_HEADER',
          desc: 'FE_PROJECT_DESCRIZIONE_HEADER'
        },
        dimensions: 'lg3 md4 sm6 xs12',
        baseLink1: '/mob',
        baseLink: '',
        loopItem: [],
        filterIncomponent: true
      };
    }
  }, {
    path: '/progetti/show/:guid_project',
    name: 'showProjectAziendaOne',
    component: Progetto,
    props: function props(route) {
      return {
        myurl: '/mob/getOneProject/' + route.params.guid_project
      };
    }
  }, //// visualizzazione aziende
  //organization/show
  {
    path: '/organization/show',
    name: 'showOrganizations',
    component: GlobalCards,
    props: function props(route) {
      return {
        remoteUrlCall: '/mob/getOrgs/',
        callRemote: true,
        subheaderShow: true,
        subheaderObjectData: {
          titolo: 'FE_ORGANIZZAZIONI_TITOLO_HEADER',
          desc: 'FE_ORGANIZZAZIONI_DESCRIZIONE_HEADER'
        },
        dimensions: 'lg3 md4 sm6 xs12',
        baseLink1: '/mob',
        baseLink: '',
        loopItem: [],
        filterIncomponent: true
      };
    }
  }, {
    path: '/organization/details/:guid',
    name: 'showOrganizationsDetails',
    component: Organizzation,
    props: function props(route) {
      return {
        myurl: '/mob/getOrgsDetails/' + route.params.guid
      };
    }
  }, //validator
  {
    path: '/validator',
    name: 'Validator',
    component: Validator
  }, {
    path: '/mob/validator',
    name: 'bhnn',
    component: ValidatorResponse
  }, {
    path: '/validator/result',
    name: 'ValidatorResult',
    component: ValidatorResponse
  }, {
    //mob/backpack
    path: '/backpack',
    name: 'BackPackUtente',
    component: Backpack
  }, {
    ///mob/backpack/user
    path: '/backpack/user',
    name: 'BackPackUtenteUser',
    component: Backpack
  }, {
    ///mob/backpack/user-emails
    path: '/backpack/user-emails',
    name: 'BackPackUtenteEmailUser',
    component: EmailUtente,
    props: function props(route) {
      return {
        myurl: '/user/getVuEmail'
      };
    }
  }, {
    path: '/backpack/badges',
    name: 'BackPackBadge',
    component: BackPackBadges
  }, {
    path: '/backpack/badges/:badge',
    name: 'BackPackBadgeDetails',
    component: UserBadgeDetails,
    props: function props(route) {
      return {
        guid_badge: route.params.badge
      };
    }
  }, {
    path: '/backpack/add-badges',
    name: 'AddBadgeUser',
    component: AddNewBadge
  }, {
    path: '/backpack/cluster',
    name: 'ClusterTagsBadge',
    component: ClusterTags
  }, {
    path: '/backpack/cluster/:type',
    name: 'ClusterTagCreateEdit',
    component: ClusterTagsEditCreate,
    props: function props(route) {
      return {
        type: route.params.type
      };
    }
  }, {
    path: '/backpack/personal-page',
    name: 'InformazioniPersonali',
    component: PersonalBio
  }, {
    path: '/backpack/user-pages',
    name: 'PagineUtente',
    component: PagesList
  }, {
    path: '/backpack/user-pages/:page',
    name: 'CreateEditPage',
    component: PagineEditNew,
    props: function props(route) {
      return {
        pagina: route.params.page
      };
    }
  }, //Controlla pagina utente
  {
    path: '/page/:page',
    name: 'ViewPaginaUtente',
    component: PagineUtente,
    props: function props(route) {
      return {
        pagina: route.params.page
      };
    }
  }, {
    path: '/profile/:user',
    name: 'VediProfiloUtente',
    component: ProfiloUtente,
    props: function props(route) {
      return {
        profilo: route.params.user
      };
    }
  }, {
    path: '/receive/:badge/:userUrl/public',
    name: 'BadgeRicevuti',
    component: ReceiveBadge,
    props: function props(route) {
      return {
        badge: route.params.badge,
        userurl: route.params.userUrl
      };
    }
  }, {
    path: '/backpack/logs',
    name: 'GetLog',
    component: LogsUtente
  }, {
    path: '/api/mob',
    name: 'OpenApi3',
    component: OpenApi
  }]
});