//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VueSimpleMarkdown } from 'vue-simple-markdown';
export default {
  name: "GlobalCards",
  components: {
    'vue-simple-markdown': VueSimpleMarkdown
  },
  props: ["callRemote", "remoteUrlCall", "subheaderShow", "subheaderObjectData", "dimensions", "baseLink", "loopItem", "tipologia", "inPages", "filterIncomponent"],
  data: function data() {
    return {
      remoteUrl: false,
      urlCall: '',
      subheader: false,
      subheaderObject: {
        titolo: '',
        desc: ''
      },
      dim: 'md4 sm6 xs12',
      baseUrl: '',
      items: [],
      isVideoMode: false,
      object: {
        absolute: true,
        bottom: true,
        top: false,
        left: false,
        right: true
      },
      paginate: false,
      page: {},
      type: 'normal',
      max_dim: 70,
      rotta: '',
      progress: true,
      currentVal: 0,
      interval: {},
      contain: true,
      loopInPages: false,
      currentPageLoop: 1,
      currentLength: 0,
      max_chunck: 8,
      search: ''
    };
  },
  created: function created() {
    if (this.$options.propsData.callRemote !== undefined) this.remoteUrl = this.$options.propsData.callRemote;
    if (this.$options.propsData.remoteUrlCall !== undefined) this.urlCall = this.$options.propsData.remoteUrlCall;
    if (this.$options.propsData.subheaderShow !== undefined) this.subheader = this.$options.propsData.subheaderShow;
    if (this.$options.propsData.subheaderObjectData !== undefined) this.subheaderObject = this.$options.propsData.subheaderObjectData;
    if (this.$options.propsData.dimensions !== undefined) this.dim = this.$options.propsData.dimensions;
    if (this.$options.propsData.baseLink !== undefined) this.baseUrl = this.$options.propsData.baseLink;
    if (this.$options.propsData.loopItem !== undefined) this.items = this.$options.propsData.loopItem;
    if (this.$options.propsData.tipologia !== undefined) this.type = this.$options.propsData.tipologia;
    if (this.$options.propsData.inPages !== undefined) this.loopInPages = this.$options.propsData.inPages;
    console.log(this.$options.propsData.loopItem);
    this.findActions();
  },
  computed: {
    getItems: function getItems() {
      var _this = this;

      if (this.search !== '' || this.search !== null) {
        var i = this.items.map(function (fn) {
          return fn.name.toLowerCase().includes(_this.search.toLowerCase()) ? fn : null;
        });
        return i.filter(function (fn) {
          return fn !== null;
        });
      } else {
        return this.items;
      }
    },
    getPage: {
      get: function get() {
        //this function will determine what is displayed in the input
        return parseInt(this.page.page);
      },
      set: function set(newVal) {
        //this function will run whenever the input changes
        this.page.page = parseInt(newVal); //this.getDataPage();
        //return parseInt(this.pages.page);

        this.$router.push(this.rotta + this.page.page);
      }
    },
    listItem: {
      get: function get() {
        var _this2 = this;

        var all = this.items;
        var local = [];

        if (this.loopInPages) {
          var my = [];
          my = _.chunk(this.items, this.max_chunck); //my=_.chunk(this.getItems,8);

          this.currentLength = my.length; // return my[this.currentPageLoop-1];

          local = my[this.currentPageLoop - 1];

          if (this.search != null && this.search !== '' && this.search != null && this.search.length > 3) {
            var i = all.map(function (fn) {
              return fn.name.toLowerCase().includes(_this2.search.toLowerCase()) ? fn : null;
            });
            local = i.filter(function (fn) {
              return fn !== null;
            });
            my = _.chunk(local, this.max_chunck);
            this.currentLength = my.length;
            return local;
          } else {
            return local;
          }
        } else {
          if (this.getItems.length > 25) {
            this.max_chunck = 20;
            this.loopInPages = true;
            return this.listItem; // local = this.listItem
          } else {
            return this.getItems;
          } // this.getItems;

        }
      },
      set: function set() {}
    }
  },
  methods: {
    handleInfinityScroll: function handleInfinityScroll() {// 	this.currentPageLoop +=1
      // 	if(this.currentPageLoop > this.currentLenght)
      // 		this.currentPageLoop = this.currentLenght
      // 	console.log(this.currentPageLoop)
      // console.log("fetch more stuff");
    },
    findActions: function findActions() {
      var self = this;

      if (this.remoteUrl) {
        axios.get(this.urlCall).then(function (response) {
          if (response.data.obj.cards !== undefined) {
            self.items = response.data.obj.cards;
          } else {
            self.items = response.data.obj;
          }

          if (response.data.obj.pages !== undefined) {
            self.page = response.data.obj.pages;
            if (self.page.max > 1) self.paginate = true;
          }

          if (response.data.obj.type !== undefined) {
            self.type = response.data.obj.type;
          }

          if (response.data.obj.rotta !== undefined) {
            self.rotta = response.data.obj.rotta;
          } //setTimeout(function(){


          self.progress = false;
          self.isVideoMode = true; //},1200);
        });
      } else {
        self.progress = false;
        self.isVideoMode = true;
      }
    },
    getStyleImage: function getStyleImage() {
      switch (this.type) {
        case 'badge':
          //this.settaDimCoverImage();
          return 'max-height: 200px;min-height: 200px ;margin: -8px;background-size: 60% auto;';
          break;

        case 'orgs':
          this.settaDimCoverImage();
          return 'max-height: 200px;margin: -8px;background-size: 60% auto;';
          break;

        case 'project':
          this.settaDimCoverImage();
          return "max-height: 200px;margin: -8px;";
          break;

        default:
          this.contain = false;
          return 'max-height: 70px;min-height: 70px';
      }
    },
    settaDimCoverImage: function settaDimCoverImage() {
      this.contain = false;
    },
    isCover: function isCover() {
      return true;
      if (this.type !== 'badge') return true;else return false;
    },
    getTags: function getTags(item) {
      var string = '';

      for (var i in item) {
        if (string !== '') string = string + ', ' + item[i];else string = item[i];
      }

      return string;
    },
    haveTitle: function haveTitle(item) {
      if (item.title !== undefined) return true;else return false;
    },
    haveTags: function haveTags(item) {
      if (item.tags !== undefined) return true;else return false;
    },
    number: function number(item) {
      return parseInt(item);
    },
    createLink: function createLink(item) {
      return this.baseUrl + item;
    },
    getEmit: function getEmit() {
      if (this.type == 'badge') return this.$t_('LABEL_EMESSO_DA_BADGE');
    },
    getDimensions: function getDimensions() {
      switch (this.type) {
        case 'orgs':
          return 'padding-bottom:65px !important';
          break;

        case 'project':
          return 'height:75px !important';
          break;

        default:
          return 'height:80px !important';
      }
    },
    haveComment: function haveComment(item) {
      if (item.comment !== undefined) return true;else return false;
    }
  }
};