//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "CardsOrganizzazioni",
  props: ["myurl"],
  data: function data() {
    return {
      items: [],
      currentUrl: '',
      isVideoMode: false,
      object: {
        absolute: true,
        bottom: true,
        top: false,
        left: false,
        right: true
      }
    };
  },
  created: function created() {
    this.currentUrl = this.$options.propsData.myurl;
    this.getData();
  },
  methods: {
    getData: function getData() {
      var self = this;
      axios.get(this.currentUrl).then(function (response) {
        self.items = response.data.obj;
        self.isVideoMode = true;
      });
    },
    createLink: function createLink(item) {
      return '/mob/organization/' + item;
    }
  }
};