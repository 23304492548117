import Vue from 'vue';
window.axios = require('axios');
window.axiosFree = require('axios');
import babelPolyfill from 'babel-polyfill';
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.prototype.$axios = axios;
Vue.prototype.$axiosFree = axios;
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue';
import Vuetify from 'vuetify';
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(Vuetify);
import VueSweetalert2 from 'vue-sweetalert2';
Vue.config.devtools = false;
Vue.use(VueSweetalert2); // Vue.config.devtools = true;

import VImageInput from 'vuetify-image-input';
Vue.component(VImageInput.name, VImageInput);
import Storage from 'vue-web-storage';
Vue.use(Storage);
import Utils from '../app/utils/utilsFunct';
Vue.mixin(Utils);
import { VueSimpleMarkdown } from 'vue-simple-markdown'; // You need a specific loader for CSS files like https://github.com/webpack/css-loader

import 'vue-simple-markdown/dist/vue-simple-markdown.css';
Vue.use(VueSimpleMarkdown); //var token = document.head.querySelector('meta[name="csrf-token"]');
//axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;

/*
if(document.head.querySelector('meta[name="selected"]')!==null){
    var token = document.head.querySelector('meta[name="selected"]');
    axios.defaults.headers.common['azienda'] = token.content;
}
*/

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; //lazy

import { VuetifyLazyImagePlugin } from "vuetify-lazy-image"; //2023

import LazyComponent from "v-lazy-component";
Vue.use(LazyComponent);
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility); ///end

Vue.use(VuetifyLazyImagePlugin);
Vue.use(require('vue-moment'));
console.log(Vue.moment().locale());
import Clipboard from 'v-clipboard';
Vue.use(Clipboard);

var VueCookie = require('vue-cookie'); // Tell Vue to use the plugin


Vue.use(VueCookie);
import VueHead from 'vue-head';
Vue.use(VueHead);
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);
import ExagonButton from './utils/widget/ExagonButton';
import ProgressBar from './utils/widget/ProgressBar';
import NavigationInCards from './utils/widget/NavigationInCards';
import SubHeaderSearch from './components/SubHeaderSearch';
import SubBar from './components/SubBar';
import footers from './components/footer';
import AziendaIssuer from './components/AziedaIssuer';
import Organizzation from './components/Organizzation';
import Validator from './components/Validator';
import ValidatorResponse from './components/ValidatorResponse';
import userSidebar from './components/backpack/userSidebar';
import BackPackBadges from './components/backpack/BackPackBadges';
import UserBadgeDetails from './components/backpack/UserBadgeDetails';
import AddNewBadge from './components/backpack/AddNewBadge';
import PersonalBio from './components/backpack/PersonalBio';
import ProfiloUtente from './components/backpack/ProfiloUtente';
import PagesList from './components/backpack/pages/PagesList';
import PagineUtente from './components/PagineUtente';
import TakeBadge from './components/TakeBadge';
import zMessage from './components/zMessage';
import LogsUtente from './components/backpack/LogsUtente';
import ClusterTags from './components/backpack/ClusterTags';
import ClusterTagsEditCreate from './components/backpack/ClusterTagsEditCreate';
import OpenApi from "./components/oApi/OpenApi";
Vue.component('m-hex-btn', ExagonButton);
Vue.component('SubHeaderSearch', SubHeaderSearch);
Vue.component('ProgressBar', ProgressBar);
Vue.component('footers', footers);
Vue.component('AziendaIssuer', AziendaIssuer);
Vue.component('SubBar', SubBar);
Vue.component('NavigationInCards', NavigationInCards);
Vue.component('Organizzation', Organizzation);
Vue.component('Validator', Validator);
Vue.component('ValidatorResponse', ValidatorResponse);
Vue.component('userSidebar', userSidebar);
Vue.component('BackPackBadges', BackPackBadges);
Vue.component('UserBadgeDetails', UserBadgeDetails);
Vue.component('AddNewBadge', AddNewBadge);
Vue.component('PersonalBio', PersonalBio);
Vue.component('PagineUtente', PagineUtente);
Vue.component('TakeBadge', TakeBadge);
Vue.component('ProfiloUtente', ProfiloUtente);
Vue.component('zMessage', zMessage);
Vue.component('LogsUtente', LogsUtente);
Vue.component('ClusterTags', ClusterTags);
Vue.component('ClusterTagsEditCreate', ClusterTagsEditCreate);
Vue.component('OpenApi', OpenApi);
import App from './userFE';
import router from './router';
new Vue({
  el: '#app',
  router: router,
  data: function data() {
    return {
      headTitle: 'Home',
      headMeta: [],
      headLinks: []
    };
  },
  head: {
    title: function title() {
      return {
        inner: this.headTitle
      };
    },
    meta: function meta() {
      return this.headMeta;
    },
    link: function link() {
      return this.headLinks;
    }
  },
  watch: {
    $route: function $route() {
      //console.log(this.$router.currentRoute);
      this.getSeoPath(this.$router.currentRoute.path);
    }
  },
  created: function created() {
    var self = this;

    if (this.$cookie.get('setT') !== undefined && this.$cookie.get('appLang') === undefined) {
      this.$cookie.set('appLang', this.$cookie.get('setT'));
      this.$cookie["delete"]('setT');
    }

    if (this.$cookie.get('appLang') === undefined) {
      this.$cookie.set('appLang', 'ITA');
    }

    var see = this.$convertNazione(this.$cookie.get('appLang'));

    if (see === undefined) {
      axios.get('/t9n').then(function (response) {
        self.$localStorage.set('l', response.data);
      });
    } else {
      axios.get('/t9n/' + see).then(function (response) {
        self.$localStorage.set('l', response.data);
      });
    }
  },
  mounted: function mounted() {
    var self = this;
    this.getSeoPath(this.$router.currentRoute.path);
  },
  methods: {
    getNewVersions: function getNewVersions() {},
    getSeoPath: function getSeoPath(match) {
      var self = this;
      self.headMeta = [];
      self.headLink = [];
      self.$emit('updateHead');
      axios({
        method: 'post',
        url: '/seo/getOg',
        data: {
          match: match
        }
      }).then(function (response) {
        self.headTitle = response.data.obj.title;
        self.headMeta = response.data.obj.meta;
        self.headLink = response.data.obj.link;
        self.$emit('updateHead');
      });
    }
  },
  components: {
    App: App
  },
  template: '<App/>'
});