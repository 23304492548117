var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("v-img", {
            staticClass: "mr-3 mb-3",
            staticStyle: { width: "250px", float: "left" },
            attrs: { src: _vm.azienda.icona, contain: "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            { domProps: { innerHTML: _vm._s(_vm.azienda.content.frontend) } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.azienda.content.frontend) +
                  "\n        "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }