//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "userSidebar",
  props: ["ready"],
  data: function data() {
    return {
      drawer: true,
      items: [{
        separator: false,
        headline: true,
        title: 'Account'
      }, {
        title: this.$t_('FE_USER_SETTINGS'),
        icon: 'dashboard',
        separator: false,
        headline: false,
        url: '/backpack/user'
      }, {
        title: this.$t_('FE_USER_PAGINA_PERSONALE'),
        icon: 'question_answer',
        separator: false,
        headline: false,
        url: '/backpack/personal-page'
      }, {
        title: this.$t_('FE_USER_EMAIL_ADD'),
        icon: 'alternate_email',
        separator: false,
        headline: false,
        url: '/backpack/user-emails'
      }, {
        title: 'Password',
        icon: 'password',
        separator: false,
        headline: false,
        url: '/backpack/user',
        ev: 'change_password'
      }, {
        separator: true,
        headline: false
      }, {
        separator: false,
        headline: true,
        title: 'Backpack'
      }, {
        separator: false,
        headline: false,
        title: this.$t_('FE_USER_MYBADGES'),
        icon: 'image_search',
        url: '/backpack/badges'
      }, {
        separator: false,
        headline: false,
        title: this.$t_('FE_USER_ADD_BADGE'),
        icon: 'add',
        url: '/backpack/add-badges'
      }, {
        separator: false,
        headline: false,
        title: this.$t_('FE_USER_CLUSTER_TAGS'),
        icon: 'filter_drama',
        url: '/backpack/cluster'
      }, {
        separator: true,
        headline: false
      }, {
        separator: false,
        headline: true,
        title: 'ePortfolio'
      }, {
        title: this.$t_('FE_USER_PAGES_ADD_VIEW'),
        icon: 'pages',
        separator: false,
        headline: false,
        url: '/backpack/user-pages'
      }, {
        separator: true,
        headline: false
      }, {
        separator: false,
        headline: true,
        title: 'Log'
      }, {
        title: 'My Logs',
        icon: 'pageview',
        separator: false,
        headline: false,
        url: '/backpack/logs'
      }],
      mini: false,
      right: null,
      userInfo: {
        profile_image: '',
        first_name: '',
        last_name: '',
        config: {
          profile_image: ''
        }
      }
    };
  },
  mounted: function mounted() {
    if (this.$options.propsData.ready) {
      if (this.$root.profile !== undefined) {
        this.userInfo = this.$root.profile;
      } else {
        var self = this;
        axios.get('/user/get_Vueprofile').then(function (response) {
          self.userInfo = response.data.obj.all;
          self.$root.profile = self.userInfo;
        });
      }
    }
  },
  methods: {
    PushNavigation: function PushNavigation(item) {
      if (item.ev === undefined) item.ev = 'default';
      this.$emit('cliccked', item.ev);
      this.$root.options.can_show = item.ev;
      if (item.url !== undefined) this.$router.push(item.url);
    }
  }
};