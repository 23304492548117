var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        { staticClass: "pt-0 pb-1 text-center" },
        [
          _c(
            "v-card",
            { staticClass: "elevation-0 madi-nocolor" },
            [
              _c(
                "v-card-text",
                {
                  staticClass:
                    "display-2 text-center text-md-center font-weight-black madiLSpacing"
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t_(_vm.titolo)) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "text-md-center" },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "mb-4 mt-2",
                          attrs: {
                            sm6: "",
                            xs8: "",
                            "offset-sm3": "",
                            "offset-xs-2": "",
                            "mb-4": "",
                            "mt-2": ""
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t_(_vm.desc)) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { sm8: "", xs12: "", "offset-sm2": "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "madi-button-search elevation-0",
                            attrs: {
                              clearable: "",
                              label: "Search",
                              type: "text",
                              solo: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-fade-transition",
                                      { attrs: { "leave-absolute": "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "blue madiButtonSearch",
                                            attrs: {
                                              flat: "",
                                              icon: "",
                                              color: "white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.StartSearch()
                                              }
                                            }
                                          },
                                          [_c("v-icon", [_vm._v("search")])],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }