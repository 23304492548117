//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "SelectLanguage",
  props: ["lingue", "show"],
  data: function data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      currentLang: 'ITA',
      lingues: [],
      isVideoMode: true
    };
  },
  computed: {
    intersLang: {
      get: function get() {},
      set: function set() {}
    },
    listalingue: {
      get: function get() {
        var my = [];

        for (var i in this.$options.propsData) {
          if (this.$options.propsData[i] !== null) {
            my.push(this.$options.propsData[i]);
          }
        }

        return my;
      },
      set: function set() {}
    }
  },
  created: function created() {//console.log(this.$options.propsData);
  },
  mounted: function mounted() {
    if (this.$cookie.get('appLang') === undefined) {
      this.setThisLang('ITA');
    } else {
      this.currentLang = this.$cookie.get('appLang');
    }
  },
  methods: {
    getImage: function getImage(data) {},
    setThisLang: function setThisLang(lang) {
      this.currentLang = lang;
      this.menu = false;
      this.$cookie.set('appLang', lang);
      var self = this;
      var see = this.$convertNazione(this.$cookie.get('appLang'));

      if (this.$root.logged) {
        axios.get('/user/setNewLang').then(function (response) {
          axios.get('/t9n/' + see).then(function (response) {
            self.$localStorage.set('l', response.data);
            setTimeout(function () {
              window.location.reload();
            }, 1200);
          });
        });
      } else {
        if (see === undefined) {
          axios.get('/t9n').then(function (response) {
            self.$localStorage.set('l', response.data);
            window.location.reload();
          });
        } else {
          axios.get('/t9n/' + see).then(function (response) {
            self.$localStorage.set('l', response.data);
            window.location.reload();
          });
        }
      }
    }
  }
};