var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.lists.badge !== undefined
            ? _c(
                "v-card",
                { staticClass: "elevation-0 transparent mt-5 badges" },
                [
                  _c("v-card-title", { staticClass: "display-1" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t_("SUB_HEADER_BADGE")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "wrap-madi-divider" }),
                  _vm._v(" "),
                  _c("GlobalCards", {
                    attrs: {
                      callRemote: false,
                      remoteUrlCall: "",
                      subheaderShow: false,
                      subheaderObjectData: false,
                      dimensions: "md3 sm6 xs12",
                      baseLink: "/mob",
                      filterIncomponent: false,
                      inPages: true,
                      loopItem: _vm.lists.badge.cards,
                      tipologia: "badge"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.lists.orgs !== undefined
            ? _c(
                "v-card",
                { staticClass: "elevation-0 transparent mt-5 badges" },
                [
                  _c("v-card-title", { staticClass: "display-1" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t_("FE_ORGANIZZAZIONI_TITOLO_HEADER")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "wrap-madi-divider" }),
                  _vm._v(" "),
                  _c("GlobalCards", {
                    attrs: {
                      callRemote: false,
                      remoteUrlCall: "",
                      subheaderShow: false,
                      subheaderObjectData: false,
                      dimensions: "md3 sm6 xs12",
                      baseLink: "/mob",
                      loopItem: _vm.lists.orgs.cards,
                      inPages: true,
                      filterIncomponent: false,
                      tipologia: "orgs"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.lists.project !== undefined
            ? _c(
                "v-card",
                { staticClass: "elevation-0 transparent mt-5 badges" },
                [
                  _c("v-card-title", { staticClass: "display-1" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t_("SUB_HEADER_PROGETTI")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "wrap-madi-divider" }),
                  _vm._v(" "),
                  _c("GlobalCards", {
                    attrs: {
                      callRemote: false,
                      remoteUrlCall: "",
                      subheaderShow: false,
                      subheaderObjectData: false,
                      dimensions: "md3 sm6 xs12",
                      baseLink: "/mob",
                      inPages: true,
                      filterIncomponent: false,
                      loopItem: _vm.lists.project.cards,
                      tipologia: "project"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.lists.tags !== undefined
            ? _c(
                "v-card",
                { staticClass: "elevation-0 transparent mt-5 badges" },
                [
                  _c("v-card-title", { staticClass: "display-1" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t_("SUB_HEADER_TAG_VIEW_SEARCH")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "wrap-madi-divider" }),
                  _vm._v(" "),
                  _c("GlobalCards", {
                    attrs: {
                      callRemote: false,
                      remoteUrlCall: "",
                      subheaderShow: false,
                      subheaderObjectData: false,
                      dimensions: "md3 sm6 xs12",
                      baseLink: "/mob",
                      inPages: true,
                      filterIncomponent: false,
                      loopItem: _vm.lists.tags.cards,
                      tipologia: "badge"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.showProgressBar
            ? _c("v-progress-linear", { attrs: { indeterminate: true } })
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }