var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("userSidebar", {
            attrs: { ready: _vm.isVideoMode },
            on: { cliccked: _vm.evInitial }
          }),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.can_show === "default"
                ? [
                    _c(
                      "v-flex",
                      { attrs: { md8: "", sm12: "", xs12: "" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "elevation-0 ml-2 mr-2" },
                          [
                            _c(
                              "v-toolbar",
                              {
                                attrs: {
                                  color: "transparent",
                                  flat: "",
                                  dense: "",
                                  card: ""
                                }
                              },
                              [
                                _c(
                                  "v-toolbar-title",
                                  { staticClass: "title ft-200" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t_(
                                          "FE_FORM_DATI_UTENTI_PRINCIPALI"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "form",
                                    model: {
                                      value: _vm.valid,
                                      callback: function($$v) {
                                        _vm.valid = $$v
                                      },
                                      expression: "valid"
                                    }
                                  },
                                  [
                                    _c("v-form-base", {
                                      attrs: {
                                        value: _vm.value,
                                        schema: _vm.schema
                                      },
                                      on: { update: _vm.update }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { md4: "", sm12: "", xs12: "" } },
                      [
                        _c("images", {
                          attrs: { immagine: _vm.immagine.image }
                        }),
                        _vm._v(" "),
                        _c(
                          "v-card",
                          { staticClass: "elevation-0 transparent pt-4" },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { block: "", color: "blue" },
                                    on: {
                                      click: function($event) {
                                        return _vm.SendData()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_FORM_DATI_BTN_UPDATE_PROFILO"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.can_show === "change_password"
                ? [
                    _c(
                      "v-flex",
                      { attrs: { md8: "", sm12: "", xs12: "" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "elevation-0 ml-2 mr-2" },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-form",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "prepend-icon": "person",
                                        name: "current",
                                        label: "Current Password",
                                        type: "password"
                                      },
                                      model: {
                                        value: _vm.currentPassword,
                                        callback: function($$v) {
                                          _vm.currentPassword = $$v
                                        },
                                        expression: "currentPassword"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        "prepend-icon": "person",
                                        name: "new",
                                        label: "New Password",
                                        type: "password"
                                      },
                                      model: {
                                        value: _vm.newPassword,
                                        callback: function($$v) {
                                          _vm.newPassword = $$v
                                        },
                                        expression: "newPassword"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        "prepend-icon": "person",
                                        name: "identiry",
                                        label: "Repet password",
                                        type: "password"
                                      },
                                      model: {
                                        value: _vm.confirmNewPassword,
                                        callback: function($$v) {
                                          _vm.confirmNewPassword = $$v
                                        },
                                        expression: "confirmNewPassword"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { block: "", color: "blue" },
                                    on: { click: _vm.resetPasswordData }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t_(
                                            "PASSWORD_CONFERMA_CAMBIO_PASSWORD"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("ProgressBar", { attrs: { dialog: _vm.showLoader } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }