function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
} //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PagineUtenteBadgeContent from '../components/PagineUtenteBadgeContent';
import VueWordCloud from 'vuewordcloud';
export default {
  name: "PagineUtente",
  props: ['pagina'],
  components: _defineProperty({
    PagineUtenteBadgeContent: PagineUtenteBadgeContent
  }, VueWordCloud.name, VueWordCloud),
  data: function data() {
    return {
      isVideoMode: false,
      paginaUtente: {},
      user: {},
      showFormPassword: false,
      cPassword: '',
      errorCount: 0,
      progress: undefined,
      progressVisible: true,
      animation: [{
        text: 'bounce',
        value: ['bounceIn', 'bounceOut']
      }]
    };
  },
  computed: {
    color: function color() {
      var colors = ['#ffd077', '#3bc4c7', '#3a9eea', '#ff4e69', '#461e47'];
      return function () {
        return chance.pickone(colors);
      };
    },
    enterAnimation: function enterAnimation() {
      return ['animated', this.animation[0]].join(' ');
    },
    leaveAnimation: function leaveAnimation() {
      return ['animated', this.animation[1]].join(' ');
    },
    animationOverlap: function animationOverlap() {
      return 1 / 2;
    },
    animationEasing: function animationEasing() {
      return 'ease-in';
    }
  },
  watch: {
    progress: {
      handler: function handler() {
        this.setStyleC();
      }
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    setStyleC: function setStyleC() {
      setTimeout(function () {
        var items = document.getElementsByClassName('elemImportant');
        var i = 0;

        while (i < items.length) {
          document.getElementsByClassName('elemImportant')[i].setAttribute('style', 'color:' + items[i].parentElement.style.color + ' !important;font-size:' + items[i].parentElement.style.fontSize + ' !important');
          i++;
        }
      }, 1250);
    },
    getData: function getData() {
      var self = this;
      this.errorCount = 0;
      axios.get('/user/getMyPageRequest/' + this.$options.propsData.pagina).then(function (response) {
        if (response.data.obj.redirect) {
          self.$router.push('/badge/show');
        } else {
          self.paginaUtente = response.data.obj.pagina;
          self.user = response.data.obj.user;
          self.controllaPaginaView();
        }
      });
    },
    controllaPaginaView: function controllaPaginaView() {
      if (this.paginaUtente.property.access === 'Password protected') {
        this.showFormPassword = true;
      } else {
        this.isVideoMode = true;
      }
    },
    matchPassword: function matchPassword() {
      var sha1 = require('sha1');

      if (this.paginaUtente.property.access_password === sha1(this.cPassword)) {
        this.showFormPassword = false;
        this.isVideoMode = true;
      } else {
        this.errorCount += 1;

        if (this.errorCount >= 6) {
          this.$router.push('/badge/show');
        }
      }
    },
    NavigateTo: function NavigateTo(url) {
      window.open(url, '_blank');
    },
    getStyle: function getStyle() {
      if (this.isVideoMode) return 'background-image: url(' + this.paginaUtente.object_to_compile.sfondo + ');background-repeat: repeat;';
    },
    getUrlFile: function getUrlFile(item) {
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

      if (!regex.test(item)) {
        return '/' + item;
      } else {
        return item;
      }
    },
    getTitleBadge: function getTitleBadge(badge) {
      if (badge.is_mob_object === '1') {
        if (badge.compose.content[this.pagina.lang] !== undefined) {
          return badge.compose.content[this.pagina.lang].name;
        } else {
          var keys = Object.keys(badge.compose.content);
          return badge.compose.content[keys[0]].name;
        }
      } else {
        return '';
      }
    },
    getMinDesc: function getMinDesc(badge) {
      if (badge.is_mob_object === '1') {
        if (badge.compose.content[this.pagina.lang] !== undefined) {
          return badge.compose.content[this.pagina.lang].min_desc;
        } else {
          var keys = Object.keys(badge.compose.content);
          return badge.compose.content[keys[0]].min_desc;
        }
      } else {
        return '';
      } //return 'min desc';

    },
    getFullDesc: function getFullDesc(badge) {
      if (badge.is_mob_object === '1') {
        if (badge.compose.content[this.pagina.lang] !== undefined) {
          return badge.compose.content[this.pagina.lang].description;
        } else {
          var keys = Object.keys(badge.compose.content);
          return badge.compose.content[keys[0]].description;
        }
      } else {
        return '';
      }
    },
    resolvObjectFromAssertion: function resolvObjectFromAssertion(url) {},

    /*****WCLOUD*/
    rotationWCloud: function rotationWCloud(word) {
      var chance = new Chance(word[0]);
      return chance.pickone([0, 3 / 4]);
    }
  }
};