//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import vuefydatatable from '../../../app/components/vuefy-datatable';
export default {
  name: "LogsUtente",
  components: {
    vuefydatatable: vuefydatatable //dataTables:dataTables

  },
  data: function data() {
    return {
      items: [],
      url: '/mob/getMyLog'
    };
  },
  mounted: function mounted() {},
  methods: {
    getData: function getData() {
      var self = this;
      axios.get('/mob/getMyLog').then(function (response) {});
    },
    delAll: function delAll() {
      var self = this;
      axios.get('/mob/delAll').then(function (response) {
        self.$router.push('/backpack/user');
      });
    }
  }
};