var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: _vm.getHeaderClass(_vm.immagine),
      attrs: { color: _vm.immagine.class }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: _vm.immagine.color, dark: "" } },
        [_c("v-toolbar-title", [_vm._v(_vm._s(_vm.immagine.name) + " ")])],
        1
      ),
      _vm._v(" "),
      _c("v-img", {
        staticClass: "white--text",
        attrs: {
          id: _vm.immagine.id,
          src: _vm.immagine.lurl,
          height: "200px",
          contain: ""
        },
        model: {
          value: _vm.immagine.url,
          callback: function($$v) {
            _vm.$set(_vm.immagine, "url", $$v)
          },
          expression: "immagine.url"
        }
      }),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-layout",
            { attrs: { row: "", center: "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    "hide-overlay": "",
                    transition: "dialog-bottom-transition"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _vm.immagine.fire_action
                            ? _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      absolute: "",
                                      dark: "",
                                      fab: "",
                                      bottom: "",
                                      left: "",
                                      color: "pink"
                                    }
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("edit")])],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dialog,
                    callback: function($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-toolbar-title", [
                            _vm._v(
                              "\n                                Edit " +
                                _vm._s(_vm.immagine.name) +
                                "\n                            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar-items",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { dark: "", flat: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.salvaImmagineContainer(
                                        _vm.immagine
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Save")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          !_vm.isHidden(_vm.immagine)
                            ? _c(
                                "v-flex",
                                { staticClass: "pa-5", attrs: { xs6: "" } },
                                [
                                  _c("div", { staticClass: "display-2" }, [
                                    _vm._v(_vm._s(_vm.immagine.name))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.immagine.descrizione
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.immagine.descrizione) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              staticClass: "pa-5 ",
                              on: {
                                "v-bind": function($event) {
                                  return _vm.getClass(_vm.immagine)
                                }
                              }
                            },
                            [
                              _vm.isHidden(_vm.immagine)
                                ? _c("div", { staticClass: "display-2" }, [
                                    _vm._v(_vm._s(_vm.immagine.name))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isHidden(_vm.immagine)
                                ? _c(
                                    "v-card-text",
                                    {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.immagine.descrizione
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.immagine.descrizione) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-image-input", {
                                attrs: {
                                  "image-quality": _vm.immagine.imageQuality,
                                  format: _vm.immagine.imageFormat,
                                  fullHeight: _vm.immagine.fullHeight,
                                  fullWidth: _vm.immagine.fullWidth,
                                  minScaling: _vm.immagine.minScaling,
                                  clearable: _vm.immagine.clearable,
                                  maxScaling: _vm.immagine.maxScaling,
                                  imageHeight: _vm.immagine.imageHeight,
                                  imageWidth: _vm.immagine.imageWidth,
                                  overlayPadding: _vm.immagine.overlayPadding
                                },
                                model: {
                                  value: _vm.immagine.url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.immagine, "url", $$v)
                                  },
                                  expression: "immagine.url"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }