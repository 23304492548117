var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { app: "", right: "" },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _vm._l(_vm.items, function(item) {
                    return [
                      item.heading
                        ? _c(
                            "v-layout",
                            {
                              key: item.heading,
                              attrs: { row: "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  item.heading
                                    ? _c("v-subheader", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(item.heading) +
                                            "\n\t\t\t\t\t\t\t"
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-center",
                                  attrs: { xs6: "" }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "body-2 black--text",
                                      attrs: { href: "#!" }
                                    },
                                    [_vm._v("EDIT")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : item.children
                        ? _c(
                            "v-list-group",
                            {
                              key: item.text,
                              attrs: {
                                "prepend-icon": item.model
                                  ? item.icon
                                  : item["icon-alt"],
                                "append-icon": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-list-tile",
                                          [
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c("v-list-tile-title", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(item.text) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.model,
                                callback: function($$v) {
                                  _vm.$set(item, "model", $$v)
                                },
                                expression: "item.model"
                              }
                            },
                            [
                              _vm._v(" "),
                              _vm._l(item.children, function(child, i) {
                                return _c(
                                  "v-list-tile",
                                  {
                                    key: i,
                                    on: {
                                      click: function($event) {
                                        return _vm.nv(child.url)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(child.text) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        : _c(
                            "v-list-tile",
                            {
                              key: item.text,
                              on: {
                                click: function($event) {
                                  return _vm.nv(item.url)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#000" } },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(item.text) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.loaded
                ? [
                    _vm.$root.options.show_login !== false
                      ? [
                          !_vm.$root.logged
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { flat: "", color: "indigo darken-3" },
                                  on: {
                                    click: function($event) {
                                      return _vm.nv("/login")
                                    }
                                  }
                                },
                                [_vm._v("\n\t\t\t\t\t\tLogin\n\t\t\t\t\t")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: { flat: "", color: "indigo darken-3" },
                                  on: {
                                    click: function($event) {
                                      return _vm.nv("/logout")
                                    }
                                  }
                                },
                                [_vm._v("\n\t\t\t\t\t\tLogout\n\t\t\t\t\t")]
                              ),
                          _vm._v(" "),
                          _c("v-divider")
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$root.options.show_langs !== false
                      ? [
                          _vm.view_lang
                            ? _c("SelectLanguage", {
                                attrs: {
                                  lingue: _vm.lingue_ui,
                                  show: _vm.view_lang
                                }
                              })
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-toolbar",
        { attrs: { color: "white elevation-0" } },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "ml-0 pl-3", staticStyle: { width: "200px" } },
            [
              _c(
                "v-btn",
                { staticClass: "elevation-0 madiNoDef", attrs: { large: "" } },
                [
                  _c("img", {
                    staticStyle: { "max-height": "25px" },
                    attrs: {
                      src:
                        "https://app.myopenbadge.com/public/assets/custom-mob/logo-mob_q.png",
                      alt: "My Open Badge"
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            { staticClass: "hidden-sm-and-down" },
            [
              _vm._l(_vm.getItems, function(item) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none font-weight-regular subheading",
                      style: item.style,
                      attrs: { flat: "", color: "blue darken-2" },
                      on: {
                        click: function() {
                          _vm.Naviga(item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" + _vm._s(item.text) + "\n\t\t\t\t\t"
                      )
                    ]
                  )
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.loaded
            ? [
                _c(
                  "v-toolbar-items",
                  { staticClass: "hidden-sm-and-down" },
                  [
                    _vm.$root.options.show_langs !== false
                      ? [
                          _vm.view_lang
                            ? _c("SelectLanguage", {
                                attrs: {
                                  lingue: _vm.lingue_ui,
                                  show: _vm.view_lang
                                }
                              })
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$root.options.show_login !== false
                      ? [
                          !_vm.$root.logged
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { flat: "", color: "indigo darken-3" },
                                  on: {
                                    click: function($event) {
                                      return _vm.nv("/login")
                                    }
                                  }
                                },
                                [_vm._v("\n\t\t\t\t\t\t\tLogin\n\t\t\t\t\t\t")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: { flat: "", color: "indigo darken-3" },
                                  on: {
                                    click: function($event) {
                                      return _vm.nv("/logout")
                                    }
                                  }
                                },
                                [_vm._v("\n\t\t\t\t\t\t\tLogout\n\t\t\t\t\t\t")]
                              )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c("v-toolbar-side-icon", {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  }
                }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            {
              staticStyle: { "max-width": "1920px", margin: "auto" },
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-layout",
                [
                  _c("router-view", {
                    key: _vm.$route.path,
                    on: { heyupdate: _vm.checkEvent }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footers")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }