//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "footers",
  computed: {
    date: {
      get: function get() {}
    }
  },
  data: function data() {
    return {
      showFooter: true,
      images: [{
        image: '/public/uploads/system/openbadges_pennant.svg',
        link: 'https://openbadges.org/',
        width: '60px'
      }, {
        image: '/public/uploads/system/cropped-ORA-logo.png',
        link: 'https://www.openrecognition.org/bord/',
        width: '75px'
      },
      /*{
      		image:'https://myopenbadge.com/wp-content/uploads/2019/06/OpenBadgesCertified.png',
      		link:'https://site.imsglobal.org/certifications/reiss-romoli-srl/170526/my+open+badge#cert_pane_nid_177951',
      		width:'75px'
      },*/
      {
        image: 'https://myopenbadge.com/wp-content/uploads/2022/11/1EdTech_Certified.png',
        link: 'https://site.imsglobal.org/certifications/reiss-romoli-srl/my-open-badge#cert_pane_nid_177951',
        width: '100px'
      }]
    };
  },
  methods: {
    naviga: function naviga(url) {
      window.open(url, '_blank');
    }
  }
};