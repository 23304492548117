var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        [
          _vm.showSubBar
            ? _c("NavigationInCards", {
                attrs: {
                  items: _vm.SubHeader,
                  image: _vm.progetto.image_logo,
                  titolo: _vm.progetto.project_name
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-layout",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: _vm.onScroll,
                  expression: "onScroll"
                }
              ],
              attrs: { row: "", wrap: "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 white progetto" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-img", {
                            attrs: { src: _vm.progetto.image, contain: "" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-card-title", { staticClass: "title" }, [
                        _c("h2", { staticClass: "display-2" }, [
                          _vm._v(_vm._s(_vm.progetto.project_name))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { domProps: { innerHTML: _vm._s(_vm.getProject()) } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getProject()) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.progetto.attachment.length > 0
                        ? _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-list",
                                _vm._l(_vm.progetto.attachment, function(
                                  attachment,
                                  indexAtt
                                ) {
                                  return _c(
                                    "v-list-tile",
                                    {
                                      key: indexAtt,
                                      attrs: { avatar: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.forceDownload(
                                            attachment.guid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-tile-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "blue lighten-1 white--text"
                                            },
                                            [_vm._v("attach_file")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(_vm._s(attachment.file_name))
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.sections, function(item, index) {
                    return _c(
                      "v-card",
                      {
                        key: index,
                        class:
                          item.class + " " + index + " elevation-0  mt-5 pa-4"
                      },
                      [
                        _c("v-card-title", [
                          _c("h2", { staticClass: "headline mb-3" }, [
                            _vm._v(_vm._s(item.header))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("v-divider", { staticClass: "wrap-madi-divider" }),
                        _vm._v(" "),
                        _c(
                          "v-card-text",
                          { domProps: { innerHTML: _vm._s(item.content) } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.content) +
                                "\n                    "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.progetto.endorsementObj.length > 0
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "elevation-0 transparent mt-5 endorsement"
                        },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t_("FE_LABEL_ENDORSEMENT_CARDS")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c("GlobalCards", {
                            attrs: {
                              callRemote: false,
                              remoteUrlCall: "",
                              subheaderShow: false,
                              subheaderObjectData: false,
                              dimensions: "md4 sm6 xs12",
                              baseLink: "/mob",
                              loopItem: _vm.progetto.endorsementObj,
                              tipologia: "orgs"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.progetto.remoteBadge
                    ? _c(
                        "v-card",
                        { staticClass: "elevation-0 transparent mt-5 badge" },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t_("SUB_HEADER_BADGE")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c("GlobalCards", {
                            attrs: {
                              callRemote: true,
                              remoteUrlCall: _vm.getCallRemoteBadge(),
                              subheaderShow: false,
                              subheaderObjectData: false,
                              dimensions: "md4 sm6 xs12",
                              baseLink: "/mob",
                              loopItem: _vm.progetto.badge,
                              tipologia: "badge"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }