var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("userSidebar", { attrs: { ready: true } }),
      _vm._v(" "),
      !_vm.all.errore
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "transparent elevation-0" },
                    [
                      _c("v-card-title", [
                        _c("h2", { staticClass: "display-2" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t_(
                                  "FE_UTENTE_BADGE_ADD_NEW_BADGE_FORM_SEND_DATA"
                                )
                              ) +
                              "\n                    "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "wrap-madi-divider" }),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md4: "", sm12: "", xs12: "" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "title blue--text pt-3" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t_(
                                              "FE_UTENTE_BADGE_ADD_NEW_BADGE_FORM_ASSERTION"
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md8: "", sm12: "", xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass:
                                      "elevation-0 madi-validator-inputs",
                                    attrs: {
                                      label: _vm.$t_(
                                        "FE_VALIDATOR_PLACEHOLDER_OBJECT"
                                      ),
                                      solo: ""
                                    },
                                    model: {
                                      value: _vm.object,
                                      callback: function($$v) {
                                        _vm.object = $$v
                                      },
                                      expression: "object"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "pt-2", attrs: { xs5: "" } },
                                [_c("v-divider", {})],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { xs2: "" } }, [
                                _c(
                                  "div",
                                  { staticClass: "title text-xs-center" },
                                  [_vm._v("or")]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { staticClass: "pt-2", attrs: { xs5: "" } },
                                [_c("v-divider", {})],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md12: "", sm12: "", xs12: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title blue--text pt-3 mb-3"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t_("FE_VALIDATOR_IMAGE_UPLOAD")
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("upload-btn", {
                                    staticClass: "left",
                                    attrs: { color: "blue" },
                                    on: { "file-update": _vm.update }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pt-3",
                                      model: {
                                        value: _vm.fileName,
                                        callback: function($$v) {
                                          _vm.fileName = $$v
                                        },
                                        expression: "fileName"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.fileName))]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("div", { staticClass: "mt-4" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t_(
                                          "FE_UTENTE_BADGE_ADD_NEW_BADgE_FORM_IMAGE_BADGE"
                                        )
                                      ) +
                                      "\n                            "
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "text-xs-right" },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "right", attrs: { xs12: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "blue", absolute: "", right: "" },
                              on: { click: _vm.sendData }
                            },
                            [
                              _vm._v(
                                "\n                        Carica\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.all.errore
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 ma-2 verify" },
                    [
                      _c("v-toolbar", { staticClass: "error" }, [
                        _c("div", { staticClass: "headline" }, [
                          _vm.all.report.valid
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t_("FE_VALIDATOR_VALIDO")) +
                                    "\n                                "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t_("FE_VALIDATOR_NONVALIDO")) +
                                    "\n                                "
                                )
                              ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.all.report.errorCount > 0 ||
                      _vm.all.report.warningCount > 0
                        ? _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            _vm._l(_vm.all.report.messages, function(
                              item,
                              index
                            ) {
                              return _c(
                                "v-flex",
                                {
                                  key: index,
                                  staticClass: "pa-2",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        value: true,
                                        color: _vm.getAlertType(item).color,
                                        icon: _vm.getAlertType(item).icon,
                                        outline: ""
                                      }
                                    },
                                    _vm._l(item, function(valore, chiave) {
                                      return _c(
                                        "div",
                                        { key: index + " " + chiave },
                                        [
                                          _c("b", [_vm._v(_vm._s(chiave))]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(valore) +
                                              "\n                            "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("div", { staticClass: "subheading pa-2 mb-2" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t_("FE_VALIDATOR_STATUS_DETAILS"))
                                )
                              ]),
                              _vm._v(":\n                            "),
                              _vm.all.report.valid
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.$t_("FE_VALIDATOR_VALIDO")) +
                                        "\n                                        "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t_("FE_VALIDATOR_NONVALIDO")
                                        ) +
                                        "\n                                        "
                                    )
                                  ]),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t_("FE_VALIDATOR_STATUS_CON_ERRORI")
                                  ) +
                                  " " +
                                  _vm._s(_vm.all.report.errorCount) +
                                  " " +
                                  _vm._s(
                                    _vm.$t_("FE_VALIDATOR_STATUS_CON_WARNING")
                                  ) +
                                  " " +
                                  _vm._s(_vm.all.report.warningCount) +
                                  "\n\n\n                        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "subheading pa-2 mb-2" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t_("FE_VALIDATOR_TIPO_VERIFICA"))
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.all.report.validationSubject))
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 ma-2 grey lighten-4 raw_data" },
                    [
                      _c("v-card-title", { staticClass: "display-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t_("FE_VALIDATOR_RAW_DATA_VIEW")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "wrap-madi-divider" }),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("json-viewer", {
                            attrs: { value: _vm.all, "expand-depth": 5 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }