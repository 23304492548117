//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GlobalCards from '../components/GlobalCards';
export default {
  name: "Progetto",
  props: ["myurl"],
  components: {
    "GlobalCards": GlobalCards
  },
  data: function data() {
    return {
      progetto: {},
      isVideoMode: false,
      currentClass: 'grey',
      showSubBar: false,
      SubHeader: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getData();
  },
  computed: {
    sections: {
      get: function get() {
        var my = [];
        var j = 0;

        for (var i in this.progetto.project_description.type) {
          if (this.progetto.project_description.type[i] === 'other') {
            var local = {
              header: this.progetto.project_description.label_name[i],
              content: this.progetto.project_description.description[i]
            };
            if (j === 0 || j % 2 === 0) local["class"] = 'grey lighten-4';else local["class"] = "white";
            my.push(local);
            j += 1;
          }
        }

        return my;
      },
      set: function set() {}
    },
    style: {
      get: function get() {
        return this.getStyle();
      },
      set: function set() {}
    }
  },
  methods: {
    forceDownload: function forceDownload(guid) {
      window.open('/utils/download/' + guid, '_blank');
    },
    onScroll: function onScroll(e) {
      // console.log(e.target.scrollTop)
      this.myScroll = window.scrollY;

      if (this.myScroll >= 100) {
        this.showSubBar = true;
      } else {
        this.showSubBar = false;
      }
    },
    getStyle: function getStyle() {
      if (this.currentClass === 'white') {
        console.log('Sono grey');
        this.currentClass = 'grey lighten-4';
      } else {
        console.log('Sono white');
        this.currentClass = 'white';
      }

      return this.currentClass;
    },
    getData: function getData() {
      var self = this;
      axios.get(this.$options.propsData.myurl).then(function (response) {
        self.progetto = response.data.obj;
        self.setSubHeader();
        self.isVideoMode = true;
      });
    },
    setSubHeader: function setSubHeader() {
      var my = [{
        name: this.$t_('SUB_HEADER_PROGETTO'),
        section: 'progetto'
      }];
      var j = 0;

      for (var i in this.progetto.project_description.type) {
        if (this.progetto.project_description.type[i] === 'other') {
          my.push({
            name: this.progetto.project_description.label_name[i],
            section: j
          });
          j = j + 1;
        }
      }

      if (this.progetto.endorsementObj.length > 0) my.push({
        name: this.$t_('FE_LABEL_ENDORSEMENT_CARDS'),
        section: 'endorsement'
      });
      if (this.progetto.remoteBadge) my.push({
        name: this.$t_('SUB_HEADER_BADGE'),
        section: 'badge'
      });
      this.SubHeader = my;
    },
    getHeader: function getHeader(el) {
      if (this.progetto.project_description.type[el] === 'project') return '';else if (this.progetto.project_description.type[el] === 'badge') return 'Badge';else return this.progetto.project_description.label_name[el];
    },
    getProject: function getProject() {
      for (var i in this.progetto.project_description.type) {
        if (this.progetto.project_description.type[i] === 'project') return this.progetto.project_description.description[i];
      }
    },
    canWrite: function canWrite(el) {
      if (this.progetto.project_description.type[el] !== 'badge') return true;else return false;
    },
    getCallRemoteBadge: function getCallRemoteBadge() {
      return '/mob/getBadgeProject/' + this.progetto.guid;
    }
  }
};