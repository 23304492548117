var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "blue-grey lighten-1", attrs: { fixed: true, app: "" } },
    [
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-toolbar-items",
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "v-btn",
            {
              key: index,
              staticClass: "white--text",
              attrs: { flat: "" },
              on: {
                click: function($event) {
                  return _vm.gotoSection(item.section)
                }
              }
            },
            [_vm._v("\n            " + _vm._s(item.name) + "\n        ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }