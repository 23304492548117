//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "CardsGeneral",
  props: ["myurl", "videoMode"],
  data: function data() {
    return {
      cards: [],
      request: '',
      rotta: '',
      pages: {},
      isVideoMode: false
    };
  },
  created: function created() {
    this.request = this.$options.propsData.myurl;
    this.getData();
  },
  computed: {
    getPage: {
      get: function get() {
        //this function will determine what is displayed in the input
        return parseInt(this.pages.page);
      },
      set: function set(newVal) {
        //this function will run whenever the input changes
        this.pages.page = parseInt(newVal); //this.getDataPage();
        //return parseInt(this.pages.page);

        this.$router.push(this.rotta + this.pages.page);
      }
    }
  },
  methods: {
    getData: function getData() {
      var self = this;
      axios.get(this.request).then(function (response) {
        self.cards = response.data.obj.cards;
        self.pages = response.data.obj.pages;
        self.rotta = response.data.obj.rotta;
        self.isVideoMode = true;
      });
    },
    getDataPage: function getDataPage() {
      var self = this;
      axios.get(this.request + (this.pages.page - 1)).then(function (response) {
        self.cards = response.data.obj.cards; //self.pages=response.data.obj.pages;
        //self.isVideoMode=true;
      });
    },
    getDescription: function getDescription(item) {
      var my = this.$clearAllTag(item);
      return this.$createSubString(my, 0, 155) + '...';
    },
    number: function number(item) {
      return parseInt(item);
    },
    NavigaPush: function NavigaPush(url) {
      this.$router.push(url);
    }
  }
};