var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _vm.isVideoMode
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _vm.tipologia === "login"
                    ? [
                        _c(
                          "v-flex",
                          {
                            staticClass: "pa-5",
                            attrs: { xs12: "", sm12: "", md6: "", lg6: "" }
                          },
                          [
                            _vm.showMessageError
                              ? _c(
                                  "v-alert",
                                  {
                                    attrs: {
                                      value: true,
                                      color: "error",
                                      icon: "warning",
                                      outline: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        domProps: {
                                          innerHTML: _vm._s(_vm.errorMessage)
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.errorMessage))]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-card",
                              { staticClass: "elevation-12" },
                              [
                                _c(
                                  "v-toolbar",
                                  { attrs: { dark: "", color: "primary" } },
                                  [
                                    _c("v-toolbar-title", [
                                      _c("h2", { staticClass: "white--text" }, [
                                        _vm._v("Login")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("v-spacer")
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-form",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "prepend-icon": "person",
                                            name: "identiry",
                                            label: _vm.$t_(
                                              "FE_LABEL_LOGIN_EMAIL"
                                            ),
                                            type: "email"
                                          },
                                          model: {
                                            value: _vm.identiry,
                                            callback: function($$v) {
                                              _vm.identiry = $$v
                                            },
                                            expression: "identiry"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("v-text-field", {
                                          attrs: {
                                            id: "password",
                                            "prepend-icon": "lock",
                                            name: "password",
                                            label: _vm.$t_(
                                              "FE_LABEL_LOGIN_PASSWORD"
                                            ),
                                            type: "password"
                                          },
                                          model: {
                                            value: _vm.password,
                                            callback: function($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-card-text",
                                  { attrs: { "text-xs-center": "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", block: "" },
                                        on: { click: _vm.sendDataLogin }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t_("FE_LOG_USER_FORMS"))
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-list",
                                      { attrs: { "two-line": "" } },
                                      [
                                        _c(
                                          "v-list-tile",
                                          {
                                            staticClass: "blue-grey lighten-3",
                                            on: {
                                              click: function($event) {
                                                _vm.tipologia = "register"
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-tile-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("how_to_reg")]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c("v-list-tile-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t_(
                                                        "LABEL_REGISTRATI_UTENTE_IN_LOGIN"
                                                      )
                                                    )
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("v-list-tile-sub-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t_(
                                                        "FE_LABEL_LOGIN_TEXT_REGISTRATI_TEXT"
                                                      )
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-tile",
                                          {
                                            staticClass: "blue-grey lighten-3",
                                            on: {
                                              click: function($event) {
                                                _vm.tipologia = "resetpassword"
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-tile-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("compare_arrows")]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c("v-list-tile-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t_(
                                                        "LABEL_UTENTE_FORGOTTEN_PASSWORD"
                                                      )
                                                    )
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("v-list-tile-sub-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t_(
                                                        "FE_LABEL_LOGIN_TEXT_RESET_TEXT"
                                                      )
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { staticClass: "pa-5", attrs: { xs12: "", sm6: "" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "mx-auto",
                                attrs: { color: "#FFF", dark: "" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-no-wrap justify-space-between"
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "headline" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_LOG_TITLE_USER_IDEM"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                contain: "",
                                                src:
                                                  "/public/images/idemEduGain.png"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ml-2 mt-5",
                                                attrs: {
                                                  outlined: "",
                                                  rounded: "",
                                                  block: "",
                                                  color: "green"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.loginIdem = !_vm.loginIdem
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_LOG_USER_FORMS_IDEM"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm.tipologia === "register"
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", sm8: "", md6: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-12" },
                            [
                              _c(
                                "v-toolbar",
                                { attrs: { dark: "", color: "primary" } },
                                [
                                  _c("v-toolbar-title", [
                                    _c("h2", { staticClass: "white--text" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t_("FE_REG_USER_FORMS"))
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("v-spacer")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid"
                                      }
                                    },
                                    [
                                      _c("v-form-base", {
                                        attrs: {
                                          value: _vm.data.values,
                                          schema: _vm.data.schema
                                        },
                                        on: { update: _vm.update }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  on: { click: _vm.openTerms }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "LABEL_TERMINI_E_CONDIZIONI"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1294407742
                                    ),
                                    model: {
                                      value: _vm.accetta_termini,
                                      callback: function($$v) {
                                        _vm.accetta_termini = $$v
                                      },
                                      expression: "accetta_termini"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "blue--text",
                                      attrs: { color: "transparent" },
                                      on: {
                                        click: function($event) {
                                          _vm.tipologia = "login"
                                        }
                                      }
                                    },
                                    [_vm._v("Login")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.sendDataRegister }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t_(
                                            "LABEL_REGISTRATI_UTENTE_IN_LOGIN"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.tipologia === "resetpassword"
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", sm8: "", md6: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-12" },
                            [
                              _c(
                                "v-toolbar",
                                { attrs: { dark: "", color: "primary" } },
                                [
                                  _c("v-toolbar-title", [
                                    _c("h2", { staticClass: "white--text" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t_("FE_FPS_USER_FORMS"))
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("v-spacer")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-form",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "person",
                                          name: "identiry",
                                          label: _vm.$t_(
                                            "FE_LABEL_LOGIN_EMAIL"
                                          ),
                                          type: "email"
                                        },
                                        model: {
                                          value: _vm.emailRescuePassword,
                                          callback: function($$v) {
                                            _vm.emailRescuePassword = $$v
                                          },
                                          expression: "emailRescuePassword"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "blue" },
                                      on: {
                                        click:
                                          _vm.InviaCodiceUnivocoResetPassword
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t_(
                                              "PASSWORD_RESET_INVIO_EMAIL"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.tipologia === "mfa"
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md12: "" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto",
                              staticStyle: { "max-width": "550px" }
                            },
                            [
                              _c(
                                "v-toolbar",
                                { attrs: { dark: "", color: "primary" } },
                                [
                                  _c("v-toolbar-title", [
                                    _c("h2", { staticClass: "white--text" }, [
                                      _vm._v("Multi Factor Authenticator")
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("v-spacer")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "align-center": "",
                                        "justify-center": "",
                                        row: "",
                                        "fill-height": ""
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        ref: "elements_next",
                                        staticClass:
                                          "ma-1 title custom-elem-input",
                                        staticStyle: {
                                          "text-align": "center",
                                          "font-size": "large"
                                        },
                                        attrs: {
                                          solo: "",
                                          mask: "# - # - # - # - #",
                                          type: "text",
                                          "max-length": "5"
                                        },
                                        model: {
                                          value: _vm.curin,
                                          callback: function($$v) {
                                            _vm.curin = $$v
                                          },
                                          expression: "curin"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "blue" },
                                      on: { click: _vm.checktwofactorauth }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\tSend Code\n\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "orange" },
                                      on: { click: _vm.resendMFA }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\tResend Code\n\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-flex",
                        { attrs: { xs12: "", sm8: "", md6: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-12" },
                            [
                              _c(
                                "v-toolbar",
                                { attrs: { dark: "", color: "primary" } },
                                [
                                  _c("v-toolbar-title", [
                                    _c("h2", { staticClass: "white--text" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t_("FE_FPS_USER_FORMS"))
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("v-spacer")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-form",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "person",
                                          name: "new",
                                          label: "New Password",
                                          type: "password"
                                        },
                                        model: {
                                          value: _vm.newPassword,
                                          callback: function($$v) {
                                            _vm.newPassword = $$v
                                          },
                                          expression: "newPassword"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "person",
                                          name: "identiry",
                                          label: "Repet password",
                                          type: "password"
                                        },
                                        model: {
                                          value: _vm.confirmNewPassword,
                                          callback: function($$v) {
                                            _vm.confirmNewPassword = $$v
                                          },
                                          expression: "confirmNewPassword"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "blue" },
                                      on: { click: _vm.resetPasswordData }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t_(
                                              "PASSWORD_CONFERMA_CAMBIO_PASSWORD"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Dialog", {
        attrs: { dialog: _vm.dialog, data: _vm.dialogData },
        on: {
          "update:dialog": function($event) {
            _vm.dialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }