//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "ProfiloUtente",
  props: ["profilo"],
  data: function data() {
    return {
      all: {},
      isVideoMode: false,
      showAllBadges: true,
      backpack: {},
      dim: 'md3 sm6 xs12',
      object: {
        absolute: true,
        bottom: true,
        top: false,
        left: false,
        right: true
      },
      badgeTags: []
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  computed: {
    badgeCard: {
      get: function get() {
        var my = [];
        var obj = {};

        for (var i in this.backpack.badges) {
          obj = {};
          obj.image = this.backpack.badges[i].image;
          obj.issuer = this.getName(this.getItemType(this.backpack.badges[i].guid_badge, 'Issuer'));
          obj.name = this.getName(this.getItemType(this.backpack.badges[i].guid_badge, 'BadgeClass'));
          obj.tags = this.getTags(this.getItemType(this.backpack.badges[i].guid_badge, 'BadgeClass'));
          obj.guid = this.backpack.badges[i].guid_badge;
          obj.email = this.backpack.badges[i].email;
          obj.revoked = this.backpack.badges[i].revoked;
          obj.expired = this.backpack.badges[i].expired;
          obj.is_mob_object = this.backpack.badges[i].is_mob_object;
          obj.permissions = this.getPrivacyBadge(this.backpack.badges[i]);
          obj.tooltip = this.translatePermissions(obj.permissions);
          obj.show_public = this.backpack.badges[i].show_public;
          obj.only_auth = this.backpack.badges[i].only_auth;
          my.push(obj);
        }

        return my;
      },
      set: function set() {}
    }
  },
  methods: {
    getData: function getData() {
      var self = this;
      axios.get('/user/getProfilePage/' + this.$options.propsData.profilo).then(function (data) {
        self.all = data.data.obj;
        self.backpack = data.data.obj.validator;
        self.isVideoMode = true;
      });
    },
    getUrlFile: function getUrlFile(item) {
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

      if (!regex.test(item)) {
        return '/' + item;
      } else {
        return item;
      }
    },
    GreyColorBadge: function GreyColorBadge(item) {
      var str = 'filter: grayscale(100%);';
      if (item.expired || item.revoked) return str;else return '';
    },
    getItemType: function getItemType(item, find) {
      for (var i in this.backpack.validator) {
        if (this.backpack.validator[i].hash_ === item) {
          for (var j in this.backpack.validator[i].validation.graph) {
            if (this.backpack.validator[i].validation.graph[j].type === find) {
              return this.backpack.validator[i].validation.graph[j];
            }
          }
        }
      }
    },
    getName: function getName(data) {
      if (data !== undefined) {
        if (data.name !== undefined) return data.name;else return 'ERROR!';
      } else return 'ERROR!';
    },
    getTags: function getTags(item) {
      var string = '';
      this.badgeTags = [];

      if (item !== undefined) {
        if (item.tags !== undefined) {
          for (var i in item.tags) {
            if (string !== '') string = string + ', ' + item.tags[i];else string = item.tags[i];
            this.badgeTags.push(item.tags[i]);
          }

          return string;
        } else return '';
      } else return '';
    },
    getPrivacyBadge: function getPrivacyBadge(item) {
      if (item.show_public === "1") {
        return 'public';
      } else {
        if (item.only_auth === "1") return 'verified_user';else return 'perm_identity';
      }
    },
    translatePermissions: function translatePermissions(item) {
      switch (item) {
        case 'public':
          return this.$t_('FE_TOOLTIP_UTENTE_STATO_PUB');
          break;

        case 'verified_user':
          return this.$t_('FE_TOOLTIP_UTENTE_STATO_AUTH');
          break;

        default:
          return this.$t_('FE_TOOLTIP_UTENTE_STATO_PRIV');
      }
    }
  }
};