//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "SubBar",
  props: ["items"],
  methods: {
    gotoSection: function gotoSection(item) {
      if (document.getElementsByClassName(item)[0].offsetTop < 100) window.scroll(0, 0);else window.scroll(0, document.getElementsByClassName(item)[0].offsetTop);
    }
  }
};