var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("userSidebar", { attrs: { ready: true } }),
      _vm._v(" "),
      _vm.isVideoMode
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm8: "", "pa-2": "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0" },
                            [
                              _c("v-card-title", [
                                _vm._v("\n\t\t\t\t\t\t\tSettings\n\t\t\t\t\t\t")
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Cluster Name" },
                                    model: {
                                      value: _vm.model.clustern_name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "clustern_name",
                                          $$v
                                        )
                                      },
                                      expression: "model.clustern_name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "pa-2": "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0" },
                            [
                              _c("v-card-title", [
                                _vm._v("\n\t\t\t\t\t\t\tTitolo\n\t\t\t\t\t\t")
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { subheader: "" } },
                                    [
                                      _c("v-subheader", [_vm._v("Badges")]),
                                      _vm._v(" "),
                                      _vm._l(_vm.customMy, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "v-list-tile",
                                          {
                                            key: item.guid,
                                            on: { click: function($event) {} }
                                          },
                                          [
                                            _c(
                                              "v-list-tile-action",
                                              [
                                                _c("v-checkbox", {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.checkSelectItems(
                                                        index
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: item.selected,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.selected"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-tile-content",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.checkSelectItems(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-tile-title", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getNameBadge(
                                                        item.guid
                                                      )
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pa-2": "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-1" },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tNuvola di Tags\n\t\t\t\t\t\t"
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                {
                                  staticStyle: {
                                    height: "750px",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm.showWordCloud
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              bottom: "0",
                                              left: "0",
                                              position: "absolute",
                                              right: "0",
                                              top: "0",
                                              "transition-duration": "1s"
                                            },
                                            style:
                                              _vm.progressVisible &&
                                              _vm.progress
                                                ? {
                                                    filter: "blur(8px)",
                                                    opacity: 0.3,
                                                    pointerEvents: "none",
                                                    transform: "scale(0.7,0.7)"
                                                  }
                                                : {}
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "ignore-css" },
                                              [
                                                _c("vue-word-cloud", {
                                                  attrs: {
                                                    words: _vm.words,
                                                    "font-family": "Roboto",
                                                    rotation:
                                                      _vm.rotationWCloud,
                                                    spacing: 1,
                                                    progress: _vm.progress,
                                                    "font-size-ratio": 1 / 2,
                                                    color: _vm.color,
                                                    "enter-animation":
                                                      _vm.enterAnimation,
                                                    "leave-animation":
                                                      _vm.leaveAnimation,
                                                    "animation-overlap":
                                                      _vm.animationOverlap,
                                                    "animation-duration": 1250,
                                                    "animation-easing":
                                                      _vm.animationEasing
                                                  },
                                                  on: {
                                                    "update:progress": function(
                                                      $event
                                                    ) {
                                                      _vm.progress = $event
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var text = ref.text
                                                          var weight =
                                                            ref.weight
                                                          var word = ref.word
                                                          var color = ref.color
                                                          var fontWeight =
                                                            ref.fontWeight
                                                          var fontStyle =
                                                            ref.fontStyle
                                                          var fontVariant =
                                                            ref.fontVariant
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "elemImportant",
                                                                attrs: {
                                                                  id: _vm.getIndex(
                                                                    text
                                                                  )
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      text
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2376344199
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-scale-transition",
                                          [
                                            _vm.progressVisible && _vm.progress
                                              ? _c(
                                                  "v-progress-circular",
                                                  {
                                                    staticClass:
                                                      "v-no-animation",
                                                    staticStyle: {
                                                      bottom: "0",
                                                      left: "0",
                                                      margin: "auto",
                                                      position: "absolute",
                                                      right: "0",
                                                      top: "0"
                                                    },
                                                    attrs: {
                                                      rotate: -90,
                                                      size: 200,
                                                      value:
                                                        (_vm.progress
                                                          .completedWords /
                                                          _vm.progress
                                                            .totalWords) *
                                                        100,
                                                      width: 20,
                                                      color: "primary"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.progress
                                                          .completedWords
                                                      ) +
                                                        " of " +
                                                        _vm._s(
                                                          _vm.progress
                                                            .totalWords
                                                        )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md7: "", "ma-2": "" } },
                        [
                          _vm.showWordCloud
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-1" },
                                [
                                  _c("v-card-title", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\tRisultato\n\t\t\t\t\t\t"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    _vm._l(_vm.wCloud, function(item, index) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: index,
                                          attrs: {
                                            color: item.is_my
                                              ? "red"
                                              : "primary",
                                            close: ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.removeFromCloud(index)
                                            }
                                          }
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(item.name))
                                          ]),
                                          _vm._v(" \n\t\t\t\t\t\t\t")
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "", "ma-2": "" } },
                        [
                          _vm.removed.length > 0
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-1" },
                                [
                                  _c("v-card-title", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\tEliminati\n\t\t\t\t\t\t"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    _vm._l(_vm.removed, function(item, index) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: index,
                                          attrs: { color: "red", close: "" },
                                          on: {
                                            input: function($event) {
                                              return _vm.removeFromRemoved(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("strong", [_vm._v(_vm._s(item))]),
                                          _vm._v(" \n\t\t\t\t\t\t\t")
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _vm.showSendButtons
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "blue",
                            fixed: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.saveCluster()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("\n\t\t\t\t\tadd\n\t\t\t\t")])],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }