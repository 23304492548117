var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        [
          _vm.showSubBar
            ? _c("SubBar", { attrs: { items: _vm.getItemSubBar } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-layout",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: _vm.onScroll,
                  expression: "onScroll"
                }
              ],
              attrs: { row: "", wrap: "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 white organizzazione" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.organizzazione.image,
                              contain: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "text-xs-center" },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "display-2  text-lg-center text-md-center text-sm-center text-xs-center"
                            },
                            [_vm._v(_vm._s(_vm.organizzazione.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "h4",
                            {
                              staticClass:
                                "headline  text-xs-center font-italic"
                            },
                            [_vm._v(_vm._s(_vm.organizzazione.payoff))]
                          ),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.organizzazione.content.frontend
                            )
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.organizzazione.content.frontend) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.organizzazione.remoteProject
                    ? _c(
                        "v-card",
                        { staticClass: "elevation-0 transparent mt-5 project" },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t_("SUB_HEADER_PROGETTI")) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c("GlobalCards", {
                            attrs: {
                              callRemote: true,
                              remoteUrlCall: _vm.CreateLinkProject(),
                              subheaderShow: false,
                              subheaderObjectData: false,
                              dimensions: "md4 sm6 xs12",
                              baseLink: "/mob",
                              loopItem: "",
                              tipologia: "orgs"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.organizzazione.remoteEndorse
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "elevation-0 transparent mt-5 endorsement"
                        },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t_("FE_LABEL_ENDORSEMENT_CARDS")) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c("GlobalCards", {
                            attrs: {
                              callRemote: true,
                              remoteUrlCall: _vm.CreateLinkEndorsement(),
                              subheaderShow: false,
                              subheaderObjectData: false,
                              dimensions: "md4 sm6 xs12",
                              baseLink: "/mob",
                              loopItem: "",
                              tipologia: "orgs"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.organizzazione.remoteBadge
                    ? _c(
                        "v-card",
                        { staticClass: "elevation-0 transparent mt-5 badges" },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t_("SUB_HEADER_BADGE")) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c("GlobalCards", {
                            attrs: {
                              callRemote: true,
                              remoteUrlCall: _vm.CreateLinkBadges(),
                              subheaderShow: false,
                              subheaderObjectData: false,
                              dimensions: "md4 sm6 xs12",
                              baseLink: "/mob",
                              loopItem: "progetto.endorsementObj",
                              tipologia: "orgs"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }