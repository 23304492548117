//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import UploadButton from 'vuetify-upload-button';
import JsonViewer from 'vue-json-viewer';
export default {
  name: "AddNewBadge",
  components: {
    'upload-btn': UploadButton,
    'JsonViewer': JsonViewer
  },
  data: function data() {
    return {
      object: '',
      fileName: '',
      image: '',
      all: {
        errore: false
      }
    };
  },
  methods: {
    update: function update(file) {
      console.log(file);
      this.fileName = file.name;
      this.image = file;
    },
    sendData: function sendData() {
      var self = this;
      self.send = true; //badge_object
      //badge_image
      //verify_elem[email][]

      var data = new FormData();

      if (this.object !== '' || this.image !== '') {
        data.append('badge_object', this.object);
        data.append('badge_image', this.image);
        var options = {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' + this.$localStorage.get('token')
          }
        };
        axios.post('/validator/addVueBadgeMixed', data, options).then(function (response) {
          //this.response=JSON.parse(String).obj;
          //self.response=response.data.obj;
          if (!response.data.obj.errore) {
            delete self.$root.backpack;
            self.$router.push('/backpack/badges');
          } else {
            self.all = response.data.obj;
            console.log(self.all);
          } //self.$root.newData=self.response;
          //self.$router.push('/mob/validator/result');

        });
      } else {
        /*this.dialog={
            title:this.$t_('FE_VALIDATOR_MESSAGE_TITLE_EMPTY_DATA_ERROR'),
            message:this.$t_('FE_VALIDATOR_MESSAGE_MESSAGE_EMPTY_ERROR_ATTRIBUTI'),
            btn_ok:this.$t_('BTN_CLOSE')
        }
        this.error=true;*/
      }
    },
    getAlertColor: function getAlertColor() {
      if (this.all.report.valid && parseInt(this.all.report.warningCount) === 0) return 'success';else if (this.all.report.valid && parseInt(this.all.report.warningCount) > 0) return 'success';else return 'error';
    },
    getAlertType: function getAlertType(item) {
      var my = {};

      if (item.messageLevel === 'ERROR') {
        my = {
          icon: 'warning',
          color: 'error'
        };
      } else {
        my = {
          icon: 'priority_high',
          color: 'warning'
        };
      }

      return my;
    }
  }
};