var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm10: "", md8: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0" },
                    [
                      _c("v-card-text", [
                        _c(
                          "div",
                          { staticClass: "text-xs-center" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("img", {
                                    staticStyle: {
                                      "background-position-y": "-25px",
                                      "background-position-x": "center",
                                      height: "100px",
                                      margin: "auto"
                                    },
                                    attrs: {
                                      src:
                                        "/public/assets/custom-mob/logo-mob-img.png",
                                      contain: ""
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "offset-xs2",
                                    attrs: { xs8: "" }
                                  },
                                  [
                                    _c("v-img", {
                                      staticStyle: {
                                        "max-width": "300px",
                                        margin: "auto"
                                      },
                                      attrs: {
                                        src: "" + _vm.badgeTake.orgs.icona,
                                        contain: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-xs-center title mb-3 mt-3" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.$t_("TAKE_BADGE_LABEL_HEADER")) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "mt-3 pl-5 pr-5" }, [
                        _c(
                          "div",
                          { staticClass: "text-xs-center" },
                          [
                            _c("v-img", {
                              staticStyle: {
                                "max-width": "250px",
                                margin: "auto"
                              },
                              attrs: {
                                src: "/" + _vm.badgeTake.image,
                                contain: ""
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.badgeTake.orgs.id !== 60
                          ? _c("div", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t_("TAKE_BADGE_LABEL_EMESSO_A")) +
                                  " "
                              ),
                              _c("b", [_vm._v(_vm._s(_vm.badgeTake.email))])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-xs-center mt-5" },
                          [
                            _c("h3", [_vm._v("Share")]),
                            _vm._v(" "),
                            _vm._l(_vm.networks, function(network) {
                              return _c(
                                "ShareNetwork",
                                {
                                  key: network.network,
                                  staticClass: "ma-1",
                                  style: {
                                    backgroundColor: network.color,
                                    color: "#FFF"
                                  },
                                  attrs: {
                                    network: network.network,
                                    url: _vm.sharing.url,
                                    title: _vm.sharing.title,
                                    description: _vm.sharing.description,
                                    quote: _vm.sharing.quote,
                                    hashtags: _vm.sharing.hashtags,
                                    twitterUser: _vm.sharing.twitterUser,
                                    tag: "v-btn"
                                  },
                                  on: {
                                    open: function($event) {
                                      return _vm.actionsUser(network)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    class: network.icon,
                                    staticStyle: { color: "#FFF" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pl-2",
                                      staticStyle: {
                                        color: "#FFF !important",
                                        display: "none"
                                      }
                                    },
                                    [_vm._v(_vm._s(network.name))]
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "mt-3 pl-5 pr-5" },
                        [
                          _c("div", { staticClass: "pb-3" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.$t_("TAKE_BADGE_LABEL_INFO_1")) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "pt-3 " }),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t_("TAKE_BADGE_LABEL_INFO_2")
                                )
                              }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t_("TAKE_BADGE_LABEL_INFO_2")) +
                                  "\n\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "mt-3 pl-5 pr-5" },
                        [_c("v-divider")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "text-xs-center pa-5" },
                        _vm._l(_vm.buttons, function(btn, index) {
                          return btn.view
                            ? _c(
                                "v-btn",
                                {
                                  key: index,
                                  staticClass: "pa-3 mx-auto",
                                  attrs: { color: btn.color },
                                  on: {
                                    click: function($event) {
                                      return _vm.routePush(btn)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(btn.name) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            : _vm._e()
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }