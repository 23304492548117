//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import VFormBase from '../../../node_modules/vuetify-form-base';
import Dialog from '../../app/components/utils/Dialog';
export default {
  name: "login",
  props: ['type', "codice"],
  components: {
    VFormBase: VFormBase,
    Dialog: Dialog
  },
  data: function data() {
    return {
      drawer: null,
      identiry: '',
      password: '',
      // tipologia:'login',
      tipologia: 'login',
      data: {},
      valid: false,
      accetta_termini: false,
      dialog: false,
      dialogData: {
        title: '',
        message: '',
        btn_ok: ''
      },
      showMessageError: false,
      errorMessage: '',
      send: false,
      isVideoMode: false,
      emailRescuePassword: '',
      newPassword: '',
      confirmNewPassword: '',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      loginIdem: false,
      inputtwofactor: {
        c: null,
        z: null,
        r: null,
        t: null,
        f: null
      },
      curin: null
    };
  },
  mounted: function mounted() {
    if (this.$options.propsData.type === 'logout') {
      this.$localStorage.remove('token');
      this.$localStorage.remove('backpack');
      this.$localStorage.remove('lastDown');
      window.location = '/auth/Vlogout';
    } else {
      this.getRegistratiModel();
      this.tipologia = this.$options.propsData.type;
    }
  },
  watch: {
    dialog: {
      handler: function handler() {
        if (this.tipologia === 'resetpassword') {
          if (!this.dialog) {
            this.$router.push('/badge/show');
          }
        }
      },
      inputtwofactor: {
        handler: function handler() {},
        deep: true
      }
    },
    accetta_termini: {
      handler: function handler() {
        console.log(this.accetta_termini);
      }
    },
    loginIdem: {
      handler: function handler() {
        window.open('https://idem.myopenbadge.com', '_self');
      }
    }
  },
  methods: {
    next: function next(e) {
      var _this = this;

      var in_next = false;
      console.log(this.$refs.elements_next);
      var a = Object.keys(this.inputtwofactor).map(function (e, k) {
        console.log(k, e);

        if (_this.inputtwofactor[e] === null && !in_next) {
          console.log(_this.$refs.elements_next[k]);

          _this.$refs.elements_next[k].focus();

          in_next = true;
        }
      });
    },
    getRegistratiModel: function getRegistratiModel() {
      var self = this;
      axios.get('/user/regsUtnV').then(function (response) {
        self.data = response.data.obj;
        self.data.schema = self.$getRulesInput(self.data.schema);
        self.isVideoMode = true;
      });
    },
    update: function update(_ref) {
      var on = _ref.on,
          id = _ref.id,
          key = _ref.key,
          value = _ref.value,
          obj = _ref.obj,
          event = _ref.event,
          params = _ref.params,
          size = _ref.size,
          data = _ref.data,
          schema = _ref.schema;
      var self = this; //console.log(self.all_images);
    },
    resendMFA: function resendMFA() {
      var _this2 = this;

      axios({
        method: 'post',
        url: '/auth/sendmfa'
      }).then(function (res) {
        if (res.data.obj.res) {
          _this2.$swal({
            title: 'Sended',
            icon: 'info',
            width: 600,
            padding: '3em',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 4500
          });
        } else {
          _this2.tipologia = 'login';
        }
      });
    },
    checktwofactorauth: function checktwofactorauth() {
      var _this3 = this;

      axios({
        method: 'post',
        url: '/auth/mfa',
        data: {
          d: this.curin
        }
      }).then(function (response) {
        if (response.data.obj.mfa) {
          _this3.$emit('heyupdate', {
            reload: true
          });

          _this3.$root.logged = true;

          _this3.$router.push('/backpack/user');
        } else {
          _this3.$swal({
            title: 'MFA ERROR',
            icon: 'error',
            width: 600,
            padding: '3em',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 4500
          });
        }
      })["catch"](function (er) {
        _this3.$swal({
          title: 'Custom width, padding, color, background.',
          icon: 'error',
          width: 600,
          padding: '3em'
        });
      });
      console.log(this.inputtwofactor);
    },
    sendDataTwoFactor: function sendDataTwoFactor() {},
    sendDataLogin: function sendDataLogin() {
      var _this4 = this;

      var self = this;
      axios({
        url: '/auth/parse_input_lg',
        method: 'POST',
        data: {
          identiry: this.identiry,
          password: this.password
        }
      }).then(function (response) {
        if (response.data.obj.lg) {
          if (response.data.obj.tf) {
            self.tipologia = 'mfa';
          } else {
            _this4.$emit('heyupdate', {
              reload: true
            });

            setTimeout(function () {
              self.$root.logged = true;
              self.$router.push('/backpack/user');
            }, 200);
          }

          setTimeout(function () {
            self.checkLangCookies(response.data.obj.l);
          }, 2500); //console.log(self.$root);
          //window.location='/badge/show';
        } else {
          self.errorMessage = response.data.obj.reason;
          self.showMessageError = true;
        }
      });
    },
    checkLangCookies: function checkLangCookies(newLang) {
      if (this.$cookie.get('appLang') !== newLang) {
        this.$cookie.set('appLang', newLang);
        window.location.reload();
      }
    },
    sendDataRegister: function sendDataRegister() {
      this.trovaDifferenze();

      if (this.send) {
        if (this.$refs.form.validate()) {
          var self = this;
          axios({
            method: 'post',
            url: '/user/registerVue',
            data: this.data.values
          }).then(function (response) {
            if (response.data.obj.oo !== 'error_email') {
              self.dialogData.title = self.$t_('FE_LOGIN_NEW_TITOLO_REGISTRAZIONE_AVVENUTA_CON_SUCCESSO');
              self.dialogData.btn_ok = self.$t_('BTN_CLOSE');
              self.dialogData.message = self.$t_('FE_LOGIN_NEW_MESSAGGIO_REGISTRAZIONE_AVVENUTA_SENZA_ATTIVAZIONE');
              self.send = false;
              self.dialog = true;
              setTimeout(function () {
                self.$router.push('/badge/show');
              }, 5000);
            } else {
              self.dialogData.title = 'ERRORE';
              self.dialogData.btn_ok = self.$t_('BTN_CLOSE');
              self.dialogData.message = 'Questa email è presente nel sistema, tra poco verrai reindirizzato per la modifica della password';
              self.send = false;
              self.dialog = true;
              setTimeout(function () {
                self.$router.push('/resetpassword');
              }, 3000);
            }
          });
        }
      }
    },
    trovaDifferenze: function trovaDifferenze() {
      this.dialogData.title = this.$t_('TITOLO_ERRORE_MSG_UTENTE_REG');
      this.dialogData.btn_ok = this.$t_('BTN_CLOSE');

      if (this.data.values.email !== this.data.values.confirm_email) {
        this.dialogData.message = this.$t_('MESG_ERRORE_MSG_UTENTE_REG_EMAIL');
        this.send = false;
        this.dialog = true;
      } else this.send = true;

      if (this.data.values.password !== this.data.values.config_password) {
        this.dialogData.message = this.$t_('MESG_ERRORE_MSG_UTENTE_REG_PASS');
        this.send = false;
        this.dialog = true;
      } else this.send = true;

      if (!this.accetta_termini) {
        this.dialogData.message = this.$t_('MESG_ERRORE_MSG_UTENTE_REG_TERMINI');
        this.send = false;
        this.dialog = true;
      } else this.send = true;
    },
    openTerms: function openTerms() {
      window.open('/public/doc/v1/termini-di-servizio-MyOpenBadge.pdf', '_blank');
    },
    InviaCodiceUnivocoResetPassword: function InviaCodiceUnivocoResetPassword() {
      if (this.emailRescuePassword !== '') {
        var self = this;
        axios({
          method: 'post',
          url: '/auth/Vue_forgot_password',
          data: {
            identity: this.emailRescuePassword
          }
        }).then(function (response) {
          self.dialogData = response.data.obj;
          self.dialog = true;
        });
      }
    },
    isEmailValid: function isEmailValid(email) {
      return email == "" ? "" : this.reg.test(email) ? true : false;
    },
    resetPasswordData: function resetPasswordData() {
      if (this.newPassword !== '' && this.confirmNewPassword !== '') {
        if (this.newPassword === this.confirmNewPassword) {
          var self = this;
          axios({
            method: 'post',
            url: '/auth/newResetPassword/' + this.$options.propsData.codice,
            data: {
              "new": this.newPassword
            }
          }).then(function (response) {
            self.dialogData = response.data.obj;
            self.dialog = true;
            setTimeout(function () {
              self.$router.push('/badge/show');
            }, 7000);
          });
        } else {
          this.dialogData = {
            title: 'Errore',
            message: this.$t_('MESG_ERRORE_MSG_UTENTE_REG_PASS'),
            btn_ok: this.$t_('BTN_CLOSE')
          };
          this.dialog = true;
        }
      } else {
        this.dialogData = {
          title: 'Errore',
          message: this.$t_('MESG_ERRORE_MSG_UTENTE_REG_PASS'),
          btn_ok: this.$t_('BTN_CLOSE')
        };
        this.dialog = true;
      }
    }
  }
};