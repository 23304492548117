function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
} //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//import { VueSvgGauge } from 'vue-svg-gauge'


import { VueSimpleMarkdown } from 'vue-simple-markdown';
import EndorsementCard from '../utils/widget/EndorsementCard';
import JsonViewer from 'vue-json-viewer';
export default {
  name: "ValidatorResponse",
  props: ["current"],
  components: {
    'JsonViewer': JsonViewer,
    'vue-simple-markdown': VueSimpleMarkdown,
    'EndorsementCard': EndorsementCard //'vue-svg-gauge':VueSvgGauge

  },
  data: function data() {
    return {
      valid: {},
      all: {},
      badgeClass: {},
      issuer: {},
      assertion: {},
      endorsement: {
        badge: [],
        issuer: []
      },
      haveBadgeEndorse: false,
      haveIssuerEndorse: false,
      show: false,
      nazioniTranslate: [],
      customData: [],
      badgeVersion: [{
        version: "1.0",
        year: 2013,
        end: 2016,
        check: false
      }, {
        version: "1.1",
        year: 2015,
        end: 2016,
        check: false
      }, {
        version: "1.2",
        year: 2016,
        end: 2016,
        check: false
      }, {
        version: "2.0",
        year: 2017,
        end: 'current',
        check: false
      }],
      noBadge: false,
      showRawData: true,
      showEmailUser: true,
      globalNazioni: [],
      utilites: {
        'iso2': {},
        'iso3': {},
        'imgs': {},
        'oth': {}
      }
    };
  },
  computed: {
    currentValueVersion: {
      get: function get() {
        if (this.all.report.openBadgesVersion !== undefined) {
          switch (this.all.report.openBadgesVersion) {
            case '1.0':
              return {
                val: 1,
                text: 'POOR'
              };
              break;

            case '1.1':
              return {
                val: 2,
                text: 'BAD'
              };
              break;

            case '1.2':
              return {
                val: 3,
                text: 'OK'
              };
              break;

            case '2.0':
              return {
                val: 4,
                text: 'SUPER'
              };
              break;
          }
        }
      },
      set: function set() {}
    },
    lateralMenu: {
      get: function get() {
        var my = [];
        if (this.showRawData) my.push({
          title: 'Verification',
          section: 'verify'
        });
        my.push({
          title: 'Badge',
          section: 'badge'
        });
        if (this.haveBadgeEndorse) my.push({
          title: 'Badge Endorser',
          section: 'badge_endorser'
        });
        my.push({
          title: 'Issuing',
          section: 'issuing'
        });
        my.push({
          title: 'Issuer',
          section: 'issuer'
        });
        if (this.haveIssuerEndorse) my.push({
          title: 'Issuer Endorser',
          section: 'issuer_endorser'
        });
        if (this.showRawData) my.push({
          title: 'Raw Data',
          section: 'raw_data'
        });
        return my;
      },
      set: function set() {}
    },
    imgNazione: function imgNazione(nz) {
      console.log(nz);
      console.log(this.convertIso);

      for (var i in this.convertIso) {
        if (this.globalNazioni[i][1] === nz) return this.convertIso[i];
      }
    }
  },
  mounted: function mounted() {
    var my = ['BadgeClass', 'Issuer', 'Assertion', 'Endorsement'];
    console.log(this.$root, this.current);

    if (this.current === undefined && this.$root.newData !== undefined) {
      this.valid = this.$root.newData.graph;
      this.all = this.$root.newData;

      if (this.$root.callFrom === 'pageBadge') {
        this.showRawData = false;
        this.showEmailUser = false;
      }

      console.log(this.all.graph);

      if (this.all.graph.length > 1) {
        this.getImageIso();
        this.getItem();
        this.getCustomData();
      } else {
        this.show = true;
        this.noBadge = true;
      }
    } else {
      // console.log(this.all)
      this.$router.push('/mob/validator');
    }
  },
  methods: {
    getImageIso: function getImageIso() {
      var currentNazioni = this.$getIsoNationEx();
      this.utilites.iso3 = currentNazioni.map;
      var remap = {};
      remap = Object.fromEntries(Object.entries(currentNazioni.map).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        return [value, key];
      }));
      this.utilites.iso2 = remap;

      for (var i in currentNazioni.nz) {
        this.utilites.oth[currentNazioni.nz[i]['Cod']] = {
          o: currentNazioni.nz[i]['Nation'],
          l: currentNazioni.nz[i]['Nation'].toLowerCase()
        };
        this.utilites.imgs[currentNazioni.nz[i]['Cod']] = '/public/_flags/' + currentNazioni.nz[i]['Nation'].toLowerCase() + '.png';
      }

      console.log(this.utilites);
    },
    //BadgeClass
    //Issuer
    //Assertion
    //Endorsement
    getItem: function getItem() {
      this.getBadgeClass();
      this.getIssuer();
      this.getAssertion();
      this.getEndorsement();
      this.show = true;
    },
    getBadgeClass: function getBadgeClass() {
      for (var i in this.valid) {
        if (this.valid[i].type === 'BadgeClass') this.badgeClass = this.valid[i];
      }

      if (typeof this.badgeClass.tags === 'string') {
        var my = this.badgeClass.tags;
        this.badgeClass.tags = [];
        this.badgeClass.tags = [my];
      }
    },
    getIssuer: function getIssuer() {
      for (var i in this.valid) {
        if (this.valid[i].type === 'Issuer') {
          if (this.assertion.issuer !== undefined) {
            if (this.assertion.issuer === this.valid[i].id) {
              this.issuer = this.valid[i];
            }
          } else if (this.badgeClass.issuer === this.valid[i].id) {
            this.issuer = this.valid[i];
          }
        }
      }
    },
    getAssertion: function getAssertion() {
      for (var i in this.valid) {
        if (this.valid[i].type === 'Assertion') this.assertion = this.valid[i];
      }
    },
    getEndorsement: function getEndorsement() {
      var self = this;
      this.getBadgeEndorserment();
      this.getIssuerEndorsement();
    },
    getBadgeEndorserment: function getBadgeEndorserment() {
      var self = this;

      if (this.badgeClass.endorsement !== undefined) {
        this.haveBadgeEndorse = true;
        this.getPostDataEndorser(this.badgeClass.endorsement).then(function (data) {
          self.endorsement.badge = data.data.obj;
        });
      }
    },
    getIssuerEndorsement: function getIssuerEndorsement() {
      var self = this;

      if (this.issuer.endorsement !== undefined) {
        this.haveIssuerEndorse = true;
        this.getPostDataEndorser(this.issuer.endorsement).then(function (data) {
          self.endorsement.issuer = data.data.obj;
        });
      }
    },
    getAlertColor: function getAlertColor() {
      if (this.all.report.valid && parseInt(this.all.report.warningCount) === 0) return 'success';else if (this.all.report.valid && parseInt(this.all.report.warningCount) > 0) return 'success';else return 'error';
    },
    getOtherLanguages: function getOtherLanguages(url) {
      this.getImageIso();
      var self = this;
      axios({
        method: 'post',
        data: {
          data: url
        },
        url: '/validator/getData'
      }).then(function (data) {
        self.badgeClass = data.data.obj;
      });
    },
    getPostDataEndorser: function getPostDataEndorser(data) {
      return axios({
        method: 'post',
        data: {
          data: data
        },
        url: '/validator/resolvEndorsement'
      });
    },
    getNationFromIso2: function getNationFromIso2(iso2) {
      this.$convertIso();
      var data = this.$trovaNazione(iso2.toUpperCase(), 1);
      return data;
    },
    getImage: function getImage(iso2) {
      var data = this.getNationFromIso2(iso2);
      if (data !== undefined) return this.$immaginiNazione(data[2]);
    },
    getTranslateLang: function getTranslateLang(iso2) {
      var data = this.getNationFromIso2(iso2);
      if (data !== undefined) return this.$traduzioneNazione(data[2]);
    },
    getCustomData: function getCustomData() {
      var self = this;

      if (this.all.graph.length) {
        if (this.badgeClass.id.includes("app.myopenbadge.com")) {
          axios.get(this.badgeClass.id).then(function (data) {
            self.customData = data.data;
            self.show = true;
          });
        }
      } else {
        self.show = true;
      }
    },
    ScrollSection: function ScrollSection(item) {
      if (document.getElementsByClassName(item)[0].offsetTop < 100) window.scroll(0, 0);else window.scroll(0, document.getElementsByClassName(item)[0].offsetTop);
    },
    getAlertType: function getAlertType(item) {
      var my = {};

      if (item.messageLevel === 'ERROR') {
        my = {
          icon: 'warning',
          color: 'error'
        };
      } else {
        my = {
          icon: 'priority_high',
          color: 'warning'
        };
      }

      return my;
    },
    openNewTab: function openNewTab(url) {
      window.open(url, '_blank');
    }
  }
};