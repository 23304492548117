//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import draggable from 'vuedraggable';
export default {
  name: "BackPackBadges",
  components: {
    draggable: draggable
  },
  data: function data() {
    return {
      isVideoMode: false,
      backpack: {},
      deleteDialog: false,
      deleteDialogCard: {
        title: this.$t_('FE_UTENTE_BADGE_TITOLO_CANCELLA_BADGE'),
        message: this.$t_('FE_UTENTE_BADGE_MESSAGE_CANCELLA_BADGE'),
        cancel: false,
        "continue": false
      },
      showAllBadges: true,
      dim: 'lg3 md4 sm12 xs12',
      object: {
        absolute: true,
        bottom: true,
        top: false,
        left: false,
        right: true
      },
      badgeTags: [],
      calcolato: [],
      update: false,
      pagination: {
        pages: [],
        current: 0,
        listPages: [5, 10, 25, 50, 100],
        curListPages: 10,
        itemsBadge: [],
        itemsValidation: []
      },
      isFirst: true
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  watch: {
    calcolato: {
      handler: function handler() {
        var my = [];
        var self = this;

        for (var i in this.calcolato) {
          this.calcolato[i].tooltip = this.translatePermissions(this.calcolato[i].permissions);
          my.push(this.calcolato[i].guid);
        } //if(!this.isFirst){


        axios({
          method: 'post',
          url: '/user/orderMyBadge',
          data: {
            ordinamento: my
          }
        }).then(function (response) {
          self.loadNewData();
        }); //   setTimeout(()=>{this.isFirst=false},2000)
        //}
      }
    }
  },
  computed: {
    badgeCard: {
      get: function get() {
        var my = [];
        var obj = {};

        for (var i in this.backpack.badges) {
          obj = {};
          obj.image = this.backpack.badges[i].image;
          obj.issuer = this.getName(this.getItemType(this.backpack.badges[i].guid_badge, 'Issuer'));
          obj.name = this.getName(this.getItemType(this.backpack.badges[i].guid_badge, 'BadgeClass'));
          obj.tags = this.getTags(this.getItemType(this.backpack.badges[i].guid_badge, 'BadgeClass'));
          obj.guid = this.backpack.badges[i].guid_badge;
          obj.email = this.backpack.badges[i].email;
          obj.revoked = this.backpack.badges[i].revoked;
          obj.expired = this.backpack.badges[i].expired;
          obj.notarize = this.backpack.badges[i].notarize;
          obj.is_mob_object = this.backpack.badges[i].is_mob_object;
          obj.permissions = this.getPrivacyBadge(this.backpack.badges[i]);
          obj.tooltip = this.translatePermissions(obj.permissions);

          if (obj.is_mob_object === '1') {
            if (this.backpack.badges[i].compose !== undefined && this.backpack.badges[i].compose.attributes !== undefined) {
              if (this.backpack.badges[i].compose.attributes[0] !== undefined) {
                if (this.backpack.badges[i].compose.attributes[0].key !== undefined && this.backpack.badges[i].compose.attributes[0].key !== '') {
                  obj.customData = true;
                } else obj.customData = false;
              } else obj.customData = false;
            } else obj.customData = false;
          } else obj.customData = false;

          my.push(obj);
        }

        return my;
      },
      set: function set() {}
    }
  },
  methods: {
    getData: function getData() {
      var self = this;

      if (this.$root.backpack !== undefined) {
        this.backpack = this.$root.backpack;
        this.createViewBadge();
        this.isVideoMode = true;
      } else {
        var backpack = this.$localStorage.get('backpack');

        if (backpack === null || backpack === undefined) {
          axios.get('/user/VueBackpack').then(function (response) {
            self.backpack = response.data.obj;
            self.$root.backpack = self.backpack; //self.createViewBadge();

            self.isVideoMode = true;
            this.calcolato = response.data.obj.backStore;

            if (response.data.obj !== undefined) {
              if (response.data.obj.backStore !== undefined) {
                self.$localStorage.set('backpack', response.data.obj.backStore);
              }
            }
          });
        } else {
          this.calcolato = backpack;
        }
      }
    },
    CancellaBadge: function CancellaBadge() {},
    loadNewData: function loadNewData() {
      var self = this;
      axios.get('/user/VueBackpack').then(function (response) {
        self.backpack = response.data.obj;
        self.$root.backpack = self.backpack; //self.createViewBadge();

        self.isVideoMode = true;

        if (response.data.obj !== undefined) {
          //self.$localStorage.set('backpack',response.data.obj);
          if (response.data.obj.backStore !== undefined) self.$localStorage.set('backpack', response.data.obj.backStore);
        }
      });
    },
    createViewBadge: function createViewBadge() {
      var my = [];
      var obj = {};

      for (var i in this.backpack.badges) {
        obj = {};
        obj.image = this.backpack.badges[i].image;
        obj.issuer = this.getName(this.getItemType(this.backpack.badges[i].guid_badge, 'Issuer'));
        obj.name = this.getName(this.getItemType(this.backpack.badges[i].guid_badge, 'BadgeClass'));
        obj.tags = this.getTags(this.getItemType(this.backpack.badges[i].guid_badge, 'BadgeClass'));
        obj.guid = this.backpack.badges[i].guid_badge;
        obj.email = this.backpack.badges[i].email;
        obj.revoked = this.backpack.badges[i].revoked;
        obj.expired = this.backpack.badges[i].expired;
        obj.notarize = this.backpack.badges[i].notarize;
        obj.is_mob_object = this.backpack.badges[i].is_mob_object;
        obj.permissions = this.getPrivacyBadge(this.backpack.badges[i]);
        obj.tooltip = this.translatePermissions(obj.permissions);

        if (obj.is_mob_object === '1') {
          if (this.backpack.badges[i].compose !== undefined && this.backpack.badges[i].compose.attributes !== undefined) {
            if (this.backpack.badges[i].compose.attributes[0] !== undefined) {
              if (this.backpack.badges[i].compose.attributes[0].key !== undefined && this.backpack.badges[i].compose.attributes[0].key !== '') {
                obj.customData = true;
              } else obj.customData = false;
            } else obj.customData = false;
          } else obj.customData = false;
        } else obj.customData = false;

        my.push(obj);
      }

      this.calcolato = my;
    },
    getUrlFile: function getUrlFile(item) {
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

      if (!regex.test(item)) {
        return '/' + item;
      } else {
        return item;
      }
    },
    GreyColorBadge: function GreyColorBadge(item) {
      var str = 'filter: grayscale(100%);';
      if (item.expired || item.revoked) return str;else return '';
    },
    getItemType: function getItemType(item, find) {
      for (var i in this.backpack.validator) {
        if (this.backpack.validator[i].hash_ === item) {
          for (var j in this.backpack.validator[i].validation.graph) {
            if (this.backpack.validator[i].validation.graph[j].type === find) {
              return this.backpack.validator[i].validation.graph[j];
            }
          }
        }
      }
    },
    getName: function getName(data) {
      if (data !== undefined) {
        if (data.name !== undefined) return data.name;else return 'ERROR!';
      } else return 'ERROR!';
    },
    getTags: function getTags(item) {
      var string = '';
      this.badgeTags = [];

      if (item !== undefined) {
        if (item.tags !== undefined) {
          for (var i in item.tags) {
            if (string !== '') string = string + ', ' + item.tags[i];else string = item.tags[i];
            this.badgeTags.push(item.tags[i]);
          }

          return string;
        } else return '';
      } else return '';
    },
    getPrivacyBadge: function getPrivacyBadge(item) {
      if (item.show_public === "1") {
        return 'public';
      } else {
        if (item.only_auth === "1") return 'verified_user';else return 'perm_identity';
      }
    },
    translatePermissions: function translatePermissions(item) {
      switch (item) {
        case 'public':
          return this.$t_('FE_TOOLTIP_UTENTE_STATO_PUB');
          break;

        case 'verified_user':
          return this.$t_('FE_TOOLTIP_UTENTE_STATO_AUTH');
          break;

        default:
          return this.$t_('FE_TOOLTIP_UTENTE_STATO_PRIV');
      }
    }
  }
};