//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VueSimpleMarkdown } from 'vue-simple-markdown';
import Loader from "./loader";
export default {
  name: "EndorsementCard",
  props: ["items", "title", "classe"],
  components: {
    Loader: Loader,
    'vue-simple-markdown': VueSimpleMarkdown
  }
};