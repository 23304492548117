var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _vm.canShowBadge
            ? [
                _c(
                  "v-container",
                  { attrs: { fluid: _vm.getDimension() } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-card",
                              { staticClass: "elevation-0" },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "", wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: { xs6: "", sm4: "", md3: "" }
                                          },
                                          [
                                            _c("v-img", {
                                              staticStyle: {
                                                margin: "auto",
                                                "max-width": "150px"
                                              },
                                              attrs: {
                                                src:
                                                  _vm.user.meta.profile_image,
                                                contain: ""
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: { md9: "", sm8: "", xs6: "" }
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "elevation-0" },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: " title" },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.user
                                                                .first_name
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.user.last_name
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-divider"),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list",
                                                      {
                                                        staticClass:
                                                          "transparent"
                                                      },
                                                      [
                                                        _c("v-list-tile", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "blue--text pr-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t_(
                                                                    "FE_UTENTE_DATA_RICEZIONE_BADGE"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "moment"
                                                                )(
                                                                  _vm.assertion
                                                                    .iTXt
                                                                    .issuedOn,
                                                                  "DD/MM/YYYY"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _vm.assertion
                                                          .revocation[0] ===
                                                        undefined
                                                          ? [
                                                              _vm.assertion.iTXt
                                                                .expires !==
                                                                undefined &&
                                                              _vm.assertion.iTXt
                                                                .expires !== ""
                                                                ? _c(
                                                                    "v-list-tile",
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "blue--text pr-3"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t_(
                                                                                "FE_VALIDATOR_ASSERTION_SCADENZA"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "moment"
                                                                            )(
                                                                              _vm
                                                                                .assertion
                                                                                .iTXt
                                                                                .expires,
                                                                              "DD/MM/YYYY"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-list-tile",
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "blue--text pr-3"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t_(
                                                                                "FE_VALIDATOR_ASSERTION_NESSUNA_SCADENZA"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                            ]
                                                          : [
                                                              _c(
                                                                "v-list-tile",
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "red--text pr-3"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t_(
                                                                            "FE_UTENTE_BADGE_REVOCATO_TITLE"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("b", [
                                                                    _c("i", [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm
                                                                              .assertion
                                                                              .revocation[0]
                                                                              .revocationReason
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      )
                                                                    ])
                                                                  ])
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-list-tile",
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "blue--text pr-3"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t_(
                                                                            "FE_UTENTE_BADGE_REVOCATO_IL"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "moment"
                                                                        )(
                                                                          _vm
                                                                            .assertion
                                                                            .revocation[0]
                                                                            .created_on,
                                                                          "DD/MM/YYYY"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.assertion.iTXt.narrative !== undefined
                          ? _c(
                              "v-flex",
                              { staticClass: "mt-5", attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "elevation-0" },
                                  [
                                    _c(
                                      "v-card-title",
                                      { staticClass: "display-1" },
                                      [
                                        _vm._v(
                                          "\n\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t_(
                                                "TITLE_CART_ASSERTION_NARRATIVE_PUBLIC"
                                              )
                                            ) +
                                            "\n\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("v-card-text", [
                                      _vm.assertion.iTXt.narrative.name !==
                                      undefined
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "blue--text font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "_FE_TITLE_ISSUING_NARRATIVE_ISSUING_NOME"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              ": " +
                                                _vm._s(
                                                  _vm.assertion.iTXt.narrative
                                                    .name
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.assertion.iTXt.narrative.genre !==
                                      undefined
                                        ? _c("div", { staticClass: "pt-3" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "blue--text font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "_FE_TITLE_ISSUING_NARRATIVE_ISSUING_GENRE"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              ": " +
                                                _vm._s(
                                                  _vm.assertion.iTXt.narrative
                                                    .genre
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.assertion.iTXt.narrative
                                        .description !== undefined
                                        ? _c("div", { staticClass: "pt-3" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "blue--text font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "_FE_TITLE_ISSUING_NARRATIVE_ISSUING_DESCRIPTION"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              ": " +
                                                _vm._s(
                                                  _vm.assertion.iTXt.narrative
                                                    .description
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.assertion.is_mob_object === "1"
                  ? [
                      _c("BadgeDetails", {
                        attrs: {
                          myurl:
                            "/mob/getBadgeDetails/" +
                            _vm.assertion.master_guid_badge
                        }
                      })
                    ]
                  : [_c("ValidatorResponse")]
              ]
            : [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-img", {
                          attrs: {
                            src: "/public/images/401.jpg",
                            contain: "",
                            "max-height": "50vh"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }