//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Images",
  props: ['immagine'],
  data: function data() {
    return {
      image: {},
      dialog: false,
      notifications: false,
      sound: true
    };
  },
  created: function created() {
    var self = this;
    self.image = this.$options.propsData.immagine;
  },
  methods: {
    update: function update(_ref) {
      var on = _ref.on,
          id = _ref.id,
          key = _ref.key,
          value = _ref.value,
          obj = _ref.obj,
          event = _ref.event,
          params = _ref.params,
          size = _ref.size,
          data = _ref.data,
          schema = _ref.schema;
      var self = this;
      console.log(self.image);
    },
    isHidden: function isHidden(elem) {
      if (elem.fullHeight) return true;else return false;
    },
    getClass: function getClass(immagine) {
      if (immagine.fullHeight) return 'xs12';else return 'xs6';
    },
    salvaImmagineContainer: function salvaImmagineContainer(immagine) {
      var self = this;
      self.dialog = false;
      self.immagine.change = true;
      self.immagine.lurl = immagine.url;
    },
    getHeaderClass: function getHeaderClass(immagine) {
      if (immagine.is_badgeClass) return '';else return 'pa-3 mb-2';
    }
  }
};