var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _vm._v("\n            Cerca:\n            "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.all.pages !== undefined && _vm.all.pages.next
        ? _c(
            "v-card-title",
            [
              _c("h3", [
                _vm._v(
                  "Load: " +
                    _vm._s(_vm.all.pages.current) +
                    " / " +
                    _vm._s(_vm.all.pages.total)
                )
              ]),
              _vm._v(" "),
              _c("v-progress-linear", {
                attrs: {
                  color: "secondary",
                  height: "10",
                  value: parseInt(_vm.all.pages.percentage)
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVideoDisplay
        ? _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.dati,
              pagination: _vm.pagination,
              search: _vm.search
            },
            on: {
              "update:items": function($event) {
                _vm.dati = $event
              },
              "update:pagination": function($event) {
                _vm.pagination = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "items",
                  fn: function(props) {
                    return _vm._l(props.item, function(valore, chiave) {
                      return _c(
                        "td",
                        [
                          chiave === "edit"
                            ? _c(
                                "router-link",
                                {
                                  key: "edits__" + valore,
                                  attrs: { to: valore }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        flat: "",
                                        fab: "",
                                        icon: "",
                                        small: "",
                                        color: "orange"
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("edit")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : chiave === "links"
                            ? _c(
                                "router-link",
                                {
                                  key: "links__" + valore,
                                  attrs: { to: valore }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        flat: "",
                                        fab: "",
                                        icon: "",
                                        small: "",
                                        color: "orange"
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("link")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : chiave == "delete"
                            ? _c(
                                "button",
                                {
                                  key: "Deletelinks__" + valore,
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteResources(valore)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        flat: "",
                                        fab: "",
                                        icon: "",
                                        small: "",
                                        color: "red"
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("delete")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : chiave === "custom_order"
                            ? _c(
                                "v-edit-dialog",
                                {
                                  attrs: {
                                    "return-value": props.item[chiave],
                                    lazy: ""
                                  },
                                  on: {
                                    "update:returnValue": function($event) {
                                      return _vm.$set(
                                        props.item,
                                        chiave,
                                        $event
                                      )
                                    },
                                    "update:return-value": function($event) {
                                      return _vm.$set(
                                        props.item,
                                        chiave,
                                        $event
                                      )
                                    },
                                    save: function($event) {
                                      return _vm.save(props.item)
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "input",
                                        fn: function() {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Edit",
                                                "single-line": "",
                                                counter: "",
                                                type: "number",
                                                max: "1000"
                                              },
                                              model: {
                                                value: props.item[chiave],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    chiave,
                                                    $$v
                                                  )
                                                },
                                                expression: "props.item[chiave]"
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.item[chiave]) +
                                      "\n                        "
                                  )
                                ]
                              )
                            : _c(
                                "span",
                                { domProps: { innerHTML: _vm._s(valore) } },
                                [_vm._v(_vm._s(valore))]
                              )
                        ],
                        1
                      )
                    })
                  }
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _vm.link_new !== ""
                        ? _c(
                            "td",
                            { attrs: { colspan: _vm.headers.length } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-block btn-green",
                                  attrs: { to: _vm.link_new }
                                },
                                [
                                  _vm._v(
                                    "\n                        New Resources\n                    "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2724735808
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "650" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "red lighten-3",
                      attrs: {
                        color: "transparent",
                        flat: "",
                        dense: "",
                        card: ""
                      }
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "subheading ft-200" },
                            [_vm._v(_vm._s(_vm.dialog_delete.title))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.dialog_delete.message) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "indifo darken-1", flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.cancelDelete()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.dialog_delete.cancel))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red darken-1", flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.continueToDelete()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.dialog_delete.continue))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }