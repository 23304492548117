var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticStyle: { background: "transparent !important" },
      attrs: { height: "auto" }
    },
    [
      _c(
        "v-container",
        {
          staticStyle: {
            padding: "0px!important",
            margin: "0px!important",
            background: "transparent!important"
          },
          attrs: { fluid: "" }
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pl-5 pr-5",
                      staticStyle: { background: "transparent!important" },
                      attrs: { fluid: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-divider", {
                                staticClass: "wrap-madi-dividerFooter"
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-flex", {
                            attrs: { sm4: "", "hidde-xs-only": "" }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm8: "", xs12: "" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "elevation-0",
                                  staticStyle: {
                                    background: "transparent !important"
                                  }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c("v-flex", {
                                            attrs: {
                                              sm6: "",
                                              "hidde-xs-only": ""
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { sm6: "", xs12: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" }
                                                },
                                                [
                                                  _vm._l(_vm.images, function(
                                                    image
                                                  ) {
                                                    return [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs4: "" } },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.naviga(
                                                                    image.link
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                style:
                                                                  "width:" +
                                                                  image.width +
                                                                  ";margin: 0 35%;",
                                                                attrs: {
                                                                  src:
                                                                    image.image,
                                                                  contain: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  })
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.showFooter
                                            ? _c(
                                                "v-flex",
                                                {
                                                  staticClass: "text-xs-right",
                                                  attrs: { xs12: "" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "subheading"
                                                    },
                                                    [
                                                      _c("i", [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticStyle: {
                                                              "text-decoration":
                                                                "none"
                                                            },
                                                            attrs: {
                                                              href:
                                                                "https://site.imsglobal.org/certifications/reiss-romoli-srl/my-open-badge#cert_pane_nid_177951",
                                                              target: "_blank"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tMY OPEN BADGE is an IMS Global Certified Open Badge Issuer, Displayer & Host\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", color: "grey darken-1" } },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    grey: "",
                    "darken-3": "",
                    "py-3": "",
                    "text-xs-left": "",
                    "white--text": "",
                    "6": "",
                    "pl-5": ""
                  }
                },
                [
                  _vm._v("\n\t\t\t\t©2022 — "),
                  _c("strong", [_vm._v("MY OPEN BADGE")]),
                  _vm._v(" Powered by Reiss Romoli srl\n\t\t\t")
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }