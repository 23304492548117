//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "SubHeaderSearch",
  props: ['title', 'description'],
  data: function data() {
    return {
      search: '',
      titolo: '',
      desc: '',
      isVideoMode: false
    };
  },
  mounted: function mounted() {
    this.titolo = this.$options.propsData.title;
    this.desc = this.$options.propsData.description;
  },
  methods: {
    StartSearch: function StartSearch() {
      this.$router.push('/search/' + this.search);
    }
  }
};