var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("userSidebar", { attrs: { ready: _vm.isVideoMode } }),
      _vm._v(" "),
      _vm.ready
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 transparent" },
                    [
                      _c("PostListCard", {
                        attrs: {
                          items: _vm.cardsPages,
                          title: _vm.$t_("FE_UTENTE_PAGINA_TITOLO_CARD"),
                          enableFilter: true
                        }
                      }),
                      _vm._v(" "),
                      _vm.canAddPage
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "blue",
                                absolute: "",
                                dark: "",
                                fab: "",
                                bottom: "",
                                right: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    "/backpack/user-pages/new"
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  "\n                        add\n                    "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }