//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "NavigationInCards",
  props: ["items", "image", "titolo"],
  methods: {
    gotoSection: function gotoSection(item) {
      if (document.getElementsByClassName(item)[0].offsetTop < 100) window.scroll(0, 0);else window.scroll(0, document.getElementsByClassName(item)[0].offsetTop);
    },
    getNumber: function getNumber(item) {
      if (parseInt(item) < 1) return '01';else if (parseInt(item) < 10) return '0' + (item + 1);else return item;
    }
  },
  data: function data() {
    return {
      drawer: true,
      mini: true,
      right: null
    };
  }
};