//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "AziedaIssuer",
  props: ["azienda"]
};