//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import VFormBase from '../../../../node_modules/vuetify-form-base';
import images from '../../../app/components/amministrazione/Images';
export default {
  name: "Backpack",
  components: {
    VFormBase: VFormBase,
    images: images
  },
  data: function data() {
    return {
      can_show: 'default',
      drawer: true,
      showLoader: false,
      newPassword: '',
      confirmNewPassword: '',
      currentPassword: '',
      items: [{
        title: this.$t_('FE_USER_SETTINGS'),
        icon: 'dashboard',
        separator: false,
        headline: false,
        url: '/backpack/user'
      }, {
        title: this.$t_('FE_USER_PAGINA_PERSONALE'),
        icon: 'question_answer',
        separator: false,
        headline: false,
        url: '/backpack/personal-page'
      }, {
        title: this.$t_('FE_USER_EMAIL_ADD'),
        icon: 'alternate_email',
        separator: false,
        headline: false,
        url: '/backpack/user-emails'
      }, {
        title: this.$t_('FE_USER_PAGES_ADD_VIEW'),
        icon: 'pages',
        separator: false,
        headline: false,
        url: '/backpack/user-pages'
      }, {
        separator: true,
        headline: false
      }, {
        separator: false,
        headline: true,
        title: 'Badges'
      }, {
        separator: false,
        headline: false,
        title: this.$t_('FE_USER_MYBADGES'),
        icon: 'image_search',
        url: ''
      }],
      mini: true,
      right: null,
      userInfo: {},
      badgeUser: [],
      value: [],
      schema: [],
      immagine: {},
      isVideoMode: false,
      valid: false,
      permissionView: null
    };
  },
  mounted: function mounted() {
    this.getBadgeUser();
    this.getDataUser();
  },
  watch: {
    permissionView: {
      handler: function handler() {
        switch (this.permissionView) {
          case '0':
            this.userInfo.config.profile_public = 0;
            this.userInfo.config.show_only_auth_user = 0;
            break;

          case '1':
            this.userInfo.config.profile_public = 1;
            this.userInfo.config.show_only_auth_user = 1;
            break;

          case '2':
            this.userInfo.config.profile_public = 0;
            this.userInfo.config.show_only_auth_user = 1;
            break;
        }
      }
    }
  },
  methods: {
    evInitial: function evInitial(el) {
      this.can_show = el;
    },
    getDataUser: function getDataUser() {
      var _this = this;

      var self = this;
      var call = true;
      var lastd = this.$localStorage.get('lastDown');

      if (lastd !== undefined && lastd !== false && lastd !== null) {
        if (Math.round(Date.now() / 1000) - lastd < 300) call = false;
      }

      if (call) {
        axios.get('/user/VueBackpack').then(function (response) {
          if (response.data.obj !== undefined && response.data.obj.backStore !== undefined) {
            _this.$localStorage.set('backpack', response.data.obj.backStore);

            _this.$localStorage.set('lastDown', Math.round(Date.now() / 1000));
          }
        });
      }
    },
    SendData: function SendData() {
      var self = this;
      this.showLoader = true;
      axios({
        method: 'post',
        url: '/user/aggiornaDatiUtente',
        data: {
          all: this.userInfo,
          image: this.immagine,
          data: this.value
        }
      }).then(function (response) {
        self.userInfo = response.data.obj.all;
        self.$root.userInfo = self.userInfo; //self.schema=response.data.obj.new_model.schema;

        self.value = response.data.obj.new_model.values;
        self.schema = self.$getRulesInput(response.data.obj.new_model.schema);
        self.immagine = response.data.obj.image;
        self.findPermission();

        if (self.$cookie.get('appLang') !== self.userInfo.myLang) {
          window.location.reload();
        }

        self.isVideoMode = true;
        self.showLoader = false;
      });
    },
    getBadgeUser: function getBadgeUser() {
      var self = this;
      axios.get('/user/get_Vueprofile').then(function (response) {
        self.userInfo = response.data.obj.all;
        self.$root.userInfo = self.userInfo; //self.schema=response.data.obj.new_model.schema;

        self.value = response.data.obj.new_model.values;
        self.schema = self.$getRulesInput(response.data.obj.new_model.schema);
        self.immagine = response.data.obj.image;
        self.findPermission();
        self.isVideoMode = true;
        if (self.$root.options !== undefined && self.$root.options.can_show !== undefined) self.can_show = self.$root.options.can_show;
      });
    },
    findPermission: function findPermission() {
      if (this.userInfo.config.profile_public === "0" && this.userInfo.config.show_only_auth_user === "0") this.permissionView = "0";else if (this.userInfo.config.profile_public === "1" && this.userInfo.config.show_only_auth_user === "0") this.permissionView = "1";else this.permissionView = "2";
    },
    update: function update(_ref) {
      var on = _ref.on,
          id = _ref.id,
          key = _ref.key,
          value = _ref.value,
          obj = _ref.obj,
          event = _ref.event,
          params = _ref.params,
          size = _ref.size,
          data = _ref.data,
          schema = _ref.schema;
      var self = this; //console.log(self.all_images);
    },
    resetPasswordData: function resetPasswordData() {
      var self = this;

      if (this.newPassword !== '' && this.confirmNewPassword !== '' && this.currentPassword !== '') {
        if (this.currentPassword !== this.newPassword && this.newPassword === this.confirmNewPassword) {
          axios({
            method: 'post',
            url: '/auth/newResetPassword/' + (Math.random() + 1).toString(36),
            data: {
              "new": this.newPassword,
              currentPassword: this.currentPassword
            }
          }).then(function (response) {
            // console.log(response.data.obj);
            self.$swal(response.data.obj.message);
          });
        }
      }
    }
  }
};