var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 85,
            "offset-x": ""
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "elevation-0",
                          attrs: { color: "transparent" }
                        },
                        on
                      ),
                      [
                        _c("img", {
                          staticStyle: {
                            "max-height": "25px",
                            "max-width": "25px"
                          },
                          attrs: {
                            src: _vm.$immaginiNazione(_vm.currentLang),
                            alt: _vm.currentLang
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "pl-3" }, [
                          _vm._v(
                            _vm._s(_vm.$traduzioneNazione(_vm.currentLang)) +
                              "\n\t\t\t\t"
                          )
                        ])
                      ]
                    )
                  ]
                }
              }
            ],
            null,
            false,
            855504929
          ),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-list",
                _vm._l(_vm.$options.propsData.lingue, function(item) {
                  return item !== _vm.currentLang
                    ? _c(
                        "v-list-tile",
                        {
                          key: item,
                          attrs: { avatar: "" },
                          on: {
                            click: function($event) {
                              return _vm.setThisLang(item)
                            }
                          }
                        },
                        [
                          [
                            _c("v-list-tile-avatar", [
                              _c("img", {
                                staticStyle: {
                                  "max-height": "25px",
                                  "max-width": "25px"
                                },
                                attrs: {
                                  src: _vm.$immaginiNazione(item),
                                  alt: item
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-title", [
                                  _vm._v(_vm._s(_vm.$traduzioneNazione(item)))
                                ])
                              ],
                              1
                            )
                          ]
                        ],
                        2
                      )
                    : _vm._e()
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }