//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BadgeDetails from '../../components/BadgeDetails';
import ValidatorResponse from '../../components/ValidatorResponse';
export default {
  name: "ReceiveBadge",
  props: ['badge', 'userurl'],
  components: {
    'BadgeDetails': BadgeDetails,
    'ValidatorResponse': ValidatorResponse
  },
  data: function data() {
    return {
      assertion: {},
      user: {},
      badges: {},
      validator: {},
      isVideoMode: false
    };
  },
  computed: {
    canShowBadge: function canShowBadge() {
      var show = true;
      if (parseInt(this.assertion.show_public) === 0) show = false;

      if (parseInt(this.assertion.only_auth) === 1) {
        if (this.$root.logged) {
          show = true;
        }
      }

      return show;
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var self = this;
      axios.get('/receive/' + this.$options.propsData.badge + '/' + this.$options.propsData.userurl + '/public').then(function (response) {
        if (response.data.obj.empty) {//errore
        } else {
          self.assertion = response.data.obj.data;
          self.user = response.data.obj.user;
          self.badges = response.data.obj.badge;
          self.validator = response.data.obj.validator;
          self.$root.newData = self.validator[0].validation;
          self.$root.callFrom = 'pageBadge';
          self.isVideoMode = true;
        }
      });
    },
    getDimension: function getDimension() {
      if (this.assertion.is_mob_object !== '1') {
        return true;
      } else return false;
    }
  }
};