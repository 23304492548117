//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "TakeBadge",
  props: ["guid_badge"],
  data: function data() {
    return {
      isVideoMode: false,
      badgeTake: {},
      login: true,
      register: true,
      download: true,
      buttons: [{
        name: 'Login',
        view: true,
        color: 'blue',
        url: '/login',
        skip: true
      }, {
        name: 'LABEL_REGISTRATI_UTENTE_IN_LOGIN',
        view: true,
        color: 'cyan',
        url: '/register',
        skip: false
      }, {
        name: 'TAKE_BADGE_LABEL_DOWNLOAD_BADGE',
        view: true,
        color: 'orange',
        url: 'takeBadge',
        skip: false
      }]
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      console.log(this.$options.propsData);
      var self = this;
      axios.get('/issuing/BadgeIssuing/' + this.$options.propsData.guid_badge).then(function (response) {
        self.badgeTake = response.data.obj;

        if (self.$root.logged !== undefined && self.$root.logged) {
          self.buttons[0].view = false;
          self.buttons[1].view = false;
        }

        for (var i in self.buttons) {
          if (!self.buttons[i].skip) {
            self.buttons[i].name = self.$t_(self.buttons[i].name);
          }
        }

        self.waiteIIII();
      });
    },
    waiteIIII: function waiteIIII() {
      var self = this;
      setTimeout(function () {
        self.isVideoMode = true;
      }, 1300);
    },
    routePush: function routePush(item) {
      if (item.url === 'takeBadge') {
        window.open('/badge/download/' + this.badgeTake.guid_badge);
      } else {
        this.$router.push(item.url);
      }
    }
  }
};