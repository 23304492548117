//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GlobalCards from '../components/GlobalCards';
import { VueSimpleMarkdown } from 'vue-simple-markdown';
export default {
  name: "BadgeDetails",
  props: ['myurl'],
  components: {
    "GlobalCards": GlobalCards,
    'vue-simple-markdown': VueSimpleMarkdown
  },
  data: function data() {
    return {
      badge: {},
      isVideoMode: false,
      activeMenu: false,
      showSubBar: false,
      myScroll: 0
    };
  },
  created: function created() {
    this.getData();
  },
  mounted: function mounted() {//console.log(window.scrollY);
  },
  computed: {
    Calcprogetti: {
      get: function get() {
        var my = [];

        for (var i in this.badge.prj) {
          if (this.badge.prj[i].cards !== undefined && this.badge.prj[i].cards[0] !== undefined) my.push(this.badge.prj[i].cards[0]);
        }

        return my;
      },
      set: function set() {}
    },
    getItemSubBar: {
      get: function get() {
        //section-name
        var my = [{
          name: this.$t_('SUB_HEADER_BADGE'),
          section: 'badge'
        }, {
          name: this.$t_('SUB_HEADER_CRITERI'),
          section: 'criteri'
        }, {
          name: this.$t_('SUB_HEADER_AZIENDA'),
          section: 'issuer'
        }];
        if (this.badge.endorseObj.length > 0) my.push({
          name: this.$t_('FE_LABEL_ENDORSEMENT_CARDS'),
          section: 'endorsement'
        });
        if (this.Calcprogetti.length > 0) my.push({
          name: this.$t_('SUB_HEADER_PROGETTI'),
          section: 'progetti'
        });
        return my;
      },
      set: function set() {}
    }
  },
  methods: {
    forceDownload: function forceDownload(guid) {
      window.open('/utils/download/' + guid, '_blank');
    },
    onScroll: function onScroll(e) {
      // console.log(e.target.scrollTop)
      this.myScroll = window.scrollY;

      if (this.myScroll >= 100) {
        this.showSubBar = true;
      } else {
        this.showSubBar = false;
      }
    },
    getData: function getData() {
      // /this.badge=this.$getData(this.$options.propsData.myurl);
      var self = this;
      axios.get(this.$options.propsData.myurl).then(function (response) {
        self.badge = response.data.obj.badge; //console.log(response.data.obj.badge);

        self.isVideoMode = true;
      });
    },
    haveEndorser: function haveEndorser() {
      if (parseInt(this.badge.endorsement) > 0) return true;else return false;
    },
    PushThis: function PushThis(item) {
      this.$router.push('/search/' + item);
    }
  }
};