//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "TakeNewBadgeOrgs",
  props: ["guid_badge"],
  data: function data() {
    return {
      isVideoMode: false,
      badgeTake: {},
      login: true,
      register: true,
      download: true,
      buttons: [{
        name: 'Login',
        view: true,
        color: 'blue',
        url: '/login',
        skip: true
      }, {
        name: 'LABEL_REGISTRATI_UTENTE_IN_LOGIN',
        view: true,
        color: 'cyan',
        url: '/register',
        skip: false
      }, {
        name: 'TAKE_BADGE_LABEL_DOWNLOAD_BADGE',
        view: true,
        color: 'orange',
        url: 'takeBadge',
        skip: false
      }],
      sharing: {
        url: 'https://news.vuejs.org/issues/180',
        title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
        description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: 'The hot reload is so fast it\'s near instant. - Evan You',
        hashtags: 'vuejs,vite,javascript' //twitterUser: 'youyuxi'

      },
      networks: [{
        network: 'email',
        name: 'Email',
        icon: 'far fah fa-lg fa-envelope',
        color: '#333333'
      }, //{ network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
      {
        network: 'facebook',
        name: 'Facebook',
        icon: 'fab fah fa-lg fa-facebook-f',
        color: '#1877f2'
      }, //{ network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
      //{ network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
      //{ network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
      //{ network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
      {
        network: 'linkedin',
        name: 'LinkedIn',
        icon: 'fab fah fa-lg fa-linkedin',
        color: '#007bb5'
      }, //{ network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
      {
        network: 'pinterest',
        name: 'Pinterest',
        icon: 'fab fah fa-lg fa-pinterest',
        color: '#bd081c'
      }, //{ network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
      // network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
      //{ network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
      //{ network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
      //{ network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
      //{ network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
      {
        network: 'telegram',
        name: 'Telegram',
        icon: 'fab fah fa-lg fa-telegram-plane',
        color: '#0088cc'
      }, //{ network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
      {
        network: 'twitter',
        name: 'Twitter',
        icon: 'fab fah fa-lg fa-twitter',
        color: '#1da1f2'
      }, {
        network: 'viber',
        name: 'Viber',
        icon: 'fab fah fa-lg fa-viber',
        color: '#59267c'
      }, //{ network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
      //{ network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
      {
        network: 'whatsapp',
        name: 'Whatsapp',
        icon: 'fab fah fa-lg fa-whatsapp',
        color: '#25d366'
      }]
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    actionsUser: function actionsUser(network) {
      console.log({
        net: network,
        badge: this.$options.propsData.guid_badge
      });
      axios({
        method: 'post',
        url: '/huge/statsBackpack',
        data: {
          net: network.network,
          badge: this.$options.propsData.guid_badge
        }
      }).then(function (response) {})["catch"](function (error) {});
    },
    getData: function getData() {
      console.log(this.$options.propsData);
      var self = this;
      axios.get('/issuing/BadgeIssuingOrgsCheck/' + this.$options.propsData.guid_badge).then(function (response) {
        self.badgeTake = response.data.obj;
        self.sharing = response.data.obj.share;

        if (self.$root.logged !== undefined && self.$root.logged) {
          self.buttons[0].view = false;
          self.buttons[1].view = false;
        }

        for (var i in self.buttons) {
          if (!self.buttons[i].skip) {
            self.buttons[i].name = self.$t_(self.buttons[i].name);
          }
        }

        self.waiteIIII();
      });
    },
    waiteIIII: function waiteIIII() {
      var self = this;
      setTimeout(function () {
        self.isVideoMode = true;
      }, 1300);
    },
    routePush: function routePush(item) {
      if (item.url === 'takeBadge') {
        window.open('/badge/download/' + this.badgeTake.guid_badge);
      } else {
        this.$router.push(item.url);
      }
    }
  }
};