var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { flui: "" } },
    [
      _c("userSidebar", { attrs: { ready: true } }),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { red: "", block: "" },
              on: {
                click: function($event) {
                  return _vm.delAll()
                }
              }
            },
            [_vm._v("\n\t\t\tDelete All\n\t\t")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("vuefydatatable", { attrs: { url: _vm.url } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }