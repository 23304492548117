//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PostListCard from '../../../../app/components/utils/widgets/card/PostListCard';
export default {
  name: "PagesList",
  components: {
    PostListCard: PostListCard
  },
  data: function data() {
    return {
      isVideoMode: true,
      pagine: [],
      modello: {},
      ready: false,
      cardsPages: [],
      canAddPage: true
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var self = this;
      axios.get('/user/getMyPagesVuew').then(function (response) {
        self.pagine = response.data.obj.page;
        self.modello = response.data.obj.model;
        self.createCards();
      });
    },
    createCards: function createCards() {
      var obj = {};

      if (this.$root.profile.first_name === undefined) {
        this.$router.push('/mob/backpack');
      }

      for (var i in this.pagine) {
        obj = {};
        obj.image = this.getImageCover(this.pagine[i].coverCard);
        obj.header = this.pagine[i].c_name;
        obj.title = this.pagine[i].c_name;
        obj.guid = this.pagine[i].guid;
        obj.url = '/backpack/user-pages/' + this.pagine[i].guid;
        obj.desc = 'Page url: https://app.myopenbadge.com/page/' + this.pagine[i].guid;
        obj.author = this.$t_('FE_UTENTE_PAGINA_CREATA_DA') + ' ' + this.$root.profile.first_name + ' ' + this.$root.profile.last_name;
        obj.createdAt = '@ ' + this.pagine[i].created_on;
        obj.icon = '';
        obj.enableLink = true;
        obj.lang = this.pagine[i].lang;
        obj.uno = this.accessType(this.pagine[i].property.access);
        obj.due = this.$t_('FE_UTENTE_PAGINA_ABILITATA_DAL') + ' ' + this.pagine[i].property.enable_from;
        obj.tre = this.$t_('FE_UTENTE_PAGINE_DISABILITATA_AL') + ' ' + this.pagine[i].property.enable_to;
        this.cardsPages.push(obj);
      }

      this.checkAddPAges();
      this.ready = true;
    },
    checkAddPAges: function checkAddPAges() {
      if (this.cardsPages.length < 8) this.canAddPage = true;else this.canAddPage = false;
    },
    accessType: function accessType(type) {
      if (type === 'access_public') return this.$t_('FE_ACCESSO_PAGINA_PUBBLICO');else return '';
    },
    getImageCover: function getImageCover(image) {
      if (image === '' || image === null) {
        return '/utils/dummy/400/400';
      } else return image;
    }
  }
};