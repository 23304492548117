//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import VFormBase from '../../../../../node_modules/vuetify-form-base';
import images from '../../../../app/components/amministrazione/Images';
import { VueEditor } from "vue2-editor";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import draggable from 'vuedraggable';
export default {
  name: "PagineEditNew",
  props: ['pagina'],
  components: {
    VFormBase: VFormBase,
    images: images,
    draggable: draggable,
    VueEditor: VueEditor,
    vueDropzone: vue2Dropzone
  },
  data: function data() {
    return {
      showSeparatore: false,
      deleteDialog: false,
      deleteDialogCard: {
        title: this.$t_('MSG_DELETE_RESOURCE_TITOLO_PAGINA_UTENTE'),
        message: this.$t_('MSG_DELETE_RESOURCE_MESSAGGIO_PAGINA_UTENTE'),
        cancel: false,
        "continue": false
      },
      collapse: [],
      loader: false,
      fab: true,
      dialog: false,
      isVideoMode: false,
      currentPage: '',
      currentLang: 'ITA',
      image: {},
      badges: [],
      modello: {},
      immagine: {},
      all: {},
      valid: false,
      tipologieAdd: ['badge', 'badge_group', 'title', 'header', 'attachment', 'text', 'cluster_tags'],
      viewBadge: ['full', 'compact', 'image'],
      viewBadgeGroup: ['full', 'compact', 'image', 'list', 'grid'],
      schemaBadge: {
        badge: {
          id: '',
          type_view: ['only_image', 'min_desc', 'full_desc']
        },
        badge_group: {
          tags: '',
          type_view: ['time_line', 'group_image', '']
        },
        title: {
          text: ''
        },
        header: {
          text: ''
        },
        attachment: {
          items: []
        },
        text: {
          text: ''
        }
      },
      dropzoneOptions: {
        url: '/user/VueattachmentFile',
        thumbnailWidth: 150,
        maxFilesize: 2,
        maxFiles: 10,
        //acceptedFiles:'.',
        addRemoveLinks: false
      },
      dropzoneOptionsOneFile: {
        url: '/user/VueattachmentFile',
        thumbnailWidth: 150,
        maxFilesize: 5,
        maxFiles: 1,
        acceptedFiles: 'image/*,.pdf',
        addRemoveLinks: false
      },
      addBadge: true,
      addGroupBadge: true,
      addBiografy: true,
      addNote: true,
      addAtch: true,
      addEditorCheck: true,
      object: [],
      currentIndex: 0,
      typeAction: 'new',
      sfondi: ['/public/images/images_mob/sfondi/cubo.jpg', '/public/images/images_mob/sfondi/mattoni.jpg', '/public/images/images_mob/sfondi/wood.jpg', '/public/images/images_mob/sfondi/musica.jpg'],
      separatori: ['/public/images/images_mob/separatori/separatoreDEMO.png'],
      currentSeparatore: '',
      currentSfondo: '',
      clusters: [],
      selected_cluster: null,
      rowsPerPageItems: [4, 8, 12],
      pagination: {
        rowsPerPage: 4
      }
    };
  },
  watch: {
    currentIndex: {
      handler: function handler() {
        console.log(this.currentIndex);
      }
    },
    object: {
      handler: function handler() {
        console.log(this.object);
      }
    }
  },
  mounted: function mounted() {
    this.currentPage = this.$options.propsData.pagina;
    this.getData();
  },
  computed: {
    badgeList: {
      get: function get() {
        var my = [];
        my = _.chunk(this.badges, 5);
        return {
          badge: my[this.object[this.currentIndex].page - 1],
          pages: my.length
        };
      },
      set: function set() {}
    }
  },
  methods: {
    getPaginationFiltered: function getPaginationFiltered(item) {
      var my = [];
      my = _.chunk(item, 5);
      return {
        badge: my[this.object[this.currentIndex].page - 1],
        pages: my.length
      };
    },
    openNewNavTab: function openNewNavTab() {
      window.open('https://app.myopenbadge.com/page/' + this.all.page.guid, '_blank');
    },
    getItemTitle: function getItemTitle(item) {
      switch (item) {
        case 'text':
          return this.$t_('FE_UTENTE_PAGINA_TITOLO_CARD_EDITOR');
          break;

        case 'badge':
          return this.$t_('FE_UTENTE_PAGINA_TITOLO_CARD_BADGE');
          break;

        case 'badgeGroup':
          return this.$t_('FE_UTENTE_PAGINA_TITOLO_CARD_GROUP_BADGE');
          break;

        case 'image':
          return this.$t_('FE_UTENTE_PAGINA_TITOLO_CARD_IMMAGINE');
          break;

        case 'attachment':
          return this.$t_('FE_UTENTE_PAGINA_TITOLO_CARD_ATTACHMENT');
          break;

        case 'biografy':
          return this.$t_('FE_UTENTE_PAGINA_TITOLO_CARD_BIOGRAFY');
          break;

        case 'note':
          return this.$t_('FE_UTENTE_PAGINA_TITOLO_CARD_NOTES');
          break;

        case 'cluster_tags':
          return this.$t_('FE_UTENTE_AGGIUNGI_CLUSTER_IN_PAGINA');
          break;

        default:
          return 'NULL';
      }
    },
    sendData: function sendData() {
      var self = this;
      self.loader = true;
      axios({
        method: 'post',
        url: '/user/savePage',
        data: {
          object: this.object,
          image: this.immagine,
          dati: this.modello.values,
          separatore: this.currentSeparatore,
          sfondo: this.currentSfondo
        }
      }).then(function (response) {
        if (response.data.obj.action === 'insert') {
          self.$router.push('/backpack/user-pages/' + response.data.obj.redirect);
        } else {}

        self.loader = false;
      });
    },
    setSfondo: function setSfondo(item) {
      this.currentSfondo = item;
    },
    getColor: function getColor(item) {
      if (this.currentSfondo !== '') {
        if (this.currentSfondo === item) {
          return 'blue';
        }
      } else return 'grey';
    },
    setSeparatore: function setSeparatore(item) {
      this.currentSeparatore = item;
    },
    getData: function getData() {
      var self = this;
      axios.get('/user/get_VuePageRequest/' + this.currentPage).then(function (response) {
        self.modello = response.data.obj.new_model;
        self.modello.schema = self.$getRulesInput(self.modello.schema);
        self.badges = response.data.obj.badge;
        self.all = response.data.obj;
        self.immagine = response.data.obj.image;
        self.isVideoMode = true;
        self.typeAction = response.data.obj.type;
        self.clusters = response.data.obj.clusters;
        self.setAllData();
      });
    },
    setAllData: function setAllData() {
      this.canViewBadgeGroups();

      if (this.typeAction === 'up') {
        this.object = this.all.page.object_to_compile.object;
        this.currentSeparatore = this.all.page.object_to_compile.separatore;
        this.currentSfondo = this.all.page.object_to_compile.sfondo;
      }

      for (var i in this.clusters) {
        this.clusters[i].selected = false;
      }
    },
    canViewBadgeGroups: function canViewBadgeGroups() {
      if (this.all.tags.length === 0) this.addGroupBadge = false;
    },
    update: function update(_ref) {
      var on = _ref.on,
          id = _ref.id,
          key = _ref.key,
          value = _ref.value,
          obj = _ref.obj,
          event = _ref.event,
          params = _ref.params,
          size = _ref.size,
          data = _ref.data,
          schema = _ref.schema;
      var self = this; //console.log(self.all_images);
    },
    getClassCard: function getClassCard(val) {
      if (val) return 'blue';else return 'red';
    },
    getUrlFile: function getUrlFile(item) {
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

      if (!regex.test(item)) {
        return '/' + item;
      } else {
        return item;
      }
    },
    setThis: function setThis(elem, index) {
      this.object[index].id = elem;
      this.object[index].items = [];

      for (var i in this.badges) {
        if (parseInt(this.badges[i].id) === parseInt(elem)) {
          this.object[index].items = [this.badges[i]];
        }
      }
    },
    getImageExsists: function getImageExsists(index) {
      for (var i in this.badges) {
        if (this.badges[i].id === this.object[index].id) return this.getUrlFile(this.badges[i].image);
      }
    },
    removeElement: function removeElement(index) {
      this.object.splice(index, 1);
      var bio = true;
      var note = true;

      for (var i in this.object) {
        if (this.object[i].type === 'biografy') bio = false;
        if (this.object[i].type === 'note') note = false;
      }

      if (bio) this.addBiografy = true;
      if (note) this.addNote = true;
    },
    salvaContenuto: function salvaContenuto() {
      console.log(this.object);
      this.dialog = false;
    },
    sendingEvent: function sendingEvent(file, xhr, formData) {
      formData.append('index', this.currentIndex);
    },
    getResponse: function getResponse(i, a) {
      //this.loadData=a.obj;
      //this.header=Object.keys(this.loadData[0]);
      //this.isData=true;
      this.object[this.currentIndex].items.push(a.obj[0]); //console.log(a);
    },
    FiltraBadge: function FiltraBadge(item, index) {
      var my = [];
      this.object[index].items = [];

      for (var i in this.badges) {
        var test1 = this.$intersezione(this.badges[i].my_tags, [item.tag]);

        if (test1.length > 0) {
          this.object[index].items.push(this.badges[i]);
          my.push(this.badges[i]);
        }
      }

      return my;
    },
    addCurrentIndex: function addCurrentIndex() {
      this.currentIndex += 1;
      this.collapse.push(true);
    },
    addEditor: function addEditor() {
      this.addCurrentIndex();
      this.object.push({
        text: '',
        type: 'text'
      });
    },
    addImmagine: function addImmagine() {
      this.addCurrentIndex();
      this.object.push({
        type: 'image',
        items: []
      });
    },
    AddBadge: function AddBadge() {
      this.addCurrentIndex();
      this.object.push({
        text: '',
        type: 'badge',
        id: 0,
        view: 'compact',
        page: 1,
        cBadges: this.badges
      });
    },
    AddBadgeGroups: function AddBadgeGroups() {
      this.addCurrentIndex();
      this.object.push({
        text: '',
        type: 'badgeGroup',
        tag: '',
        items: [],
        view: 'image',
        page: 1
      });
    },
    Attachment: function Attachment() {
      this.addCurrentIndex();
      this.object.push({
        type: 'attachment',
        items: []
      });
    },
    AddBio: function AddBio() {
      this.addCurrentIndex();
      this.object.push({
        text: this.$root.$root.profile.config.biography,
        type: 'biografy'
      });
      this.addBiografy = false;
    },
    AddNotes: function AddNotes() {
      this.addCurrentIndex();
      this.object.push({
        text: this.$root.$root.profile.config.add_notes,
        type: 'note'
      });
      this.addNote = false;
    },
    AddCluster: function AddCluster() {
      this.addCurrentIndex();
      this.object.push({
        text: '',
        type: 'cluster_tags',
        id: -1,
        view: 'compact',
        page: 1,
        cluster: this.clusters
      });
    },
    setCurrentCluster: function setCurrentCluster(index) {},
    removeItemsAttachment: function removeItemsAttachment(index, indice) {
      this.object[index].items.splice(indice, 1);
    },
    CancellaPagina: function CancellaPagina() {
      var self = this;
      axios({
        method: 'post',
        url: '/user/deletePage',
        data: {
          guid: this.all.page.guid
        }
      }).then(function (response) {
        self.deleteDialog = false;
        self.$router.push('/backpack/user-pages');
      });
    }
  }
};