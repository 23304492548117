var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "container fluid" },
    [
      _vm.filterIncomponent
        ? [
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-flex",
                  { staticClass: "pt-0 pb-1 text-center" },
                  [
                    _c(
                      "v-card",
                      { staticClass: "elevation-0 madi-nocolor" },
                      [
                        _c(
                          "v-card-text",
                          {
                            staticClass:
                              "display-2 text-center text-md-center font-weight-black madiLSpacing"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.$t_(_vm.subheaderObject.titolo)) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-card-text",
                          { staticClass: "text-md-center" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "mb-4 mt-2",
                                    attrs: {
                                      sm6: "",
                                      xs8: "",
                                      "offset-sm3": "",
                                      "offset-xs-2": "",
                                      "mb-4": "",
                                      "mt-2": ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t_(_vm.subheaderObject.desc)
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      sm8: "",
                                      xs12: "",
                                      "offset-sm2": ""
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass:
                                        "madi-button-search elevation-0",
                                      attrs: {
                                        clearable: "",
                                        label: "Search",
                                        type: "text",
                                        solo: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-fade-transition",
                                                  {
                                                    attrs: {
                                                      "leave-absolute": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "blue madiButtonSearch",
                                                        attrs: {
                                                          flat: "",
                                                          icon: "",
                                                          color: "white"
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("search")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        1097073998
                                      ),
                                      model: {
                                        value: _vm.search,
                                        callback: function($$v) {
                                          _vm.search = $$v
                                        },
                                        expression: "search"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm.subheader
        ? _c("SubHeaderSearch", {
            attrs: {
              title: _vm.subheaderObject.titolo,
              description: _vm.subheaderObject.desc
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.progress
        ? _c("v-progress-linear", { attrs: { indeterminate: true } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVideoMode
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm._l(_vm.listItem, function(item) {
                return [
                  _c(
                    "v-flex",
                    { class: _vm.dim },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-4 pa-2 elevation-0" },
                        [
                          !_vm.isCover()
                            ? _c(
                                "v-card-title",
                                [
                                  _c("vuetify-lazy-image", {
                                    class: _vm.type,
                                    style: _vm.getStyleImage(),
                                    attrs: {
                                      src: item.image,
                                      contain: _vm.contain
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("vuetify-lazy-image", {
                                class: _vm.type,
                                style: _vm.getStyleImage(),
                                attrs: { src: item.image, cover: "" }
                              }),
                          _vm._v(" "),
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "elevation-0 white",
                              staticStyle: {
                                height: "33px",
                                background: "transparent !important"
                              }
                            },
                            [
                              _c(
                                "v-toolbar-items",
                                {
                                  staticClass: "indigo--text",
                                  staticStyle: {
                                    position: "absolute",
                                    "padding-top": "15px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.header) +
                                      "\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-toolbar-items",
                                {
                                  staticStyle: {
                                    float: "right",
                                    "padding-left": "80%"
                                  }
                                },
                                [
                                  _c("m-hex-btn", {
                                    key: item.guid,
                                    staticStyle: { float: "right" },
                                    attrs: {
                                      margin: "",
                                      icon: "arrow_forward",
                                      link: _vm.createLink(item.guid),
                                      object: _vm.object
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _vm.haveTitle(item)
                            ? _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "grey--text pt-4 pb-4 body-2 madi-style-ccard",
                                  staticStyle: {
                                    width: "92%",
                                    height: "65px !important"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.getEmit()) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "blue--text" }, [
                                    _vm._v(_vm._s(item.title))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type === "project" || _vm.type === "badge"
                            ? _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "title  pt-0 pb-5   font-weight-medium madi-style-ccard madi-ellips",
                                  style: _vm.getDimensions()
                                },
                                [
                                  _c("vue-simple-markdown", {
                                    staticClass: "minHIm",
                                    attrs: { source: item.name }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "title  pt-4 pb-5   font-weight-medium madi-style-ccard madi-ellips",
                                  style: _vm.getDimensions()
                                },
                                [
                                  _c("vue-simple-markdown", {
                                    attrs: { source: item.name }
                                  })
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _vm.haveTags(item)
                            ? _c(
                                "v-card-text",
                                { staticClass: "grey--text madi-ellips pb-5" },
                                [
                                  _vm._v(
                                    "\n                            Tags: "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "blue-grey--text" },
                                    [_vm._v(_vm._s(_vm.getTags(item.tags)))]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-divider", {
                            staticStyle: {
                              border: "dashed rgba(0,0,0,.12)",
                              "border-width": "thin 0 0"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "orange--text pl-2",
                                        attrs: { xs6: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(item.footer_sx) +
                                            "\n                                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "orange--text",
                                        staticStyle: { "text-align": "right" },
                                        attrs: { xs6: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(item.footer_dx) +
                                            "\n                                    "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.haveComment(item)
                            ? _c(
                                "v-slide-y-transition",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-divider", {
                                        staticClass: "wrap-madi-divider"
                                      }),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.comment) +
                                          "\n                            "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }),
              _vm._v(" "),
              !_vm.progress
                ? _c("div", {
                    directives: [
                      {
                        name: "observe-visibility",
                        rawName: "v-observe-visibility",
                        value: _vm.handleInfinityScroll,
                        expression: "handleInfinityScroll"
                      }
                    ]
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVideoMode && _vm.paginate
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "text-xs-center" },
                  [
                    _c("v-pagination", {
                      attrs: {
                        length: _vm.number(_vm.page.max),
                        color: "transparent",
                        "next-icon": "arrow_forward",
                        "prev-icon": "arrow_back"
                      },
                      model: {
                        value: _vm.getPage,
                        callback: function($$v) {
                          _vm.getPage = $$v
                        },
                        expression: "getPage"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVideoMode && _vm.loopInPages
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "text-xs-center" },
                  [
                    _c("v-pagination", {
                      attrs: {
                        length: _vm.currentLength,
                        color: "transparent",
                        "next-icon": "arrow_forward",
                        "prev-icon": "arrow_back"
                      },
                      model: {
                        value: _vm.currentPageLoop,
                        callback: function($$v) {
                          _vm.currentPageLoop = $$v
                        },
                        expression: "currentPageLoop"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }