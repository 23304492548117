function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
} //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*import wordcloud from 'vue-wordcloud'*/


import VueWordCloud from 'vuewordcloud';
export default {
  name: "ClusterTagsEditCreate",
  props: ["type"],
  components: _defineProperty({}, VueWordCloud.name, VueWordCloud),
  data: function data() {
    return {
      isVideoMode: false,
      tipo: 'new',
      data: {},
      model: {},
      action: 'insert',
      selected: [],
      showWordCloud: false,
      wCloud: [],
      tags: [],
      customMy: [],
      inArray: [],
      removed: [],
      wordsText: [],
      progress: undefined,
      progressVisible: false,
      animation: [{
        text: 'bounce',
        value: ['bounceIn', 'bounceOut']
      }],
      showSendButtons: true
    };
  },
  watch: {
    selected: {
      handler: function handler() {
        var temp = [];
        this.wCloud = [];
        this.inArray = [];

        for (var i in this.selected) {
          for (var j in this.customMy) {
            if (this.customMy[j].guid === this.selected[i]) {
              for (var z in this.customMy[j].items) {
                //this.wCloud.push({name:this.customMy[j].items[z],value:45,fontSize:25,});
                if (this.$intersezione(this.removed, [this.customMy[j].items[z].tag]).length === 0) {
                  if (this.$intersezione(this.inArray, [this.customMy[j].items[z].tag]).length === 0) {
                    this.wCloud.push({
                      text: this.customMy[j].items[z].tag,
                      name: this.customMy[j].items[z].tag,
                      is_my: this.customMy[j].items[z].is_my,
                      guid: this.customMy[j].guid,
                      value: z + 1,
                      weight: z + 1
                    });
                    this.inArray.push(this.customMy[j].items[z].tag);
                  }
                }
              }
            }
          }
        }

        if (this.wCloud.length > 0) {
          this.showWordCloud = true;
        } else this.showWordCloud = false;
      }
    },
    progress: function progress(currentProgress, previousProgress) {
      if (previousProgress) {
        this.progressVisible = false;
        this.setStyleC();
      }
    }
  },
  computed: {
    words: function words() {
      this.progressVisible = true;
      var my = []; //this.wordsText=[];

      var h = 0;

      for (var i in this.wCloud) {
        h = parseInt(this.wCloud[i].weight);

        if (h > 25) {
          h = Math.floor(Math.random() * 20 + 1);
        }

        my.push([this.wCloud[i].text, parseInt(h)]);
      }

      this.wordsText = my;
      return this.wordsText;
    },
    color: function color() {
      var colors = ['#ffd077', '#3bc4c7', '#3a9eea', '#ff4e69', '#461e47'];
      return function () {
        return chance.pickone(colors);
      };
    },
    enterAnimation: function enterAnimation() {
      return ['animated', this.animation[0]].join(' ');
    },
    leaveAnimation: function leaveAnimation() {
      return ['animated', this.animation[1]].join(' ');
    },
    animationOverlap: function animationOverlap() {
      return 1 / 2;
    },
    animationEasing: function animationEasing() {
      return 'ease-in';
    }
  },
  mounted: function mounted() {
    if (this.$options.propsData.type !== 'new') this.action = 'update';
    this.getData(); //this.generateWordsText();
  },
  methods: {
    getIndex: function getIndex(elem) {
      for (var i in this.wordsText) {
        if (this.wordsText[i][0] === elem) return 'elem_' + i;
      }

      return 'elem_' + 0;
    },
    setStyleC: function setStyleC() {
      setTimeout(function () {
        var items = document.getElementsByClassName('elemImportant');
        var i = 0;

        while (i < items.length) {
          document.getElementsByClassName('elemImportant')[i].setAttribute('style', 'color:' + items[i].parentElement.style.color + ' !important;font-size:' + items[i].parentElement.style.fontSize + ' !important');
          i++;
        }
      }, 500);
    },
    getData: function getData() {
      var self = this;
      axios.get('/user/editCreateCluster/' + this.$options.propsData.type).then(function (response) {
        self.tags = response.data.obj.tags;
        self.data = response.data.obj.backpack;
        self.model = response.data.obj.row;
        self.PreparaData();
        self.haveUpdate();
        self.isVideoMode = true;

        if (self.action === 'update') {
          self.showSendButtons = true;
        }
      });
    },
    PreparaData: function PreparaData() {
      var my = [];

      for (var i in this.tags) {
        my.push({
          selected: false,
          guid: this.tags[i].guid,
          items: this.tags[i].items
        });
      }

      this.customMy = my;
    },
    haveUpdate: function haveUpdate() {
      if (this.action === 'update') {
        for (var j in this.model.cluster_tags.selected) {
          for (var i in this.customMy) {
            if (this.customMy[i].guid === this.model.cluster_tags.selected[j].guid) {
              this.customMy[i] = this.model.cluster_tags.selected[j];
            }
          }
        } //{text:this.customMy[j].items[z].tag,name:this.customMy[j].items[z].tag,is_my:this.customMy[j].items[z].is_my,guid:this.customMy[j].guid,value:(z+1),weight:(z+1)}


        for (var _i in this.model.cluster_tags.wordcloud) {
          this.wCloud.push({
            text: this.model.cluster_tags.wordcloud[_i][0],
            name: this.model.cluster_tags.wordcloud[_i][0],
            is_my: false,
            guid: '',
            value: this.model.cluster_tags.wordcloud[_i][1],
            weight: this.model.cluster_tags.wordcloud[_i][1]
          });
          this.inArray.push(this.model.cluster_tags.wordcloud[_i][0]);
        }

        this.removed = this.model.cluster_tags.deleted;

        for (var _i2 in this.customMy) {
          if (this.customMy[_i2].selected) {
            this.selected.push(this.customMy[_i2].guid); //this.checkSelectItems(i);
          }
        } //this.wCloud=this.model.cluster_tags.wordcloud;


        this.showWordCloud = true;
      }
    },
    getNameBadge: function getNameBadge(item) {
      for (var i in this.data.validator) {
        if (this.data.validator[i].hash_ === item) {
          for (var j in this.data.validator[i].validation.graph) {
            var local = this.data.validator[i].validation.graph[j];

            if (local.type !== undefined && local.type === 'BadgeClass') {
              return local.name;
            }
          }
        }
      }
    },
    checkSelectItems: function checkSelectItems(index) {
      if (!this.progressVisible) {
        if (this.customMy[index].selected) {
          this.customMy[index].selected = false;

          for (var i in this.selected) {
            if (this.selected[i] === this.customMy[index].guid) this.selected.splice(i, 1);
          }
        } else {
          this.customMy[index].selected = true;
          this.selected.push(this.customMy[index].guid);
        }
      }
    },
    removeFromCloud: function removeFromCloud(item) {
      if (!this.progressVisible) {
        this.removed.push(this.wCloud[item].name);
        this.wCloud.splice(item, 1);
      }
    },
    removeFromRemoved: function removeFromRemoved(item) {
      if (!this.progressVisible) {
        this.removed.splice(item, 1);
        this.selected.push('uno');
      }
    },
    saveCluster: function saveCluster() {
      var self = this;

      if (this.model.clustern_name !== '') {
        this.showSendButtons = false;
        this.model.cluster_tags = {
          wordcloud: this.wordsText,
          selected: this.customMy,
          deleted: this.removed
        };
        axios({
          method: 'post',
          url: '/user/actionCluster',
          data: {
            model: this.model,
            type: this.action
          }
        }).then(function (response) {
          if (response.data.obj.redirect) {
            self.$router.push('/backpack/cluster/' + response.data.obj.guid);
          }
        });
      }
    },

    /***********WCLOUD*/
    rotationWCloud: function rotationWCloud(word) {
      var chance = new Chance(word[0]);
      return chance.pickone([0, 3 / 4]);
    },
    generateWordsText: function generateWordsText() {
      this.wordsText = [[9, 1, 3], [4, 5, 15], [2, 5, 15], [1, 25, 150]].reduce(function (returns, item) {
        var weight = item[0];
        var minCount = item[1];
        var maxCount = item[2];
        var count = chance.integer({
          min: minCount,
          max: maxCount
        });
        chance.n(function () {
          var word = chance.word();
          returns.push(word + ' ' + weight);
        }, count);
        return returns;
      }, []).join('\n');
    }
  }
};