var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item.view === "grid" ||
    (_vm.item.view === "list" && _vm.item.type === "badgeGroup")
    ? _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.item.view === "grid"
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-data-table", {
                        attrs: { items: _vm.item.validator },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "headers",
                              fn: function() {
                                return [
                                  _c("td"),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                        Titolo Badge\n                    "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t_("LABEL_EMESSO_DA_BADGE")
                                        ) +
                                        "\n                    "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_VALIDATOR_ASSERTION_EMISSIONE"
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_VALIDATOR_ASSERTION_SCADENZA"
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                        Url\n                    "
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c("td", [
                                    _c("img", {
                                      staticStyle: { "max-height": "50px" },
                                      attrs: { src: props.item.badge_image }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(_vm._s(props.item.badge_name))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(_vm._s(props.item.issuer_name))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("moment")(
                                          props.item.badge_issued_on,
                                          "DD/MM/YYYY"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    props.item.badge_expired_on !== undefined &&
                                    props.item.badge_expired_on !== ""
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  props.item.badge_expired_on,
                                                  "DD/MM/YYYY"
                                                )
                                              ) +
                                              "\n                        "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.$t_(
                                                  "FE_VALIDATOR_ASSERTION_NESSUNA_SCADENZA"
                                                )
                                              ) +
                                              "\n                        "
                                          )
                                        ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "text-xs-left" },
                                    [
                                      props.item.badge_criteri_id !== undefined
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { fab: "", icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.urlToSend(
                                                    props.item.badge_criteri_id
                                                  )
                                                }
                                              }
                                            },
                                            [_c("v-icon", [_vm._v("link")])],
                                            1
                                          )
                                        : props.item.badge_criteri !== undefined
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { fab: "", icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.urlToSend(
                                                    props.item.badge_criteri
                                                  )
                                                }
                                              }
                                            },
                                            [_c("v-icon", [_vm._v("link")])],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          170715742
                        )
                      })
                    ],
                    1
                  )
                : _vm.item.view === "list"
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("PostListCard", {
                        attrs: {
                          items: _vm.createListPostCard(_vm.item),
                          title: "TAG: " + _vm.item.tag.toUpperCase(),
                          enableFilter: false
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            _vm._l(_vm.item.validator, function(itemBadge, keyBadge) {
              return _c(
                "v-flex",
                { key: keyBadge, attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 transparent pa-2" },
                    [
                      _c("v-card-title", [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(itemBadge.badge_name) +
                              "\n                    "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs4: "", sm3: "" } }, [
                                _c("img", {
                                  staticStyle: { "max-width": "175px" },
                                  attrs: { src: itemBadge.badge_image }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs8: "", sm9: "" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "body-1 pb-2" },
                                    [
                                      _c(
                                        "v-list",
                                        { staticClass: "transparent" },
                                        [
                                          _c("v-list-tile", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "blue--text pr-3"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_VALIDATOR_ASSERTION_EMISSIONE"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    itemBadge.badge_issued_on,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                "\n                                    "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          itemBadge.badge_expired_on !==
                                            undefined &&
                                          itemBadge.badge_expired_on !== ""
                                            ? _c("v-list-tile", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "blue--text pr-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_VALIDATOR_ASSERTION_SCADENZA"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        itemBadge.badge_expired_on,
                                                        "DD/MM/YYYY"
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                )
                                              ])
                                            : _c("v-list-tile", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "blue--text pr-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_VALIDATOR_ASSERTION_NESSUNA_SCADENZA"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]),
                                          _vm._v(" "),
                                          _c("v-list-tile", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "blue--text pr-3"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "LABEL_EMESSO_DA_BADGE"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(itemBadge.issuer_name) +
                                                "\n                                    "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.item.view !== "image"
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "body-1 pb-4" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "\n                                                        " +
                                                  _vm._s(
                                                    itemBadge.badge_description
                                                  ) +
                                                  "\n                                                    "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider", {
                                          staticClass: "pa-2"
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "body-1" },
                                          [
                                            _c("h3", [_vm._v("Criteria")]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "pt-3 pb-2" },
                                              [
                                                itemBadge.badge_criteri !==
                                                undefined
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            itemBadge.badge_criteri,
                                                          target: "_blank"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            Open Criteria\n                                        "
                                                        )
                                                      ]
                                                    )
                                                  : itemBadge.badge_criteri_id !==
                                                    undefined
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            itemBadge.badge_criteri_id,
                                                          target: "_blank"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            Open Criteria\n                                        "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.item.view === "full"
                                              ? [
                                                  itemBadge.badge_criteri_narrative !==
                                                  undefined
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(
                                                              itemBadge.badge_criteri_narrative
                                                            ) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    : itemBadge.badge_criteri_id !==
                                                      undefined
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(
                                                              itemBadge.badge_criteri_id
                                                            ) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          { staticClass: "body-1 pb-4" },
                                          [
                                            _c("h3", [_vm._v("Criteria")]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "pt-3 pb-2" },
                                              [
                                                itemBadge.badge_criteri !==
                                                undefined
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            itemBadge.badge_criteri,
                                                          target: "_blank"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            Open Criteria\n                                        "
                                                        )
                                                      ]
                                                    )
                                                  : itemBadge.badge_criteri_id !==
                                                    undefined
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            itemBadge.badge_criteri_id,
                                                          target: "_blank"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            Open Criteria\n                                        "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }