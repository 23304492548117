//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "ExagonButton",
  props: ['icon', 'link', 'margin', 'object'],
  data: function data() {
    return {
      icona: 'arrow_downward',
      navLink: '',
      margine: 'mt-1',
      objects: {
        absolute: false,
        bottom: false,
        top: false,
        left: false,
        right: false
      }
    };
  },
  created: function created() {
    this.icona = this.$options.propsData.icon;
    this.navLink = this.$options.propsData.link;
    this.margine = 'hexagon ' + this.$options.propsData.margin;

    if (this.$options.propsData.object !== undefined) {
      this.object = this.$options.propsData.object;
    } // console.log(this.navLink);

  },
  methods: {
    enableLink: function enableLink(item) {
      if (item !== '') {
        return true;
      }

      return false;
    },
    navigatoToUrl: function navigatoToUrl(item) {
      this.$router.push(item.replace('/mob', ''));
    }
  }
};