var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { style: _vm.getStyle() },
    [
      _vm.isVideoMode
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0 white progetto ma-3" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.paginaUtente.coverCard,
                                      contain: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-card-title", { staticClass: "title" }, [
                                _c("h2", { staticClass: "display-2" }, [
                                  _vm._v(_vm._s(_vm.paginaUtente.c_name))
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { sm4: "", xs6: "" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "elevation-0  pa-4"
                                            },
                                            [
                                              _c("v-img", {
                                                staticStyle: {
                                                  "max-width": "250px"
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.user.config
                                                      .profile_image,
                                                  contain: ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { sm8: "", xs6: "" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "elevation-0  pa-4"
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass: "display-1",
                                                  attrs: { div: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(
                                                        _vm.user.first_name
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.user.last_name
                                                      ) +
                                                      "\n                                            "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    _vm._l(_vm.paginaUtente.object_to_compile.object, function(
                      item,
                      index
                    ) {
                      return _c(
                        "v-flex",
                        { key: index, attrs: { xs12: "" } },
                        [
                          item.type === "text"
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-3" },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      domProps: { innerHTML: _vm._s(item.text) }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.text) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "attachment"
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-3" },
                                [
                                  _c("v-card-title", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n\n                                " +
                                        _vm._s(_vm.$t_("CHIAVE_" + item.type)) +
                                        "\n                            "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-list",
                                        _vm._l(item.items, function(
                                          attachment
                                        ) {
                                          return _c(
                                            "v-list-tile",
                                            {
                                              key: attachment.file_name,
                                              on: {
                                                click: function($event) {
                                                  return _vm.NavigateTo(
                                                    attachment.path_save
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-tile-content", [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      attachment.file_name
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "badge" || item.type === "badgeGroup"
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-3" },
                                [
                                  _c("PagineUtenteBadgeContent", {
                                    attrs: { item: item }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "biografy" || item.type === "note"
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-3" },
                                [
                                  _c("v-card-title", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t_("CHIAVE_" + item.type)) +
                                        "\n                            "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("v-card-text", [
                                    _c(
                                      "div",
                                      {
                                        domProps: {
                                          innerHTML: _vm._s(item.text)
                                        }
                                      },
                                      [_vm._v(_vm._s(item.text))]
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "image"
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-3" },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-xs-center" },
                                    [
                                      item.items[0].type !== "application/pdf"
                                        ? _c("v-img", {
                                            staticStyle: { margin: "auto" },
                                            attrs: {
                                              src: item.items[0].path_save
                                            }
                                          })
                                        : [
                                            _c("iframe", {
                                              attrs: {
                                                src: item.items[0].path_save,
                                                height: "500px",
                                                width: "100%"
                                              }
                                            })
                                          ]
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "cluster_tags"
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-3" },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { height: "750px" } },
                                    [
                                      _c("vue-word-cloud", {
                                        attrs: {
                                          words: item.cluster,
                                          "font-family": "Roboto",
                                          rotation: _vm.rotationWCloud,
                                          spacing: 1,
                                          progress: _vm.progress,
                                          "font-size-ratio": 1 / 2,
                                          color: _vm.color,
                                          "enter-animation": _vm.enterAnimation,
                                          "leave-animation": _vm.leaveAnimation,
                                          "animation-overlap":
                                            _vm.animationOverlap,
                                          "animation-duration": 1250,
                                          "animation-easing":
                                            _vm.animationEasing
                                        },
                                        on: {
                                          "update:progress": function($event) {
                                            _vm.progress = $event
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var text = ref.text
                                                var weight = ref.weight
                                                var word = ref.word
                                                var color = ref.color
                                                var fontWeight = ref.fontWeight
                                                var fontStyle = ref.fontStyle
                                                var fontVariant =
                                                  ref.fontVariant
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "elemImportant"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(text) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-scale-transition",
                                        [
                                          _vm.progressVisible && _vm.progress
                                            ? _c(
                                                "v-progress-circular",
                                                {
                                                  staticClass: "v-no-animation",
                                                  staticStyle: {
                                                    bottom: "0",
                                                    left: "0",
                                                    margin: "auto",
                                                    position: "absolute",
                                                    right: "0",
                                                    top: "0"
                                                  },
                                                  attrs: {
                                                    rotate: -90,
                                                    size: 200,
                                                    value:
                                                      (_vm.progress
                                                        .completedWords /
                                                        _vm.progress
                                                          .totalWords) *
                                                      100,
                                                    width: 20,
                                                    color: "primary"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.progress
                                                        .completedWords
                                                    ) +
                                                      " of " +
                                                      _vm._s(
                                                        _vm.progress.totalWords
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.showFormPassword
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 " },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", color: "transparent" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t_("FE_UTENTE_PAGINA_RICHIESTA_PASSWORD")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _c(
                          "div",
                          [
                            _c("v-text-field", {
                              attrs: {
                                id: "password",
                                "prepend-icon": "lock",
                                name: "password",
                                label: "Password",
                                type: "password"
                              },
                              model: {
                                value: _vm.cPassword,
                                callback: function($$v) {
                                  _vm.cPassword = $$v
                                },
                                expression: "cPassword"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.matchPassword()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t_("FE_UTENTE_PAGINA_BTN_INVIA_PASSWORD")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }