//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "zMessage",
  props: ['type', 'utn', 'code'],
  data: function data() {
    return {
      messaggio: '',
      isVideoMode: false,
      response: {}
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var self = this;
      axios({
        method: 'post',
        url: '/sessions_messages',
        data: this.$options.propsData
      }).then(function (response) {
        self.response = response.data.obj;
        self.isVideoMode = true;
      });
    }
  }
};