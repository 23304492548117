//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PostListCard',
  props: ['items', 'title', 'enableFilter'],
  data: function data() {
    return {
      search: '',
      listObject: [],
      ready: false,
      paginate: [],
      currentPage: 1
    };
  },
  created: function created() {
    this.listObject = this.$options.propsData.items;
    this.ready = true;
    this.paginate = _.chunk(this.listObject, 15);
  },
  watch: {
    currentPage: {
      handler: function handler(item) {//this.contenuto.push([''])
        //this.changePage(item);
      }
    }
  },
  computed: {
    filtered: function filtered() {
      var _this = this;

      if (this.ready) {
        if (this.search !== '') {
          var my = this.listObject.filter(function (item) {
            return item.title.toLowerCase().indexOf(_this.search.toLowerCase()) > -1;
          });
          this.paginate = _.chunk(my, 15);
          return this.paginate[this.currentPage - 1];
        } else {
          return this.paginate[this.currentPage - 1];
        }
      }
    },
    chunkedItems: function chunkedItems() {
      return _.chunk(this.listObject, 15);
    }
  },
  methods: {
    handleThumb: function handleThumb() {// implement your own method here
    },
    handleComment: function handleComment() {// implement your own method here
    },
    handleFavorite: function handleFavorite() {// implement your own method here
    },
    navigateToUrl: function navigateToUrl(item) {
      if (item.enableLink) {
        if (item.direction !== undefined) {
          window.open(item.url, 'blank');
        } else this.$router.push(item.url);
      }
    }
  }
};