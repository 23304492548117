//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import JsonViewer from 'vue-json-viewer';
export default {
  name: "notarization",
  props: ["badge"],
  components: {
    'JsonViewer': JsonViewer
  },
  data: function data() {
    return {};
  },
  filters: {
    matchssa: function matchssa(e) {
      return e.charAt(0).toUpperCase() + e.slice(1);
    }
  },
  mounted: function mounted() {
    console.log(this.badge);
  },
  methods: {
    navigateToUrl: function navigateToUrl(el) {
      window.open('https://www.blockchain.com/eth/tx/' + el, '_blank');
    }
  }
};