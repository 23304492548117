var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: " fluid" },
        [
          !_vm.noBadge
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md3: "", sm3: "", xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-2" },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _vm.badgeClass.image.id !== undefined
                                        ? _c("v-img", {
                                            staticStyle: { padding: "5%" },
                                            attrs: {
                                              src: _vm.badgeClass.image.id,
                                              contain: ""
                                            }
                                          })
                                        : _c("v-img", {
                                            staticStyle: { padding: "5%" },
                                            attrs: {
                                              src: _vm.badgeClass.image,
                                              contain: ""
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-xs-center" },
                                    [
                                      _c("h4", { staticClass: "display-1" }, [
                                        _vm._v(
                                          "\n\t\t\t\t  " +
                                            _vm._s(_vm.badgeClass.name) +
                                            "\n\t\t\t\t"
                                        )
                                      ]),
                                      _vm._v(
                                        "\n\t\t\t\t" +
                                          _vm._s(
                                            _vm._f("moment")(
                                              _vm.assertion.issuedOn,
                                              "DD/MM/YYYY"
                                            )
                                          ) +
                                          "\n\n\t\t\t\t"
                                      ),
                                      _c("v-divider", {
                                        staticClass: "mt-4 pb-4"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-list",
                                        [
                                          _vm._l(_vm.lateralMenu, function(
                                            item,
                                            index
                                          ) {
                                            return [
                                              _c(
                                                "v-list-tile",
                                                {
                                                  attrs: { avatar: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.ScrollSection(
                                                        item.section
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-tile-content",
                                                    [
                                                      _c("v-list-tile-title", [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.lateralMenu[index + 1] !==
                                              undefined
                                                ? _c("v-divider")
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md9: "", sm9: "", xs12: "" } },
                            [
                              _vm.showRawData
                                ? _c(
                                    "v-card",
                                    { staticClass: "elevation-0 ma-2 verify" },
                                    [
                                      _c(
                                        "v-toolbar",
                                        { class: _vm.getAlertColor() },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "headline" },
                                            [
                                              _vm.all.report.valid
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "FE_VALIDATOR_VALIDO"
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "FE_VALIDATOR_NONVALIDO"
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _vm._l(_vm.badgeVersion, function(
                                            item
                                          ) {
                                            return [
                                              item.version ===
                                              _vm.all.report.openBadgesVersion
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "text-xs-left",
                                                      attrs: { xs12: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "headline pa-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t  " +
                                                              _vm._s(
                                                                _vm.$t_(
                                                                  "FE_VALIDATOR_VERSIONE"
                                                                )
                                                              ) +
                                                              "  " +
                                                              _vm._s(
                                                                item.version
                                                              ) +
                                                              "\n\t\t\t\t\t"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm.all.report.errorCount > 0 ||
                                      _vm.all.report.warningCount > 0
                                        ? _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            _vm._l(
                                              _vm.all.report.messages,
                                              function(item, index) {
                                                return _c(
                                                  "v-flex",
                                                  {
                                                    key: index,
                                                    staticClass: "pa-2",
                                                    attrs: { xs12: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-alert",
                                                      {
                                                        attrs: {
                                                          value: true,
                                                          color: _vm.getAlertType(
                                                            item
                                                          ).color,
                                                          icon: _vm.getAlertType(
                                                            item
                                                          ).icon,
                                                          outline: ""
                                                        }
                                                      },
                                                      _vm._l(item, function(
                                                        valore,
                                                        chiave
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key:
                                                              index +
                                                              " " +
                                                              chiave
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(chiave)
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              ": " +
                                                                _vm._s(valore) +
                                                                "\n\t\t\t\t\t"
                                                            )
                                                          ]
                                                        )
                                                      }),
                                                      0
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "pa-2",
                                          attrs: { row: "", wrap: "" }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t  " +
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_VALIDATOR_LABEL_TESTO_PIEPAG"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-2 badge" },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "display-1 mb-3" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t  " +
                                              _vm._s(
                                                _vm.$t_(
                                                  "FE_VALIDATOR_ABOUT_BADGE"
                                                )
                                              ) +
                                              "\n\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticClass: "wrap-madi-divider"
                                      }),
                                      _vm._v(" "),
                                      _vm.badgeClass.related !== undefined
                                        ? [
                                            _c(
                                              "span",
                                              { staticClass: "headline" },
                                              [_vm._v("Languages: ")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "active blue ",
                                                attrs: {
                                                  outline: "",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.getOtherLanguages(
                                                      _vm.badgeClass.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "max-height": "25px",
                                                    "min-height": "25px"
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.utilites.imgs[
                                                        _vm.badgeClass[
                                                          "@language"
                                                        ]
                                                      ]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      color: "purple"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "badge",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    dark: "",
                                                                    small: ""
                                                                  }
                                                                },
                                                                [_vm._v("done")]
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      227349030
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.badgeClass[
                                                              "@language"
                                                            ]
                                                          ) +
                                                          "\n"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.badgeClass.related.length > 0
                                              ? _vm._l(
                                                  _vm.badgeClass.related,
                                                  function(index, key) {
                                                    return _c(
                                                      "v-btn",
                                                      {
                                                        key: key,
                                                        staticClass: "orange",
                                                        attrs: {
                                                          small: "",
                                                          outline: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.getOtherLanguages(
                                                              index.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            "max-height":
                                                              "25px",
                                                            "min-height": "25px"
                                                          },
                                                          attrs: {
                                                            src:
                                                              _vm.utilites.imgs[
                                                                index[
                                                                  "@language"
                                                                ]
                                                              ]
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t  " +
                                                            _vm._s(
                                                              index["@language"]
                                                            ) +
                                                            "\n\n\t\t\t\t\t"
                                                        )
                                                      ]
                                                    )
                                                  }
                                                )
                                              : [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "orange",
                                                      attrs: {
                                                        outline: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.getOtherLanguages(
                                                            _vm.badgeClass
                                                              .related.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          "max-height": "25px",
                                                          "min-height": "25px"
                                                        },
                                                        attrs: {
                                                          src: _vm.getImage(
                                                            _vm.badgeClass
                                                              .related[
                                                              "@language"
                                                            ]
                                                          )
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t  " +
                                                          _vm._s(
                                                            _vm.badgeClass
                                                              .related[
                                                              "@language"
                                                            ]
                                                          ) +
                                                          "\n\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                ],
                                            _vm._v(" "),
                                            _c("v-divider", {
                                              staticClass: "wrap-madi-divider"
                                            })
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "headline mb-3 mt-3" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t  " +
                                              _vm._s(_vm.badgeClass.name) +
                                              "\n\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {},
                                        [
                                          _c("vue-simple-markdown", {
                                            attrs: {
                                              source: _vm.badgeClass.description
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.badgeClass.tags !== undefined &&
                                      _vm.badgeClass.tags.length > 0
                                        ? _c(
                                            "div",
                                            {},
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "headline mb-2 mb-3 mt-3"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.$t_("FE_TAGS_FE")
                                                      ) +
                                                      "\n\t\t\t\t  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.badgeClass.tags,
                                                function(item, index) {
                                                  return _c(
                                                    "v-chip",
                                                    { key: item },
                                                    [_vm._v(_vm._s(item))]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _vm.badgeClass.criteria !== undefined
                                    ? _c(
                                        "v-card-text",
                                        { staticClass: "mb-2 mt-2" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "headline mb-2" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t  " +
                                                  _vm._s(
                                                    _vm.$t_("FE_CRITERI_LABEL")
                                                  ) +
                                                  "\n\t\t\t\t"
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm.badgeClass.criteria
                                              .narrative !== undefined
                                              ? _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.badgeClass.criteria
                                                          .narrative
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.badgeClass
                                                            .criteria.narrative
                                                        ) +
                                                        "\n                                    "
                                                    )
                                                  ]
                                                )
                                              : _vm.badgeClass.criteria !== ""
                                              ? _c(
                                                  "span",
                                                  {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.badgeClass.criteria
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.badgeClass
                                                            .criteria
                                                        ) +
                                                        "\n                                    "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.customData.customData !== undefined &&
                                  _vm.customData.customData.length > 0
                                    ? _c(
                                        "v-card-text",
                                        { staticClass: "mb-2 mt-2" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "headline mb-3" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t  " +
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_VALIDATOR_CUSTOM_DATA_VIEW"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t"
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _vm._l(
                                                _vm.customData.customData,
                                                function(item) {
                                                  return [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "text-xs-left subheading",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(item.key)
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          ": " +
                                                            _vm._s(item.label) +
                                                            "\n\t\t\t\t\t"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "mb-2 mt-2" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { small: "", outline: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.openNewTab(
                                                _vm.badgeClass.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\n\t\t\t\t  View More Details\n\n\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.badgeClass.criteria.id !== undefined
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "right",
                                              attrs: { small: "", outline: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openNewTab(
                                                    _vm.badgeClass.criteria.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n\n\t\t\t\t  More Info\n\n\t\t\t\t"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.haveBadgeEndorse
                                ? _c("EndorsementCard", {
                                    attrs: {
                                      classe: "badge_endorser",
                                      title: _vm.$t_(
                                        "FE_VALIDATOR_ENDORSEMENT_BADGE_TITLE"
                                      ),
                                      items: _vm.endorsement.badge
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-2 issuing" },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "display-1 mb-2" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t_(
                                              "FE_VALIDATOR_ASSERTION_INFORMATION"
                                            )
                                          ) +
                                          "\n\t\t\t  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider", {
                                    staticClass: "wrap-madi-divider"
                                  }),
                                  _vm._v(" "),
                                  _c("v-card-text", [
                                    _c("div", { staticClass: "headline" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subheading font-weight-black"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t_(
                                                "FE_VALIDATOR_ASSERTION_EMISSIONE"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "subheading" },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(_vm.assertion.issuedOn) +
                                              " ( " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  _vm.assertion.issuedOn,
                                                  "D MMMM  YYYY"
                                                )
                                              ) +
                                              " )\n                                    "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "headline" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subheading font-weight-black"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t_(
                                                "FE_VALIDATOR_ASSERTION_SCADENZA"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.assertion.expires !== undefined &&
                                      _vm.assertion.expired !== "9999-01-01"
                                        ? _c(
                                            "span",
                                            { staticClass: "subheading" },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.assertion.expires
                                                  ) +
                                                  " ( " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm.assertion.expires,
                                                      "D MMMM  YYYY"
                                                    )
                                                  ) +
                                                  " )\n                                    "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "subheading" },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_VALIDATOR_ASSERTION_NESSUNA_SCADENZA"
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              )
                                            ]
                                          )
                                    ]),
                                    _vm._v(" "),
                                    _vm.all.report.recipientProfile !==
                                      undefined && _vm.showEmailUser
                                      ? _c(
                                          "div",
                                          { staticClass: "headline mb-3" },
                                          _vm._l(
                                            _vm.all.report.recipientProfile,
                                            function(item, index) {
                                              return _c("div", { key: item }, [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "subheading font-weight-black"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_VALIDATOR_VERIFICATION"
                                                        )
                                                      ) +
                                                        " " +
                                                        _vm._s(index) +
                                                        " : "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "subheading" },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item) +
                                                        "\n                                        "
                                                    )
                                                  ]
                                                )
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm.showEmailUser
                                      ? _c(
                                          "div",
                                          { staticClass: "headline" },
                                          [
                                            _c(
                                              "v-alert",
                                              {
                                                attrs: {
                                                  value: true,
                                                  color: "error",
                                                  icon: "warning",
                                                  outline: ""
                                                }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "subheading font-weight-black"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "FE_VALIDATOR_VERIFICATION"
                                                          )
                                                        ) + "  : "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "subheading"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_VALIDATOR_UTENTE_NON_VERIFICATO_VALIDO"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "red--text body-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "FE_VALIDATOR_NON_VERIFICATO_UTENTE_MESSAGE"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { small: "", outline: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.openNewTab(
                                                _vm.all.report.validationSubject
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t  View More Details\n\t\t\t\t"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { staticClass: "elevation-0 ma-2 issuer pb-4" },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "display-1 mb-2" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t_(
                                              "FE_VALIDATOR_ISSUER_ABOUT_CARD"
                                            )
                                          ) +
                                          "\n\t\t\t  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider", {
                                    staticClass: "wrap-madi-divider"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "headline mb-3"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.issuer.name)
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md3: "",
                                                sm5: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _vm.issuer.image !== undefined
                                                ? _c("v-img", {
                                                    attrs: {
                                                      src: _vm.issuer.image,
                                                      contain: ""
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md9: "",
                                                sm7: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _vm.issuer.description !==
                                              undefined
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "subheading "
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t  " +
                                                          _vm._s(
                                                            _vm.issuer
                                                              .description
                                                          ) +
                                                          "\n\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pb-4 mb-3" },
                                    [
                                      _vm.issuer.id !== undefined
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "left",
                                              attrs: { small: "", outline: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openNewTab(
                                                    _vm.issuer.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t  View More Details\n\t\t\t\t"
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.issuer.email !== undefined
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "right",
                                              attrs: { small: "", outline: "" }
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    href:
                                                      "mailto:" +
                                                      _vm.issuer.email
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\tEMAIL\n\t\t\t\t  "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.issuer.url !== undefined
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "right",
                                              attrs: { small: "", outline: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openNewTab(
                                                    _vm.issuer.url
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t  Web Site\n\t\t\t\t"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.haveIssuerEndorse
                                ? _c("EndorsementCard", {
                                    attrs: {
                                      classe: "issuer_endorser",
                                      title: _vm.$t_(
                                        "FE_VALIDATOR_ENDORSEMENT_ISSER_TITLE"
                                      ),
                                      items: _vm.endorsement.issuer
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showRawData
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "elevation-0 ma-2 grey lighten-4 raw_data"
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "display-1" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t" +
                                              _vm._s(
                                                _vm.$t_(
                                                  "FE_VALIDATOR_RAW_DATA_VIEW"
                                                )
                                              ) +
                                              "\n\t\t\t  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticClass: "wrap-madi-divider"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "subheading pa-2 mb-2"
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_VALIDATOR_STATUS_DETAILS"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(":\n\t\t\t\t\t"),
                                                  _vm.all.report.valid
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$t_(
                                                                "FE_VALIDATOR_VALIDO"
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$t_(
                                                                "FE_VALIDATOR_NONVALIDO"
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        )
                                                      ]),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_VALIDATOR_STATUS_CON_ERRORI"
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.all.report
                                                          .errorCount
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_VALIDATOR_STATUS_CON_WARNING"
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.all.report
                                                          .warningCount
                                                      ) +
                                                      "\n\n\n\t\t\t\t  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "subheading pa-2 mb-2"
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_VALIDATOR_TIPO_VERIFICA"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.all.report
                                                          .validationSubject
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("json-viewer", {
                                            attrs: {
                                              value: _vm.all,
                                              "expand-depth": 5
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 ma-2 verify" },
                        [
                          _c("v-toolbar", { staticClass: "error" }, [
                            _c("div", { staticClass: "headline" }, [
                              _vm.all.report.valid
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.$t_("FE_VALIDATOR_VALIDO")) +
                                        "\n                                    "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t_("FE_VALIDATOR_NONVALIDO")
                                        ) +
                                        "\n                                    "
                                    )
                                  ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.all.report.errorCount > 0 ||
                          _vm.all.report.warningCount > 0
                            ? _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                _vm._l(_vm.all.report.messages, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "v-flex",
                                    {
                                      key: index,
                                      staticClass: "pa-2",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            value: true,
                                            color: _vm.getAlertType(item).color,
                                            icon: _vm.getAlertType(item).icon,
                                            outline: ""
                                          }
                                        },
                                        _vm._l(item, function(valore, chiave) {
                                          return _c(
                                            "div",
                                            { key: index + " " + chiave },
                                            [
                                              _c("b", [_vm._v(_vm._s(chiave))]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(valore) +
                                                  "\n\t\t\t\t"
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  { staticClass: "subheading pa-2 mb-2" },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t_("FE_VALIDATOR_STATUS_DETAILS")
                                        )
                                      )
                                    ]),
                                    _vm._v(":\n\t\t\t\t"),
                                    _vm.all.report.valid
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.$t_("FE_VALIDATOR_VALIDO")
                                              ) +
                                              "\n                                            "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.$t_(
                                                  "FE_VALIDATOR_NONVALIDO"
                                                )
                                              ) +
                                              "\n                                            "
                                          )
                                        ]),
                                    _vm._v(
                                      "\n\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_VALIDATOR_STATUS_CON_ERRORI"
                                          )
                                        ) +
                                        " " +
                                        _vm._s(_vm.all.report.errorCount) +
                                        " " +
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_VALIDATOR_STATUS_CON_WARNING"
                                          )
                                        ) +
                                        " " +
                                        _vm._s(_vm.all.report.warningCount) +
                                        "\n\n\n\t\t\t  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "subheading pa-2 mb-2" },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t_("FE_VALIDATOR_TIPO_VERIFICA")
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.all.report.validationSubject)
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "elevation-0 ma-2 grey lighten-4 raw_data"
                        },
                        [
                          _c("v-card-title", { staticClass: "display-1" }, [
                            _vm._v(
                              "\n\t\t\t" +
                                _vm._s(_vm.$t_("FE_VALIDATOR_RAW_DATA_VIEW")) +
                                "\n\t\t  "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "wrap-madi-divider" }),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("json-viewer", {
                                attrs: { value: _vm.all, "expand-depth": 5 }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }