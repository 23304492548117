//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import UploadButton from 'vuetify-upload-button';
export default {
  name: "Validator",
  components: {
    'upload-btn': UploadButton
  },
  data: function data() {
    return {
      object: '',
      show: {
        image: true,
        object: true
      },
      image: '',
      elements: [],
      fileName: 'No file selected',
      modelloCheck: [],
      elem: ['email', 'url', 'telephone'],
      select: 'Choose Element',
      currentValue: '',
      headers: ['type', 'value'],
      error: false,
      dialog: {
        title: '',
        message: '',
        btn_ok: ''
      },
      immagineRead: '',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      includeResponse: false,
      response: {},
      send: false
    };
  },
  watch: {
    object: {
      handler: function handler() {
        if (this.object.length > 0) {
          this.show.image = true;
        } else {
          this.show.image = true;
        }
      }
    },
    fileName: {
      handler: function handler() {
        if (this.fileName.length > 0) {
          this.object = '';
          this.show.object = true;
        }
      }
    }
  },
  methods: {
    sendData: function sendData() {
      var self = this;
      self.send = true; //badge_object
      //badge_image
      //verify_elem[email][]

      var data = new FormData();

      if (this.object !== '' || this.image !== '') {
        data.append('badge_object', this.object);
        data.append('verify_elem', JSON.stringify(this.modelloCheck));
        data.append('badge_image', this.image); //console.log(this.image);

        var options = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        };
        axios.post('/validator/validatorVuex', data, options).then(function (response) {
          //this.response=JSON.parse(String).obj;
          self.response = response.data.obj;
          self.$root.newData = self.response;
          self.$root.callFrom = 'validator';
          setTimeout(function () {
            self.$router.push('/validator/result');
          }, 1200);
        });
      } else {
        this.dialog = {
          title: this.$t_('FE_VALIDATOR_MESSAGE_TITLE_EMPTY_DATA_ERROR'),
          message: this.$t_('FE_VALIDATOR_MESSAGE_MESSAGE_EMPTY_ERROR_ATTRIBUTI'),
          btn_ok: this.$t_('BTN_CLOSE')
        };
        this.error = true;
      }
    },
    validateUrl: function validateUrl(param) {
      var regex = /^(http|https)/;

      if (param > 3 && !param.match(regex)) {
        param = 'http://' + param;
      }
    },
    isEmailValid: function isEmailValid(email) {
      return email == "" ? "" : this.reg.test(email) ? true : false;
    },
    update: function update(file) {
      console.log(file);
      this.fileName = file.name;
      this.image = file;
    },
    removeElement: function removeElement(index) {
      this.modelloCheck.splice(index, 1);
    },
    setThisElement: function setThisElement(elem) {
      this.select = elem;
    },
    addThis: function addThis() {
      if (this.select !== 'Choose Element') {
        if (this.select === 'email') {
          if (this.isEmailValid(this.currentValue)) {
            var my = {
              type: this.select,
              value: this.currentValue
            };
            this.modelloCheck.push(my);
            this.select = 'Choose Element';
            this.currentValue = '';
          } else {
            this.dialog = {
              title: this.$t_('FE_VALIDATOR_MESSAGE_TITLE_ERROR'),
              message: this.$t_('FE_VALIDATOR_MESSAGE_MESSAGE_WRONG_EMAIL_DATA'),
              btn_ok: this.$t_('BTN_CLOSE')
            };
            this.error = true;
          }
        } else {
          if (this.currentValue !== '') {
            var _my = {
              type: this.select,
              value: this.currentValue
            };
            this.modelloCheck.push(_my);
            this.select = 'Choose Element';
            this.currentValue = '';
          } else {
            this.dialog = {
              title: this.$t_('FE_VALIDATOR_MESSAGE_TITLE_ERROR'),
              message: this.$t_('FE_VALIDATOR_MESSAGE_MESSAGE_WRONG_DATA'),
              btn_ok: this.$t_('BTN_CLOSE')
            };
            this.error = true;
          }
        }
      } else {
        this.dialog = {
          title: this.$t_('FE_VALIDATOR_MESSAGE_TITLE_ERROR'),
          message: this.$t_('FE_VALIDATOR_MESSAGE_MESSAGE_ERROR_EMPTY_CHOOSE'),
          btn_ok: this.$t_('BTN_CLOSE')
        };
        this.error = true;
      }
    }
  }
};