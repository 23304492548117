var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.backToMenu
        ? _c(
            "v-btn",
            {
              staticStyle: { "margin-top": "-50px" },
              attrs: {
                small: "",
                dark: "",
                fab: "",
                top: "",
                left: "",
                color: "red"
              },
              on: { click: _vm.resetAllMenu }
            },
            [_c("v-icon", [_vm._v("arrow_back")])],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("userSidebar", { attrs: { ready: true } }),
      _vm._v(" "),
      _vm.isVideoMode
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.isMenu
                ? [
                    _c(
                      "v-flex",
                      { attrs: { md3: "", sm12: "", xs12: "" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "ma-4 pa-2 elevation-0" },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("vuetify-lazy-image", {
                                  staticClass: "badge",
                                  staticStyle: {
                                    "'max-height": "200px",
                                    "min-height": "200px",
                                    margin: "-8px",
                                    "background-size": "60% auto"
                                  },
                                  attrs: {
                                    src: _vm.getUrlFile(
                                      _vm.currentBadge.badge.image
                                    ),
                                    contain: true
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-divider"),
                            _vm._v(" "),
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "title  pt-4 pb-5   font-weight-medium madi-style"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.getNambeBadge()) +
                                    "\n\t\t\t\t\t"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              { staticClass: "text-xs-center" },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm._f("moment")(
                                        _vm.currentBadge.badge.iTXt.issuedOn,
                                        "D MMMM  YYYY"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "red", block: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.deleteDialog = true
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_UTENTE_CANCELLA_STO_BADGE"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { md9: "", sm12: "", xs12: "" } },
                      [
                        !_vm.isFormControl && !_vm.activeCustomData
                          ? [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pa-2 ma-4 elevation-0"
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            { staticClass: "title" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.$t_(
                                                      "FE_UTENTE_VISIBILITA_BADGE_SHARED"
                                                    )
                                                  ) +
                                                  ":\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                              _vm.permissionView === "1"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "success--text pl-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_FORM_DATI_ADD_COMPLETAMENTE_PUB_VISUALIZZA_PROFILO"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                : _vm.permissionView === "2"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text pl-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_FORM_DATI_ADD_SOLO_AUTH_VISUALIZZA_PROFILO"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "error--text pl-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_FORM_DATI_ADD_NESSUNO_VISUALIZZA_PROFILO"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs3: "" } },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              "https://app.myopenbadge.com/receive/" +
                                                              _vm.$root.profile
                                                                .config
                                                                .my_custom_url +
                                                              "/" +
                                                              _vm.currentBadge
                                                                .badge
                                                                .guid_badge +
                                                              "/public"
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                "https://app.myopenbadge.com/utils/shorten_url/" +
                                                                _vm.currentBadge
                                                                  .badge.shorten
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs9: "" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0"
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-actions",
                                                            {
                                                              staticClass:
                                                                "pa-4"
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      "https://app.myopenbadge.com/receive/" +
                                                                      _vm.$root
                                                                        .profile
                                                                        .config
                                                                        .my_custom_url +
                                                                      "/" +
                                                                      _vm
                                                                        .currentBadge
                                                                        .badge
                                                                        .guid_badge +
                                                                      "/public"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\thttps://app.myopenbadge.com/receive/" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .$root
                                                                          .profile
                                                                          .config
                                                                          .my_custom_url
                                                                      ) +
                                                                      "/" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .currentBadge
                                                                          .badge
                                                                          .guid_badge
                                                                      ) +
                                                                      "/public\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-card-actions", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-xs-center mt-5"
                                              },
                                              _vm._l(_vm.networks, function(
                                                network
                                              ) {
                                                return _c(
                                                  "ShareNetwork",
                                                  {
                                                    key: network.network,
                                                    staticClass: "ma-1",
                                                    style: {
                                                      backgroundColor:
                                                        network.color,
                                                      color: "#FFF"
                                                    },
                                                    attrs: {
                                                      network: network.network,
                                                      url:
                                                        _vm.currentBadge.badge
                                                          .share.url,
                                                      title:
                                                        _vm.currentBadge.badge
                                                          .share.title,
                                                      description:
                                                        _vm.currentBadge.badge
                                                          .share.description,
                                                      quote:
                                                        _vm.currentBadge.badge
                                                          .share.quote,
                                                      hashtags:
                                                        _vm.currentBadge.badge
                                                          .share.hashtags,
                                                      twitterUser:
                                                        _vm.currentBadge.badge
                                                          .share.twitterUser,
                                                      tag: "v-btn",
                                                      disabled:
                                                        _vm.permissionView ===
                                                        "3"
                                                    },
                                                    on: {
                                                      open: function($event) {
                                                        return _vm.actionsUser(
                                                          network
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      class: network.icon,
                                                      staticStyle: {
                                                        color: "#FFF"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "pl-2",
                                                        staticStyle: {
                                                          color:
                                                            "#FFF !important",
                                                          display: "none"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(network.name)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }),
                                              1
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.iterCards, function(item, index) {
                                    return _c(
                                      "v-flex",
                                      { key: index, attrs: { xs6: "" } },
                                      [
                                        _c(
                                          "v-card",
                                          { class: "pa-2 ma-4 " + item.color },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    { staticClass: "headline" },
                                                    [_vm._v(_vm._s(item.name))]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.description
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            item.description
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-card-actions",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      dark: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.activeShowThis(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.$t_(
                                                            "FE_UTENTE_BADGE_VIEW_MORE"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm.infoNotarization !== null
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs6: "" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              class: "pa-2 ma-4 green lighten-1"
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "headline"
                                                      },
                                                      [_vm._v("Notarization")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\tBlock Chain Eth\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        dark: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showNotarizationModal = true
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tEth Details\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          : _vm.isFormControl
                          ? [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "ma-4 elevation-0" },
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              attrs: {
                                                color: "cyan lighten-1",
                                                flat: "",
                                                dense: "",
                                                card: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-toolbar-title",
                                                {
                                                  staticClass:
                                                    "subheading ft-200"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_CARD_TITLE_FORM_CONTROL_SHARED"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_UTENTE_VISIBILITA_BADGE_SHARED"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { column: "" },
                                                  model: {
                                                    value: _vm.permissionView,
                                                    callback: function($$v) {
                                                      _vm.permissionView = $$v
                                                    },
                                                    expression: "permissionView"
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t_(
                                                        "FE_FORM_DATI_ADD_COMPLETAMENTE_PUB_VISUALIZZA_PROFILO"
                                                      ),
                                                      value: "1"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t_(
                                                        "FE_FORM_DATI_ADD_SOLO_AUTH_VISUALIZZA_PROFILO"
                                                      ),
                                                      value: "2"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t_(
                                                        "FE_FORM_DATI_ADD_NESSUNO_VISUALIZZA_PROFILO"
                                                      ),
                                                      value: "0"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_UTENTE_GOOGLE_INDEX"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { column: "" },
                                                  model: {
                                                    value: _vm.googleView,
                                                    callback: function($$v) {
                                                      _vm.googleView = $$v
                                                    },
                                                    expression: "googleView"
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t_(
                                                        "FE_UTENTE_GOOGLE_NEGA_ACCESSO"
                                                      ),
                                                      value: "0"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t_(
                                                        "FE_UTENTE_GOOGLE_ACCONSENTI_ACCESSO"
                                                      ),
                                                      value: "1"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_UTENTE_CUSTOM_TAG_BADGE"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-combobox", {
                                                attrs: {
                                                  "hide-selected": "",
                                                  multiple: "",
                                                  "small-chips": "",
                                                  label: _vm.$t_(
                                                    "FE_UTENTE_TAGS_BADGE_"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.currentBadge.badge
                                                      .my_tags,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.currentBadge.badge,
                                                      "my_tags",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "currentBadge.badge.my_tags"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-textarea", {
                                                attrs: {
                                                  label: _vm.$t_(
                                                    "FE_UTENTE_NOTE_BADGE_"
                                                  ),
                                                  counter: "",
                                                  maxlength: "450"
                                                },
                                                model: {
                                                  value:
                                                    _vm.currentBadge.badge
                                                      .my_notes,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.currentBadge.badge,
                                                      "my_notes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "currentBadge.badge.my_notes"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    block: "",
                                                    color: "blue"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateBadgeShare()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.$t_(
                                                          "FE_UTENTE_BADGE_AGGIORNA_PROPS"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm.activeCustomData
                          ? [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "ma-4 elevation-0" },
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              attrs: {
                                                color: "cyan lighten-1",
                                                flat: "",
                                                dense: "",
                                                card: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-toolbar-title",
                                                {
                                                  staticClass:
                                                    "subheading ft-200"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_BADGE_CUSTOM_DATA_UTENTE_VIEW"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-text",
                                            [
                                              _vm._l(
                                                _vm.currentBadge.badge.compose
                                                  .attributes,
                                                function(customData, index) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "mt-2 mb-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(index + 1) +
                                                            ". "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "blue--text pr-3"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                customData.key
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            customData.value
                                                          ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isValidator
                ? _c(
                    "v-flex",
                    { attrs: { md12: "" } },
                    [
                      _c("ValidatorResponse", {
                        key: _vm.guid_badge,
                        attrs: { current: _vm.guid_badge }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isShareOptions
                ? _c(
                    "v-flex",
                    { attrs: { "md-12": "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-4 elevation-0" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                color: "orange lighten-1",
                                flat: "",
                                dense: "",
                                card: ""
                              }
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "subheading ft-200" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t_("FE_UTENTE_URL_PUBBLICO_BADGE")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _c(
                              "div",
                              {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t_(
                                      "FE_UTENTE_URL_PUBBLICO_DESCRIPTION_BADGE"
                                    )
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.$t_(
                                        "FE_UTENTE_URL_PUBBLICO_DESCRIPTION_BADGE"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t"
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-card-actions", { staticClass: "pa-4" }, [
                            _c("pre", { staticClass: "item-border" }, [
                              _vm._v(
                                "https://app.myopenbadge.com/receive/" +
                                  _vm._s(
                                    _vm.$root.profile.config.my_custom_url
                                  ) +
                                  "/" +
                                  _vm._s(_vm.currentBadge.badge.guid_badge) +
                                  "/public"
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "ma-4 elevation-0" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                color: "orange lighten-1",
                                flat: "",
                                dense: "",
                                card: ""
                              }
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "subheading ft-200" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t_(
                                        "FE_UTENTE_URL_ASSERTION_VALIDATION_BADGE"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _c(
                              "div",
                              {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t_(
                                      "FE_UTENTE_URL_ASSERTION_DESCRIPTION_VALIDATION_BADGE"
                                    )
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.$t_(
                                        "FE_UTENTE_URL_ASSERTION_DESCRIPTION_VALIDATION_BADGE"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t"
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-card-actions", { staticClass: "pa-4" }, [
                            _c("pre", { staticClass: "item-border" }, [
                              _vm._v(
                                "https://app.myopenbadge.com/api/v2/assertion/" +
                                  _vm._s(_vm.currentBadge.badge.guid_badge)
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "ma-4 elevation-0" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                color: "orange lighten-1",
                                flat: "",
                                dense: "",
                                card: ""
                              }
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "subheading ft-200" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t_("FE_UTENTE_BADGE_DOWNLOAD_IMAGE")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-tile",
                                    {
                                      attrs: { avatar: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadBadge(
                                            "/badge/download/" +
                                              _vm.currentBadge.badge.guid_badge
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.$t_(
                                                    "FE_UTENTE_BADGE_DOWNLOAD_NATURAl_IMAGE"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-tile-avatar", [
                                        _c("img", {
                                          attrs: { src: "/utils/dummy/400/400" }
                                        })
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.currentBadge.badge.is_mob_object === "1"
                                    ? _c(
                                        "v-list-tile",
                                        {
                                          attrs: { avatar: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadBadge(
                                                "/badge/download/" +
                                                  _vm.currentBadge.badge
                                                    .guid_badge +
                                                  "/350"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_BADGE_DOWNLOAD_350_IMAGE"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-list-tile-avatar", [
                                            _c("img", {
                                              attrs: {
                                                src: "/utils/dummy/350/350"
                                              }
                                            })
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentBadge.badge.is_mob_object === "1"
                                    ? _c(
                                        "v-list-tile",
                                        {
                                          attrs: { avatar: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadBadge(
                                                "/badge/download/" +
                                                  _vm.currentBadge.badge
                                                    .guid_badge +
                                                  "/125"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.$t_(
                                                        "FE_UTENTE_BADGE_DOWNLOAD_125_IMAGE"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-list-tile-avatar", [
                                            _c("img", {
                                              attrs: {
                                                src: "/utils/dummy/125/125"
                                              }
                                            })
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.currentBadge.badge.is_mob_object === "1"
                        ? _c(
                            "v-card",
                            { staticClass: "ma-4 elevation-0" },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color: "purple lighten-3",
                                    flat: "",
                                    dense: "",
                                    card: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticClass: "subheading ft-200" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_UTENTE_BADGE_EMBEDDED_HTML"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c("v-card-text", [
                                _c("pre", { staticClass: "item-border" }, [
                                  _vm._v(_vm._s(_vm.embedded))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-card-text", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.embedded)
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(_vm.embedded) +
                                        "\n\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "ma-4 elevation-0" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                color: "orange lighten-1",
                                flat: "",
                                dense: "",
                                card: ""
                              }
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "subheaning ft-200" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t_("FE_UTENTE_BADGE_LINKEDIN_SHARE")
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", ripple: "" },
                                  on: { click: _vm.apriLinkedin }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey lighten-1" } },
                                    [_vm._v("file_copy")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: { md10: "", sm9: "", xs12: "" }
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { attrs: { "two-line": "" } },
                                          [
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c(
                                                      "v-list-tile-title",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_UTENTE_BADGE_LINKEDIN_NOME_CERTIFICAZIONE"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list-tile-sub-title",
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getNambeBadge()
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "clipboard",
                                                            rawName:
                                                              "v-clipboard",
                                                            value: _vm.getNambeBadge(),
                                                            expression:
                                                              "(getNambeBadge())"
                                                          }
                                                        ],
                                                        attrs: {
                                                          icon: "",
                                                          ripple: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "grey lighten-1"
                                                            }
                                                          },
                                                          [_vm._v("file_copy")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c(
                                                      "v-list-tile-title",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_UTENTE_BADGE_LINKEDIN_CERTIFICANTE"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list-tile-sub-title",
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getIssuerName()
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "clipboard",
                                                            rawName:
                                                              "v-clipboard",
                                                            value: _vm.getIssuerName(),
                                                            expression:
                                                              "(getIssuerName())"
                                                          }
                                                        ],
                                                        attrs: {
                                                          icon: "",
                                                          ripple: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "grey lighten-1"
                                                            }
                                                          },
                                                          [_vm._v("file_copy")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c(
                                                      "v-list-tile-title",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_UTENTE_BADGE_LINKEDIN_LIC"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list-tile-sub-title",
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentBadge
                                                              .badge.guid_badge
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "clipboard",
                                                            rawName:
                                                              "v-clipboard",
                                                            value:
                                                              _vm.currentBadge
                                                                .badge
                                                                .guid_badge,
                                                            expression:
                                                              "(currentBadge.badge.guid_badge)"
                                                          }
                                                        ],
                                                        attrs: {
                                                          icon: "",
                                                          ripple: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.actionsUser(
                                                              {
                                                                network:
                                                                  "linkedin"
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "grey lighten-1"
                                                            }
                                                          },
                                                          [_vm._v("file_copy")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c(
                                                      "v-list-tile-title",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t_(
                                                              "FE_UTENTE_BADGE_LINKEDIN_URL_CERTIFICAZIONE"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list-tile-sub-title",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "normal!important"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "https://app.myopenbadge.com/" +
                                                            _vm._s(
                                                              _vm.$root.profile
                                                                .config
                                                                .my_custom_url
                                                            ) +
                                                            "/" +
                                                            _vm._s(
                                                              _vm.currentBadge
                                                                .badge
                                                                .guid_badge
                                                            ) +
                                                            "/public"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "clipboard",
                                                            rawName:
                                                              "v-clipboard",
                                                            value:
                                                              "https://app.myopenbadge.com/" +
                                                              _vm.$root.profile
                                                                .config
                                                                .my_custom_url +
                                                              "/" +
                                                              _vm.currentBadge
                                                                .badge
                                                                .guid_badge +
                                                              "/public",
                                                            expression:
                                                              "('https://app.myopenbadge.com/'+$root.profile.config.my_custom_url+'/'+currentBadge.badge.guid_badge+'/public')"
                                                          }
                                                        ],
                                                        attrs: {
                                                          icon: "",
                                                          ripple: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "grey lighten-1"
                                                            }
                                                          },
                                                          [_vm._v("file_copy")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      {
                                        staticStyle: {
                                          "margin-top": "130px",
                                          "padding-left": "50px"
                                        },
                                        attrs: {
                                          md2: "",
                                          sm3: "",
                                          "xs-hiddens": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { flat: "" },
                                            on: { click: _vm.apriLinkedin }
                                          },
                                          [
                                            _c("v-img", {
                                              staticStyle: {
                                                "max-width": "60px"
                                              },
                                              attrs: {
                                                src:
                                                  "/public/assets/custom-mob/linkedin.png",
                                                contain: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "ma-4 elevation-0" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                color: "blue",
                                flat: "",
                                dense: "",
                                card: ""
                              }
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "subheaning ft-200" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t_("FE_UTENTE_BADGE_LINKEDIN_LIC")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _c("pre", { staticClass: "item-border" }, [
                              _vm._v(_vm._s(_vm.currentBadge.badge.guid_badge))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "650" },
              model: {
                value: _vm.deleteDialog,
                callback: function($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "red lighten-3",
                      attrs: {
                        color: "transparent",
                        flat: "",
                        dense: "",
                        card: ""
                      }
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "subheading ft-200" },
                            [_vm._v(_vm._s(_vm.deleteDialogCard.title))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.deleteDialogCard.message) +
                        "\n\t\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "indifo darken-1", flat: "" },
                          on: {
                            click: function($event) {
                              _vm.deleteDialog = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t_("BTN_ANNULLA_OPERAZIONE")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red darken-1", flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.CancellaBadge()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t_("BTN_CONTINUA_CANCELLAZIONE")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showNotarizationModal
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", fullscreen: "" },
              model: {
                value: _vm.showNotarizationModal,
                callback: function($$v) {
                  _vm.showNotarizationModal = $$v
                },
                expression: "showNotarizationModal"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", color: "primary" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              _vm.showNotarizationModal = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-toolbar-title", [_vm._v("Notarization")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", flat: "" },
                              on: {
                                click: function($event) {
                                  _vm.showNotarizationModal = false
                                }
                              }
                            },
                            [_vm._v("Close")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("notarization", { attrs: { badge: _vm.infoNotarization } })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ProgressBar", { attrs: { dialog: _vm.showLoader } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }