var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "div",
        { staticClass: "container fluid" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm._l(_vm.cards, function(item) {
                return [
                  _c(
                    "v-flex",
                    { attrs: { md4: "", sm12: "", xs12: "", "mb-2": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs10: "", "offset-xs1": "" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "elavation-10" },
                                [
                                  _c(
                                    "v-toolbar",
                                    {
                                      attrs: {
                                        color: "transparent",
                                        flat: "",
                                        dense: "",
                                        card: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.NavigaPush(item.show.url)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        { staticClass: "subheading ft-200" },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                dark: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "  " +
                                                                _vm._s(
                                                                  item.show.name
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "  " + _vm._s(item.show.name)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                md6: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.NavigaPush(
                                                    item.show.url
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: item.show.image,
                                                  "max-width": "95%",
                                                  height: "175",
                                                  "aspect-ratio": "0.6"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                md6: ""
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _c("div", {}, [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(item.show.desc) +
                                                      "\n                                            "
                                                  )
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c("v-divider"),
                                              _vm._v(" "),
                                              _c("b-nav-text", [
                                                _vm._v(
                                                  _vm._s(item.show.disp_from)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("v-divider"),
                                              _vm._v(" "),
                                              _c("b-nav-text", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.show.footer_1
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("b-nav-text", {
                                        staticClass: "warningTags",
                                        domProps: {
                                          innerHTML: _vm._s(item.show.desc1)
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("b-nav-text", {
                                        staticClass: "grey--text ",
                                        domProps: {
                                          innerHTML: _vm._s(item.show.footer_1)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "text-xs-center" },
                  [
                    _c("v-pagination", {
                      attrs: { length: _vm.number(_vm.pages.max), circle: "" },
                      model: {
                        value: _vm.getPage,
                        callback: function($$v) {
                          _vm.getPage = $$v
                        },
                        expression: "getPage"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }