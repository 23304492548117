//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PostListCard from "../../app/components/utils/widgets/card/PostListCard";
export default {
  name: "PagineUtenteBadgeContent",
  components: {
    PostListCard: PostListCard
  },
  props: ['item'],
  data: function data() {
    return {
      currentBadges: []
    };
  },
  mounted: function mounted() {},
  methods: {
    checkItems: function checkItems(item) {
      console.log(item);
    },
    createListPostCard: function createListPostCard(item) {
      var my = [];

      for (var i in item.validator) {
        var local = {};
        local.image = item.validator[i].badge_image;
        local.title = item.validator[i].badge_name;
        local.desc = item.validator[i].badge_description;
        local.author = this.$t_('LABEL_EMESSO_DA_BADGE') + ' ' + item.validator[i].issuer_name;
        local.createdAt = item.validator[i].badge_issued_on;
        local.enableLink = false;

        if (item.validator[i].badge_criteri_id !== undefined) {
          local.enableLink = true;
          local.direction = 'new_target';
          local.url = item.validator[i].badge_criteri_id;
        } else {
          if (item.validator[i].badge_criteri !== undefined && !local.enableLink) {
            local.enableLink = true;
            local.direction = 'new_target';
            local.url = item.validator[i].badge_criteri;
          }
        }

        local.icon = '';
        my.push(local);
      }

      return my;
    },
    urlToSend: function urlToSend(url) {
      window.open(url, 'blank');
    }
  }
};