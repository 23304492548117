var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("userSidebar", { attrs: { ready: _vm.isVideoMode } }),
          _vm._v(" "),
          _vm.ricongiungiEmail
            ? _c("v-layout", { attrs: { row: "", wrap: "" } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.addEmail
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { "xs-12": "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 pa-2" },
                        [
                          _c("v-card-title", { staticClass: "title" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t_("FE_CARD_UTENTE_GESIONE_EMAIL")
                                ) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  headers: _vm.emailUtente.headers,
                                  items: _vm.emailUtente.dati
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "items",
                                      fn: function(props) {
                                        return [
                                          _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(props.item.new_email) +
                                                "\n                                "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            props.item.is_primary
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("check")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(props.item.num_badge) +
                                                "\n                                "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.getStatus(
                                                    props.item.status
                                                  )
                                                ) +
                                                "\n                                "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            !props.item.is_primary
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-danger",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteResources(
                                                          props.item.delete
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          flat: "",
                                                          fab: "",
                                                          icon: "",
                                                          small: "",
                                                          color: "red"
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("delete")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4221787692
                                )
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.canAddEmail()
                            ? _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "blue",
                                      attrs: { round: "", small: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.addEmail = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.$t_(
                                              "FE_BTN_UTENTE_ADD_NUOVA_MAIL_TABLE_BTN"
                                            )
                                          ) +
                                          "\n                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "grey lighten-4",
                                      attrs: { round: "", small: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.changeEmail = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.$t_(
                                              "FE_BTN_UTENTE_CHANGE_EMAIL_PRINC"
                                            )
                                          ) +
                                          "\n                        "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.changeEmail
                        ? _c(
                            "v-card",
                            { staticClass: "elevation-0 pa-2 mt-4" },
                            [
                              _c("v-card-title", { staticClass: "title" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$t_(
                                        "FE_CARD_UTENTE_CAMBIA_EMAIL_PRINCIPALE"
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c("span", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t_(
                                            "FE_CARD_TEXT_EMAIL_PRINCIPALE_CHANGE_VALUE"
                                          )
                                        ) +
                                        " " +
                                        _vm._s(_vm.oldPrimary) +
                                        "\n                        "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-radio-group",
                                    {
                                      model: {
                                        value: _vm.selected,
                                        callback: function($$v) {
                                          _vm.selected = $$v
                                        },
                                        expression: "selected"
                                      }
                                    },
                                    _vm._l(_vm.emailUtente.dati, function(
                                      item,
                                      index
                                    ) {
                                      return _c("v-radio", {
                                        key: item.new_email,
                                        attrs: {
                                          label: item.new_email,
                                          value: item.new_email
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.showBtnChange &&
                              _vm.emailUtente.dati.length > 1
                                ? _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "red lighten-3",
                                          attrs: { small: "", round: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.ChangeEmailUser()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.$t_(
                                                  "FE_CARD_ACTION_CAMBIA_EMAIL"
                                                )
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 pa-2 ma-2" },
                        [
                          _c("v-card-title", { staticClass: "title" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t_("FE_CARD_UTENTE_ADD_EMAIL")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  model: {
                                    value: _vm.valid,
                                    callback: function($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid"
                                  }
                                },
                                [
                                  _c("v-form-base", {
                                    attrs: {
                                      value: _vm.inputs.values,
                                      schema: _vm.inputs.schema
                                    },
                                    on: { update: _vm.update }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "text-xs-right" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue",
                                  attrs: { round: "", small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SendData()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$t_("FE_BTN_UTENTE_ADD_EMAIL")
                                      ) +
                                      "\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "red",
                                  attrs: { round: "", small: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.addEmail = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$t_("FE_BTN_UTENTE_ESCI_ADD_EMAIL")
                                      ) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm4: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 pa-2 ma-2" },
                        [
                          _c("v-card-title", { staticClass: "title" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t_("FE_USER_EMAIL_PRESENTI")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-list",
                                _vm._l(_vm.emailUtente.dati, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "v-list-tile",
                                    {
                                      key: index,
                                      on: { click: function($event) {} }
                                    },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.new_email) +
                                            "\n                                "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "650" },
                  model: {
                    value: _vm.dialog,
                    callback: function($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "red lighten-3",
                          attrs: {
                            color: "transparent",
                            flat: "",
                            dense: "",
                            card: ""
                          }
                        },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "subheading ft-200" },
                                [_vm._v(_vm._s(_vm.dialog_delete.title))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.dialog_delete.message) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "indifo darken-1", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.cancelDelete()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.dialog_delete.cancel))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red darken-1", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.continueToDelete()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.dialog_delete.continue))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("Dialog", {
            attrs: { dialog: _vm.openDialog, data: _vm.dataDialog },
            on: {
              "update:dialog": function($event) {
                _vm.openDialog = $event
              }
            },
            model: {
              value: _vm.openDialog,
              callback: function($$v) {
                _vm.openDialog = $$v
              },
              expression: "openDialog"
            }
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "490" },
              model: {
                value: _vm.showReuinionModal,
                callback: function($$v) {
                  _vm.showReuinionModal = $$v
                },
                expression: "showReuinionModal"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.dataModal.title))
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [_vm._v(_vm._s(_vm.dataModal.message))]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Inserisci il codice",
                          placeholder: "##########",
                          outline: ""
                        },
                        model: {
                          value: _vm.inputReunionMail,
                          callback: function($$v) {
                            _vm.inputReunionMail = $$v
                          },
                          expression: "inputReunionMail"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "green darken-1", flat: "" },
                          on: { click: _vm.sendChecked }
                        },
                        [_vm._v(_vm._s(_vm.dataModal.btn_ok))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }