var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { class: "post-card ma-2 " + _vm.classe },
    [
      _c("v-card-title", { staticClass: "display-2 mb-2" }, [
        _vm._v("\n        " + _vm._s(_vm.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("v-divider", { staticClass: "wrap-madi-divider" }),
      _vm._v(" "),
      _vm.items.length > 0
        ? _c(
            "ul",
            { staticClass: "post--list flex-list vertical" },
            _vm._l(_vm.items, function(item, key) {
              return _c("li", { key: key, staticClass: "post--item" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      " post--link pa-4 layout row ma-0 text--primary"
                  },
                  [
                    _c("div", { staticClass: "post--media" }, [
                      _c("img", {
                        staticClass: "image-scale",
                        attrs: {
                          src: item.issuerData.image,
                          alt: "",
                          height: "100"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "post--content ml-3" }, [
                      _c("h3", { staticClass: "title post--title" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.issuerData.name) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "post--desc py-2 text--secondary" },
                        [
                          item.claim[0] !== undefined
                            ? _c("vue-simple-markdown", {
                                attrs: {
                                  source: item.claim[0].endorsementComment
                                }
                              })
                            : _c("vue-simple-markdown", {
                                attrs: { source: item.claim.endorsementComment }
                              })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "post--meta o-flex justify-space-between"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "post--author caption grey--text text--darken-1"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t_(
                                      "FE_VALIDATOR_ENDORSEMENT_EMISSIONE"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("time", { staticClass: "px-2" }, [
                                _vm._v(_vm._s(item.issuedOn))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outline: "",
                                    small: "",
                                    color: "purple"
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: item.id, target: "_blank" }
                                    },
                                    [_vm._v("View More Details")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            }),
            0
          )
        : _c(
            "v-card-text",
            [_c("v-progress-linear", { attrs: { indeterminate: true } })],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }