//
//
//
//
//
//
//
//
//
//
import vuefydatatable from '../components/vuefy-datatable';
export default {
  name: "tables",
  props: ['myurl'],
  data: function data() {
    return {
      url: '/supersu/structureList/intestazioni'
    };
  },
  created: function created() {//console.log(this.$currentUser);
  },
  components: {
    vuefydatatable: vuefydatatable //dataTables:dataTables

  }
};