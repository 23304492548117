var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("SubHeaderSearch", {
        attrs: {
          title: "FE_ORGANIZZAZIONI_TITOLO_HEADER",
          description: "FE_ORGANIZZAZIONI_DESCRIZIONE_HEADER"
        }
      }),
      _vm._v(" "),
      _vm.isVideoMode
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm._l(_vm.items, function(item) {
                return [
                  _c(
                    "v-flex",
                    { attrs: { xs4: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-4 pa-2" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("v-img", {
                                staticStyle: {
                                  "max-height": "70px",
                                  "min-height": "70px"
                                },
                                attrs: { src: item.icona, contain: "" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "elevation-0 white",
                              staticStyle: { height: "33px" }
                            },
                            [
                              _c(
                                "v-toolbar-items",
                                {
                                  staticStyle: {
                                    float: "right",
                                    "padding-left": "80%"
                                  }
                                },
                                [
                                  _c("m-hex-btn", {
                                    staticStyle: { float: "right" },
                                    attrs: {
                                      margin: "",
                                      icon: "arrow_forward",
                                      link: _vm.createLink(item.guid),
                                      object: _vm.object
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "title  pt-4 pb-5  font-weight-medium",
                              staticStyle: { height: "35px" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.name) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-divider", {
                            staticStyle: {
                              border: "dashed rgba(0,0,0,.12)",
                              "border-width": "thin 0 0"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "cyan--text",
                                        attrs: { xs6: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.num_badges) +
                                            " Badges\n                                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "cyan--text",
                                        staticStyle: { "text-align": "right" },
                                        attrs: { xs6: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.num_project) +
                                            " Progetti\n                                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }