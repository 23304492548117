var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoMode
    ? _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        value: true,
                        color: _vm.response.type,
                        icon: _vm.response.icon,
                        outline: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t_(_vm.response.message)) +
                          "\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t_(_vm.response.info)) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }