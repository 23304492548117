//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GlobalCards from '../components/GlobalCards';
export default {
  name: "Organizzation",
  props: ["myurl"],
  components: {
    "GlobalCards": GlobalCards
  },
  data: function data() {
    return {
      organizzazione: {},
      isVideoMode: false,
      showSubBar: false
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  computed: {
    getItemSubBar: {
      get: function get() {
        //section-name
        var my = [{
          name: this.$t_('SUB_HEADER_AZIENDA'),
          section: 'organizzazione'
        }];
        if (this.organizzazione.remoteProject) my.push({
          name: this.$t_('SUB_HEADER_PROGETTI'),
          section: 'project'
        });
        if (this.organizzazione.remoteEndorse) my.push({
          name: this.$t_('FE_LABEL_ENDORSEMENT_CARDS'),
          section: 'endorsement'
        });
        if (this.organizzazione.remoteBadge) my.push({
          name: this.$t_('SUB_HEADER_BADGE'),
          section: 'badges'
        });
        return my;
      },
      set: function set() {}
    }
  },
  methods: {
    onScroll: function onScroll(e) {
      // console.log(e.target.scrollTop)
      this.myScroll = window.scrollY;

      if (this.myScroll >= 100) {
        this.showSubBar = true;
      } else {
        this.showSubBar = false;
      }
    },
    getData: function getData() {
      var self = this;
      axios.get(this.$options.propsData.myurl).then(function (response) {
        self.organizzazione = response.data.obj;
        self.isVideoMode = true;
      });
    },
    CreateLinkEndorsement: function CreateLinkEndorsement() {
      return '/mob/orgEndoreser/' + this.organizzazione.guid;
    },
    CreateLinkProject: function CreateLinkProject() {
      return '/mob/orgProject/' + this.organizzazione.guid;
    },
    CreateLinkBadges: function CreateLinkBadges() {
      return '/mob/orgBadges/' + this.organizzazione.guid;
    }
  }
};